import app from 'app';
import templateUrl from './headerTitle.html';
import './headerTitle.scss';

app.component('headerTitle', {
  bindings: {
    icon: '<',
    title: '<',
  },
  controllerAs: 'HeaderTitle',
  templateUrl,
});

export default 'headerTitle';
