import { COLLECTIVE_TITLE } from '../collectiveTitle';
// This constant maps communication settings to copy
// Creates one source of truth for the unsubscribe settings and the comm settings
export const COMM_SETTINGS = [
  {
    settingsName: 'emailBenefitsUpdate',
    shortLabel: 'Changes to your benefits',
    longLabel: 'Changes that may affect your benefits',
    description:
      'These emails let you know about benefit updates, such as changes to your plan or network.',
  },
  {
    settingsName: 'emailProgramPartnerUpdate',
    shortLabel: 'Relevant benefit suggestions',
    longLabel: 'Information about benefits relevant to you',
    description:
      'These emails highlight personalized recommendations about benefits we think you’d find valuable.',
  },
  {
    settingsName: 'emailNewOffers',
    shortLabel: 'Information about your account',
    longLabel: `Updates about your ${COLLECTIVE_TITLE} account`,
    description: `These emails notify you about your ${COLLECTIVE_TITLE} account, when we unveil something new, or when we update our product.`,
  },
  {
    settingsName: 'emailResearchRequest',
    shortLabel: 'Influence our products',
    longLabel: 'Invitations to influence our products',
    description:
      'These emails ask for your feedback or invite you to participate in studies to help us improve.',
  },
];

export const LEGALLY_REQUIRED_COMMS = {
  shortLabel: 'Legally required messages',
  longLabel: 'Legally required messages',
  description:
    "Sometimes we need to send you information that's pretty important (and legally required), like updates to our Terms of Service or Privacy Policy and emails about your claims (unless you have chosen to receive paper claims statements). We'll continue to send messages like these.",
};

export default {
  COMM_SETTINGS,
  LEGALLY_REQUIRED_COMMS,
};
