import app from 'app';
import templateUrl from './premiumPerk.html';
import './premiumPerk.scss';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-external-link-small';

import { logEvent, eventCategories } from 'helpers/analytics';

function PremiumPerk() {
  const PremiumPerk = this;

  PremiumPerk.trackAnalytics = () => {
    logEvent('partnerDetailPageViewed', {
      category: eventCategories.PARTNERS,
      partnerName: PremiumPerk.item.about.title,
      partnerID: PremiumPerk.item.partnerId,
      referralURL: getPartnerUrl(),
      sponsorCode: PremiumPerk.currentSponsorship.sponsor.sponsorCode,
      page: 'planBenefit'
    });
  };

  function getPartnerUrl() {
    if (
      PremiumPerk.item.display.actions &&
      PremiumPerk.item.display.actions.length > 0
    ) {
      return PremiumPerk.item.display.actions[0].value;
    }
    return null;
  }
}

app.component('premiumPerk', {
  bindings: {
    currentUser: '<',
    currentSponsorship: '<',
    item: '<'
  },
  controller: PremiumPerk,
  controllerAs: 'PremiumPerk',
  templateUrl
});

export default 'premiumPerk';
