/*eslint angular/window-service: "off"*/
import subdomains from '../../subdomains.json';

import {
  getEnv as getEnvHelper,
  isProd as isProdHelper,
  isLocal as isLocalHelper,
  isTesting as isTestingHelper,
} from '@collectivehealth/mx-machines/src/helpers/checkEnv';

export function getEnv() {
  return getEnvHelper(subdomains);
}

export function isProd() {
  return isProdHelper(subdomains);
}

export function isLocal() {
  return isLocalHelper(subdomains);
}

export function isTesting() {
  return isTestingHelper(subdomains);
}
