import app from 'app';
import templateUrl from './sessionTimeout.html';

function SessionTimeoutCtrl($scope, Session) {
  const SessionTimeout = this;

  SessionTimeout.$onInit = () => {
    // Update seconds remaining during warning countdown.
    // IdleWarn comes from ng-idle
    $scope.$on('IdleWarn', (event, countdown) => {
      $scope.$applyAsync(() => {
        SessionTimeout.timeRemaining = countdown;
      });
    });

    // Close modal if user returns from idle.
    // IdleEnd comes from ng-idle
    $scope.$on('IdleEnd', () => {
      SessionTimeout.$close();
    });

    // Close modal if session ends due to inactivity.
    $scope.$on(Session.events.SESSION_END, () => {
      SessionTimeout.$close();
    });
  };
}

app.component('sessionTimeout', {
  bindings: {
    $close: '<',
  },
  controller: SessionTimeoutCtrl,
  controllerAs: 'SessionTimeout',
  templateUrl,
});

export default 'sessionTimeout';
