import app from 'app';
import templateUrl from './resetPassword.html';
import './resetPassword.scss';
import './resetPasswordForm';

app.component('resetPassword', {
  bindings: {
    apiErrorMessage: '<',
    sptoken: '<',
  },
  templateUrl,
  controllerAs: 'ResetPassword',
});

export default 'resetPassword';
