import app from 'app';

/**
 * Factory that is a wrapper around angular-scroll. Calls duScrollTop with default values
 * if called with no parameters.
 */
app.factory('animatedScrollTop', ($document) => {
  /**
   * Scrolls to the top of the page or a given position.
   * @param  {number} position - position on page to scroll to. Defaults to 0.
   * @param  {number} duration - time spent scrolling. Defaults to 500.
   * @return {Promise} resolves when scrolling animation finishes
   */
  return function (position, duration) {
    return $document.scrollTop(position || 0, duration || 500);
  };
});
