import app from 'app';
import templateUrl from './confirmEmail.html';
import './confirmEmail.scss';

function ConfirmEmailCtrl($stateParams, Registration) {
  const ConfirmEmail = this;

  ConfirmEmail.email = $stateParams.email;

  ConfirmEmail.resendVerificationEmail = function () {
    Registration.createVerificationEmail($stateParams.email);
  };
}

app.component('confirmEmail', {
  controller: ConfirmEmailCtrl,
  controllerAs: 'ConfirmEmail',
  templateUrl,
});

export default 'confirmEmail';
