import { getCookie, putCookie } from '@collectivehealth/cookie';

const IDENTIFIER = 'chUserIdentifier';

export function setUserIdentifier(id) {
  putCookie(IDENTIFIER, id);
}

export function getUserIdentifier() {
  return getCookie(IDENTIFIER);
}
