import app from 'app';
import templateUrl from './landingFundAccount.html';
import './landingFundAccount.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-right';

app.component('landingFundAccount', {
  bindings: {
    account: '<',
  },
  controllerAs: 'LandingFundAccount',
  templateUrl,
});
