import app from 'app';
import templateUrl from './benefitOverviewVision.html';
import './benefitOverviewVision.scss';

// Sub-components.
import '../benefitCards/benefitCardLight/benefitCardLight';

import { logEvent, eventCategories } from 'helpers/analytics';

function BenefitOverviewVision() {
  const Benefits = this;

  Benefits.$onInit = () => {
    logEvent('planViewed', {
      category: eventCategories.PLANS,
      planType: 'vision'
    });
  };
}

app.component('benefitOverviewVision', {
  bindings: {
    planBenefitGroups: '<',
    allBenefits: '<'
  },
  controller: BenefitOverviewVision,
  controllerAs: 'Benefits',
  templateUrl
});

export default 'benefitOverviewVision';
