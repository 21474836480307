import app from 'app';
import templateUrl from './supportPhone.html';
import './supportPhone.scss';

import MEMBER_PORTAL from 'constants/memberPortal';

import { logEvent, eventCategories } from 'helpers/analytics';
import { getAssetParams } from 'helpers/assetParamsHelper';

function SupportPhoneCtrl(AssetService, Sponsor, $scope, Session) {
  const SupportPhone = this;
  AssetService.getAssetByAssetKey(
    'mp-support-number',
    getAssetParams(
      false, // un-authorized only
      AssetService.getSubdomainName(),
    ),
  ).then((response) => {
    SupportPhone.assetResponse = response;
    SupportPhone.currentSponsorship = Sponsor.getCurrentSponsorship();
    if (Session.hasSession() && Boolean(SupportPhone.currentSponsorship)) {
      SupportPhone.number =
        SupportPhone.currentSponsorship.sponsor.tollFreeNumber;
    } else if (response.asset) {
      SupportPhone.number = response.asset.value.text;
    } else {
      SupportPhone.number = MEMBER_PORTAL.defaultSupportPhone;
    }
  });

  function setSupportPhone() {
    SupportPhone.currentSponsorship = Sponsor.getCurrentSponsorship();
    if (Session.hasSession() && Boolean(SupportPhone.currentSponsorship)) {
      SupportPhone.number =
        SupportPhone.currentSponsorship.sponsor.tollFreeNumber;
    } else if (SupportPhone.assetResponse && SupportPhone.assetResponse.asset) {
      SupportPhone.number = SupportPhone.assetResponse.asset.value.text;
    } else {
      SupportPhone.number = MEMBER_PORTAL.defaultSupportPhone;
    }
  }

  SupportPhone.analyticsTrack = () => {
    logEvent('contactButtonClicked', {
      category: eventCategories.HELP,
      location: 'footer',
    });
  };

  SupportPhone.$onInit = () => {
    setSupportPhone();
  };

  SupportPhone.$onChanges = () => {
    setSupportPhone();
  };

  $scope.$on('$locationChangeSuccess', setSupportPhone);
}

app.component('supportPhone', {
  bindings: {
    currentSponsorship: '<',
  },
  controller: SupportPhoneCtrl,
  controllerAs: 'SupportPhone',
  templateUrl,
});

export default 'supportPhone';
