import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';
export function checkReactLoginPortalFlag(FeatureFlagService) {
  return new Promise((resolve, reject) => {
    const client = FeatureFlagService.getLDClient();
    client
      .waitUntilReady()
      .then(() => {
        const isFeatureFlagEnabled = getFFVariation(
          client,
          'isReactLoginPortalOn',
        );

        resolve(isFeatureFlagEnabled);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
