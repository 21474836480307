import app from 'app';
import moment from 'moment';
import _ from 'lodash';

function SubmitClaimService() {
  const thisYear = moment().year();
  const lastYear = moment().subtract(1, 'year').year();

  function isThisYearOrLastYear(year) {
    return year === thisYear || year === lastYear;
  }

  function getMostRecentPlanOfType(planMembershipsOfType) {
    return _(planMembershipsOfType)
      .sortBy((x) => moment(x.startDate))
      .last();
  }

  function getMostRecentPlanOfEachType(planMemberships) {
    return _(planMemberships)
      .filter((x) => isThisYearOrLastYear(moment(x.startDate).year()))
      .groupBy('plan.type')
      .mapValues(getMostRecentPlanOfType)
      .value();
  }

  return {
    getMostRecentPlanOfEachType,
  };
}

app.service('SubmitClaimService', SubmitClaimService);
