import app from 'app';

// Components.
import five9Component from 'components/five9/five9';
import headerComponent from 'components/header/header';
import footerComponent from 'components/footer/footer';
import supportMessage from 'components/supportMessage/supportMessage';

// Pages
import base from 'pages/base/base';
import notFound from 'pages/notFound/notFound';
import safariOutage from 'pages/safariOutage/safariOutage';
import docsPage from 'pages/legal/docs/docs';
import accessibilityPage from 'pages/legal/accessibility/accessibility';
import partnerDisclaimersPage from 'pages/legal/partnerDisclaimers/partnerDisclaimers';
import emailSurveyFeedbackPage from 'pages/feedback/feedback';
import unsubscribePage from 'pages/feedback/unsubscribe';
import unsubscribeTokenExpiredPage from 'pages/feedback/unsubscribeTokenExpired';
import { getAssetParams } from 'helpers/assetParamsHelper';
import { logEvent, setAmplitudeUser } from 'helpers/analytics';
import appendQueryParam from 'helpers/appendQueryParam';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

import LEGAL from 'constants/legal';
import ROUTES from './routes.json';
import LOGIN_ROUTES from '@collectivehealth/login-portal/src/helpers/routes';

const MEMBER_REFERRED_PARAMS = [
  'campaign',
  'sponsor',
  'action',
  'user',
  'campaignNameSuffix',
];

const MIGRATED_REACT_PATHS = {
  [`/${ROUTES.terms.angular}`]: ROUTES.terms.react,
  [`/${ROUTES.privacy.angular}`]: ROUTES.privacy.react,
  [`/${ROUTES.accessibility.angular}`]: ROUTES.accessibility.react,
};

function getMigratedLegalPathname(currentPath) {
  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const { sponsorshipId, sponsorId } = queryParams;
  let newPath = MIGRATED_REACT_PATHS[currentPath];

  if (sponsorshipId) {
    newPath = appendQueryParam(newPath, 'sponsorshipId', sponsorshipId);
  }

  if (sponsorId) {
    newPath = appendQueryParam(newPath, 'sponsorId', sponsorId);
  }

  return newPath;
}

app.config(($locationProvider, $qProvider, $urlServiceProvider) => {
  $locationProvider.html5Mode(true).hashPrefix('!');

  // Default to '/'.
  $urlServiceProvider.rules.when('', '/');

  // Redirect to the 404 state if URL can't be matched.
  $urlServiceProvider.rules.otherwise((bestMatch, url, router) => {
    return router.stateService.go('404', {}, { location: false });
  });

  // Make trailing slahes in URLs optional.
  $urlServiceProvider.config.strictMode(false);

  $qProvider.errorOnUnhandledRejections(false);
});

app.config(($stateProvider, FrontendLegalProvider) => {
  $stateProvider.state('App', {
    views: {
      'footer@': {
        component: footerComponent,
      },
    },
  });

  // Accessing this via the $window service crashes the App
  const lastChatStatus = window.sessionStorage.getItem('lastChatStatus'); // eslint-disable-line angular/window-service

  const defaultChatValue = lastChatStatus !== 'false' && lastChatStatus;

  $stateProvider.state('Base', {
    abstract: true,
    parent: 'App',
    url: `/?
    ${MEMBER_REFERRED_PARAMS.join('&')}
    &showTranslationKeys&redirectTo&redirectToParams`,
    params: {
      isChatOpen: defaultChatValue,
      action: {
        dynamic: true,
      },
      campaign: {
        dynamic: true,
      },
      user: {
        dynamic: true,
      },
      sponsor: {
        dynamic: true,
      },
      campaignNameSuffix: {
        dynamic: true,
      },
    },
    resolve: {
      activeAlerts: (MemberAlertService) =>
        MemberAlertService.getAllActiveWebAlerts(),
    },
    views: {
      // This view represents the abstract base state.
      '@': {
        component: base,
      },
      'header@Base': {
        component: headerComponent,
      },
      'five9@Base': {
        component: five9Component,
      },
      'footer@Base': {
        component: footerComponent,
      },
    },
    data: {
      scrollTop: document,
    },
    onEnter($rootScope, $state, $stateParams, $transition$) {
      const hasMemberReferredParam = MEMBER_REFERRED_PARAMS.reduce(
        (hasParam, param) => hasParam || !!$stateParams[param],
        false,
      );

      if ($stateParams.user) {
        setAmplitudeUser($stateParams.user);
      }

      if (hasMemberReferredParam) {
        logEvent('memberReferredToCH', {
          // TODO: replace category with enum from frontend-analytics when available
          category: 'session',
          action: $stateParams.action,
          campaign: $stateParams.campaign,
          user: $stateParams.user,
          sponsor: $stateParams.sponsor,
          campaignNameSuffix: $stateParams.campaignNameSuffix,
        });

        const cleanedStateParams = {};
        Object.assign(cleanedStateParams, $stateParams);

        cleanedStateParams.action = undefined;
        cleanedStateParams.campaign = undefined;
        cleanedStateParams.user = undefined;
        cleanedStateParams.sponsor = undefined;
        cleanedStateParams.campaignNameSuffix = undefined;

        $state.transitionTo($transition$.to().name, cleanedStateParams);
      }
    },
  });

  $stateProvider.state('404Angular', {
    parent: 'Base',
    views: {
      'content@Base': {
        component: notFound,
      },
    },
    data: {
      title: 'Page Not Found',
    },
  });

  $stateProvider.state('404', {
    // we are serving angular document for 404 scenarios, so that angular can handle angular-only routes or 404 if path really doesnt exist
    // so adding redirect here to react not found
    // Update after all angular routes are migrated
    onEnter($state, FeatureFlagService) {
      const client = FeatureFlagService.getLDClient();
      client.waitUntilReady().then(() => {
        const isReact404Enabled = getFFVariation(client, 'isReact404Enabled');
        if (isReact404Enabled) {
          window.location.replace(`/${ROUTES.notFound.react}`);
        } else {
          $state.go('404Angular');
        }
      });
    },
  });

  $stateProvider.state('safariOutage', {
    url: 'safariOutage',
    parent: 'Base',
    views: {
      'content@Base': {
        component: safariOutage,
      },
    },
    data: {
      title: 'Safari outage',
    },
  });

  $stateProvider.state('Posing', {
    url: 'posing?poseuid&subdomain&env&port',
    parent: 'Base',
    template: '<div></div>',
    onEnter($q, $state, $stateParams, $timeout, Posing, Session) {
      return Posing.requestLoginToken({
        userId: parseInt($stateParams.poseuid, 10),
        subdomain: $stateParams.subdomain,
        port: $stateParams.port,
        env: $stateParams.env,
      })
        .then(Session.beginSession)
        .then(() => $state.target('Landing'))
        .catch(() => {
          /**
           * Why $timeout:
           *
           * We want to do 2 things here, in this order:
           *
           * 1. CANCEL (not redirect) this transition. To do this, we must return
           *    false or a rejected promise.
           * 2. Ensure that any session cookie is cleared and the user is
           *    redirected to the login page.
           *
           * Calling Session.logout() will broadcast the SESSION_END event, which
           * we listen to and do a redirect to login. If we call this BEFORE
           * cancelling this transition, we will wind up in an infinite redirect
           * loop. So in order to both return then trigger our logout/redirect
           * AFTERWARDS, we need to delay the call to Session.logout(), which we
           * accomplish with $timeout.
           */
          $timeout(() => {
            Session.logout();
          });

          return false;
        });
    },
  });

  $stateProvider.state('Support', {
    url: 'support',
    parent: 'Base',
    onEnter: ($state, chModal, Session) => {
      chModal.open({
        component: supportMessage,
      });

      return $state.target(Session.hasSession() ? 'Landing' : 'Login');
    },
  });

  $stateProvider.state('EmailSurveyFeedback', {
    url: 'feedback',
    parent: 'Base',
    views: {
      'content@Base': {
        component: emailSurveyFeedbackPage,
      },
    },
  });

  $stateProvider.state('Unsubscribe', {
    url: 'unsubscribe?token&emailAddress',
    parent: 'Base',
    views: {
      'content@Base': {
        component: unsubscribePage,
      },
    },
    resolve: {
      token: ($stateParams) => $stateParams.token,
      emailAddress: ($stateParams) => $stateParams.emailAddress,
    },
  });

  $stateProvider.state('UnsubscribeTokenExpired', {
    url: 'unsubscribe/expired',
    parent: 'Base',
    views: {
      'content@Base': {
        component: unsubscribeTokenExpiredPage,
      },
    },
  });

  $stateProvider.state('Legal', {
    abstract: true,
    parent: 'Base',
    url: '?sponsorshipId&sponsorId',
    resolve: {
      partnerDisclaimers: (AssetService, Session) => {
        const assetParams = getAssetParams(
          Session.hasSession(),
          AssetService.getSubdomainName(),
          window.sessionStorage.getItem('affiliatesSponsorId') || undefined,
        );

        return AssetService.getAssetByAssetKey(
          'mp-partner-disclaimer-content',
          assetParams,
        ).then((response) => {
          if (response?.asset?.value?.body) {
            return response.asset.value;
          }

          return null;
        });
      },
      partnerDisclaimersFlag: (FeatureFlagService) => {
        return FeatureFlagService.getFeatureFlagStatus(
          'affiliatesPartnerDisclaimers',
        ).then((enabled) => {
          return enabled;
        });
      },
      // Iterate over document data from constants. If the current sponsor has a
      // document, add the sponsor-specific URL for the document to the data from
      // constants.
      sponsorDocuments: ($stateParams, Session, User) => {
        if (Session.hasSession()) {
          return User.getUserLegalInfo($stateParams.sponsorshipId);
        } else {
          return [];
        }
      },
      sponsorshipId: ($stateParams) => {
        return parseInt($stateParams.sponsorshipId, 10);
      },
      sponsorId: ($stateParams) => {
        return parseInt($stateParams.sponsorId, 10);
      },
    },
    onEnter(FeatureFlagService) {
      const currentPath = window.location.pathname;
      const ENV = FeatureFlagService.getOtherEnvVariables();

      if (Object.keys(MIGRATED_REACT_PATHS).includes(currentPath)) {
        const isLegalPagesMigrationEnabled = ENV.MIGRATION_LEGAL_PAGES || false;

        if (isLegalPagesMigrationEnabled) {
          const newPath = getMigratedLegalPathname(currentPath);

          window.location.replace(newPath);
        }
      }
    },
  });

  FrontendLegalProvider.configure({
    configName: LEGAL.CONFIG_NAME,
    parent: 'Legal',
    viewTarget: 'content@Base',
    terms: {
      url: ROUTES.terms.angular,
      dataHook: LEGAL.TERMS.DATA_HOOK,
    },
    privacy: {
      url: ROUTES.privacy.angular,
      dataHook: LEGAL.PRIVACY.DATA_HOOK,
    },
    customStates: [
      {
        navName: LEGAL.ACCESSIBILITY.NAV_NAME,
        stateName: LEGAL.ACCESSIBILITY.STATE_NAME,
        dataHook: LEGAL.ACCESSIBILITY.DATA_HOOK,
        url: ROUTES.accessibility.angular,
        component: accessibilityPage,
      },
      {
        navName: LEGAL.HEALTHCARE_RIGHTS.NAV_NAME,
        shortNavName: LEGAL.HEALTHCARE_RIGHTS.SHORT_NAV_NAME,
        stateName: LEGAL.HEALTHCARE_RIGHTS.STATE_NAME,
        dataHook: LEGAL.HEALTHCARE_RIGHTS.DATA_HOOK,
        url: 'healthcare-rights',
        component: docsPage,
        onEnter: ($state, Session) => {
          'ngInject';

          if (!Session.hasSession()) {
            return $state.target('Login', { redirectTo: 'SponsorDocs' });
          }
        },
      },
      {
        navName: LEGAL.PARTNER_DISCLAIMERS.NAV_NAME,
        shortNavName: LEGAL.PARTNER_DISCLAIMERS.SHORT_NAV_NAME,
        stateName: LEGAL.PARTNER_DISCLAIMERS.STATE_NAME,
        dataHook: LEGAL.PARTNER_DISCLAIMERS.DATA_HOOK,
        url: LOGIN_ROUTES.PARTNER_DISCLAIMERS.angular,
        component: partnerDisclaimersPage,
        onEnter: ($state, partnerDisclaimers, partnerDisclaimersFlag) => {
          'ngInject';

          // redirect user if partner disclaimers page should not be available
          if (!partnerDisclaimersFlag || !Boolean(partnerDisclaimers)) {
            return $state.target('404');
          }
        },
      },
    ],
    hideStates(partnerDisclaimers, partnerDisclaimersFlag, sponsorDocuments) {
      'ngInject';

      return {
        PartnerDisclaimers:
          !partnerDisclaimersFlag || !Boolean(partnerDisclaimers),
        SponsorDocs: !sponsorDocuments || sponsorDocuments.length < 1,
      };
    },
  });
});
