import app from 'app';
import templateUrl from './mxToast.html';
import './mxToast.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-circle-exclamation';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-circle-check';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-circle-minus';

app.component('mxToast', {
  bindings: {
    level: '@',
  },
  controllerAs: 'MxToast',
  templateUrl,
  transclude: {
    body: '?mxToastBody',
    button: '?mxToastButton',
  },
});
