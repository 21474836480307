import app from 'app';
import _ from 'lodash';
import moment from 'moment';
import { COLLECTIVE_TITLE } from '../collectiveTitle';

app.factory('App', ($log, $q, $rootScope, $state, User) => {
  const deregistrationFunctions = [];
  const currentYear = moment().format('YYYY');

  let pageTitle = COLLECTIVE_TITLE;

  function deregisterStateEvents() {
    deregistrationFunctions.forEach(function (deregister) {
      deregister();
    });
  }

  function registerStateEvents() {
    deregistrationFunctions.push(
      $rootScope.$on(
        '$stateChangeSuccess',
        function (event, toState, toParams, fromState) {
          $log.debug(
            'StateChangeSuccess TO',
            toState.name,
            toParams,
            'FROM',
            fromState.name
          );
        }
      )
    );

    deregistrationFunctions.push(
      $rootScope.$on(
        '$stateChangeStart',
        function (event, toState, toStateParams, fromState) {
          $log.debug(
            'stateChangeStart TO',
            toState.name,
            toStateParams,
            'FROM',
            fromState.name
          );
        }
      )
    );

    deregistrationFunctions.push(
      $rootScope.$on(
        '$stateNotFound',
        function (event, unfoundState, fromState, fromParams) {
          $log.debug('$stateNotFound', unfoundState, fromState, fromParams);
        }
      )
    );

    deregistrationFunctions.push(
      $rootScope.$on(
        '$stateChangeError',
        function (event, toState, toParams, fromState, fromParams, error) {
          $log.debug('stateChangeError', error, toState, toParams);
        }
      )
    );
  }

  function enableStateDebugging(shouldEnable) {
    deregisterStateEvents();
    if (shouldEnable) {
      registerStateEvents();
    }
  }

  function setPageTitle(title) {
    pageTitle = title;
  }

  function getPageTitle() {
    return pageTitle;
  }

  function getPageId() {
    return $state.current.name.replace(/\./g, '');
  }

  /**
   * @return {promise} - Resolves with the current member's name if one is
   * active, or null otherwise.
   */
  function getMemberName() {
    return User.getUser()
      .then(function (user) {
        return user.firstName + ' ' + user.lastName;
      })
      .catch(_.constant(null));
  }

  return {
    currentYear,
    enableStateDebugging,
    setPageTitle,
    getPageTitle,
    getPageId,
    getMemberName,
  };
});
