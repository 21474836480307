import app from 'app';
import templateUrl from './landingFunds.html';
import './landingFunds.scss';
import './landingFundAccount/landingFundAccount';

function LandingFunds() {}

app.component('landingFunds', {
  bindings: {
    memberFunds: '<',
  },
  controller: LandingFunds,
  controllerAs: 'LandingFunds',
  templateUrl,
});
