export default function getCommaDelimitedList(list) {
  if (!list) {
    return [];
  }

  return list.map((item, index) => {
    if (list.length < 2) {
      return item;
    }

    if (list.length === 2 && index === 0) {
      return `${item} `;
    }

    if (index === list.length - 1) {
      return `and ${item}`;
    }

    return `${item}, `;
  });
}
