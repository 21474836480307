import app from 'app';
import templateUrl from './resetPasswordForm.html';
import './resetPasswordForm.scss';
import PATTERNS from 'constants/patterns';

import {
  required,
  pattern,
  maxLength,
  match,
} from '@darkobits/formation-validators';

function ResetPasswordForm($state, Registration) {
  const ResetPasswordForm = this;
  const DEFAULT_ERROR_MESSAGE =
    "Hmm. something went wrong. Please refresh the page, or give us a ring. We're happy to help.";

  ResetPasswordForm.formControls = {
    newPassword: {
      validators: {
        required,
        pattern: pattern(PATTERNS.password),
        maxLength: maxLength(50),
      },
      errors: [
        ['required', 'This field is required.'],
        [
          'pattern',
          `Your password must be at least 8 characters and include 1 uppercase letter,
          1 lowercase letter, and 1 number.`,
        ],
        ['maxLength', 'Your password must be fewer than 50 characters.'],
      ],
    },
    confirmPassword: {
      validators: {
        required,
        pattern: pattern(PATTERNS.password),
        maxLength: maxLength(50),
        match: match('newPassword'),
      },
      errors: [
        ['required', 'This field is required.'],
        [
          'pattern',
          `Your password must be at least 8 characters and include 1 uppercase letter,
          1 lowercase letter, and 1 number.`,
        ],
        ['maxLength', 'Your password must be fewer than 50 characters.'],
        ['match', 'This does not match the password entered above.'],
      ],
    },
  };

  // Sets new password, given token.
  ResetPasswordForm.submit = (data) => {
    Registration.resetPassword(
      ResetPasswordForm.sptoken,
      data.newPassword,
      data.confirmPassword
    )
      .then(() => {
        $state.go('Login', {
          passwordResetSuccess: true,
        });
      })
      .catch(() => {
        // Bad password, but good token
        ResetPasswordForm.apiErrorMessage = DEFAULT_ERROR_MESSAGE;
      });
  };
}

app.component('resetPasswordForm', {
  bindings: {
    sptoken: '<',
    apiErrorMessage: '=',
  },
  controller: ResetPasswordForm,
  controllerAs: 'ResetPasswordForm',
  templateUrl,
});

export default 'resetPasswordForm';
