import _ from 'lodash';

/**
 * Given an input, capitalize all words not in the excludedWords array
 *
 * @param  {string} input - string to filter
 * @param  {array} excludedWords - Array of words to not capitalize
 * @param  {string} delimiter - chracter to split the
 * @return {string} - a capitalized string
 */
export default function selectiveCapitalize(
  input,
  excludedWords = [],
  delimiter = ' '
) {
  return input
    .split(delimiter)
    .map((word) => {
      return excludedWords.indexOf(word) !== -1 ? word : _.capitalize(word);
    })
    .join(delimiter);
}
