import app from 'app';

app.factory('Redirect', $state => {
  function redirectTo(redirectState = 'Landing', redirectParams = {}) {
    // If there are serialized redirectTo state params
    if ($state.params.redirectTo && $state.params.redirectToParams) {
      // deserialize them
      redirectParams = JSON.parse(
        decodeURIComponent($state.params.redirectToParams),
      );
    }

    redirectParams.redirectTo = ''; // This is necessary to reset the redirect param

    $state.go($state.params.redirectTo || redirectState, redirectParams);
  }

  return {
    redirectTo,
  };
});
