import app from 'app';
import templateUrl from './recommendationCardSuggestion.html';
import './recommendationCardSuggestion.scss';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-up';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-down';
import { logEvent } from 'helpers/analytics';
import NAVIGATION from 'constants/navigation';

function RecommendationCardSuggestion($state) {
  const RecommendationCardSuggestion = this;

  RecommendationCardSuggestion.$onInit = () => {
    if (RecommendationCardSuggestion.data) {
      logEvent('viewRecommendation', {
        page: NAVIGATION.HOME.toLowerCase(),
        ...RecommendationCardSuggestion.data.tracker,
      });
    }
  };

  RecommendationCardSuggestion.goToRecommendation = () => {
    if (RecommendationCardSuggestion.data) {
      $state.go('RecommendationsSingle', {
        recommendationId: RecommendationCardSuggestion.data.id,
        source: NAVIGATION.HOME.toLowerCase(),
      });
    }
  };

  RecommendationCardSuggestion.dismissRecommendation = ($event) => {
    $event.stopPropagation();
    logEvent(
      'dismissRecommendation',
      RecommendationCardSuggestion.data.tracker
    );
    RecommendationCardSuggestion.onDismiss(RecommendationCardSuggestion.data);
  };
}

app.component('recommendationCardSuggestion', {
  bindings: {
    data: '<',
    onDismiss: '<',
  },
  controller: RecommendationCardSuggestion,
  controllerAs: 'RecommendationCardSuggestion',
  templateUrl,
});
