import app from 'app';
import decodeJwt from 'jwt-decode';
import { setUserIdentifier } from 'helpers/userIdentifier';
import { setAmplitudeUser } from 'helpers/analytics';
import Request from '@collectivehealth/cog/dist/services/request.js';

app.factory('LoginService', (Session, $q) => {
  /**
   * V2 login endpoint will return a temporary loginToken and
   * the copy that the user will need to accept.
   * Temporary login tokens do not have a role, and will not work
   * with any other endpoint except for public ones and the /accept endpoint.
   * @param {object} options
   * @param {object.email} email
   * @param {object.password} password
   * @return {promise}
   */
  function login(options) {
    const { email, password } = options;
    return Request.req({
      url: '/api/v2/login',
      method: 'POST',
      data: { email, password },
    })
      .then((response) => {
        const { loginToken, userIdentifier } = response;
        setUserIdentifier(userIdentifier);
        setAmplitudeUser(userIdentifier);
        const decodedToken = decodeJwt(loginToken);
        const isTosRequired = !decodedToken.role;

        if (isTosRequired) {
          return {
            accessToken: loginToken,
            isTosRequired,
          };
        }

        Session.beginSession(loginToken);
        return Session.getSession();
      })
      .catch((error) => $q.reject(error.response));
  }

  return {
    login,
  };
});
