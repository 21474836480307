import app from 'app';
import PlanMemberships from 'models/PlanMemberships';

app.factory('PlanMembership', (Logger, ApiMCM) => {
  const planMembershipsMap = {};
  // Returns all of users sponsors
  // these sponsors are sorted in order of sponsors importance to the user
  function getPlanMemberships(sponsorshipId, callbacks) {
    if (planMembershipsMap[sponsorshipId]) {
      if (callbacks && callbacks.successCallback) {
        callbacks.successCallback();
      }
      return planMembershipsMap[sponsorshipId];
    }

    const params = {
      sponsorshipId,
      includeNonCHPlans: true,
    };

    const promise = ApiMCM.get('planmemberships', params)
      .then((response) => {
        if (callbacks && callbacks.successCallback) {
          callbacks.successCallback();
        }
        return new PlanMemberships(response);
      })
      .catch((error) => {
        if (callbacks && callbacks.errorCallback) {
          callbacks.errorCallback();
        }
        Logger.captureApiError('planmemberships', params, error.response.data);
        return [];
      });

    planMembershipsMap[sponsorshipId] = promise;
    return planMembershipsMap[sponsorshipId];
  }

  return {
    getPlanMemberships,
  };
});
