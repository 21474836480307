import app from 'app';
import templateUrl from './findAccount.html';
import './findAccount.scss';
import supportMessage from 'components/supportMessage/supportMessage';
import PATTERNS from 'constants/patterns';
import _ from 'lodash';
import { logEvent, eventCategories } from 'helpers/analytics';
import getRoute from 'helpers/getRoute';
import ROUTES from 'routes.json';

import { required, pattern } from '@darkobits/formation-validators';

function FindAccountCtrl($state, Api, chModal) {
  const FindAccount = this;

  function showErrorToast(errorCode) {
    switch (errorCode) {
      case 103:
        FindAccount.apiErrorMessage =
          'Looks like your link has expired! Select who you are below to get started.';
        break;
      case 904:
        FindAccount.apiErrorMessage = "You've already registered!";
        break;
      case 909:
        // User is not at least 13 years old.
        FindAccount.apiErrorMessage =
          'Minors under the age of 13 are not eligible to register.';
        break;
      case 1203:
        // User not found.
        FindAccount.apiErrorMessage = [
          "We couldn't find you. Please double check your",
          'information below and try again.',
        ].join(' ');
        break;
      case 1212:
        // Multiple users with the same DOB/Primary Subscriber SSN found.
        // Usually twins.
        FindAccount.apiErrorMessage = [
          'It looks like two members on your plan share the same date of',
          'birth. Please give us a call and we can help you activate',
          'your account.',
        ].join(' ');
        break;
      default:
        FindAccount.apiErrorMessage =
          'Looks like our servers hit a snag. Please try again, or refresh the page.';
        break;
    }
  }

  FindAccount.$onInit = () => {
    FindAccount.PRIMARY = 'primary';
    FindAccount.DEPENDENT = 'dependent';

    logEvent('registrationLookupArrived', {
      category: eventCategories.REGISTRATION,
    });

    FindAccount.formControls = {
      subscriberSsnLastFour: {
        validators: {
          required,
          pattern: pattern(PATTERNS.lastFour),
        },
        errors: [
          ['required', 'This field is required.'],
          ['pattern', 'Please only include numbers.'],
        ],
      },
      subscriberLastName: {
        validators: {
          required,
        },
        errors: [['required', 'This field is required.']],
      },
      subscriberDateOfBirth: {
        validators: {
          required,
        },
        errors: [['required', 'This field is required.']],
      },
      dependentDateOfBirth: {
        validators: {
          required,
        },
        errors: [['required', 'This field is required.']],
      },
    };

    if (FindAccount.errorCode) {
      showErrorToast(FindAccount.errorCode);
    }
  };

  FindAccount.whoseInfo = () => {
    if (FindAccount.relationship === FindAccount.PRIMARY) {
      return 'Your';
    }
    return "Primary's";
  };

  FindAccount.isDependent = () => {
    return FindAccount.relationship === FindAccount.DEPENDENT;
  };

  FindAccount.submit = (data) => {
    let dataToSend = {};

    // Don't send the dependent info for subscriber calls otherwise call will fail
    if (FindAccount.relationship === 'primary') {
      dataToSend = _.pick(data, [
        'subscriberSsnLastFour',
        'subscriberLastName',
        'subscriberDateOfBirth',
      ]);
    } else {
      dataToSend = data;
    }

    logEvent('registrationLookupIdentity', {
      category: eventCategories.REGISTRATION,
      lookupIdentity: FindAccount.isDependent() ? 'dependent' : 'subscriber',
    });

    Api.req({
      apiPrefix: '/api/v2',
      endPoint: '/registration/token',
      method: 'POST',
      data: dataToSend,
    })
      .then((response) => {
        $state.go('Register', {
          coldReg: true,
          regtoken: response.registrationToken,
        });
      })
      .catch((error) => {
        logEvent('registrationLookupFailed', {
          category: eventCategories.REGISTRATION,
        });
        showErrorToast(error.data.errorNumber);
      });
  };

  FindAccount.showSupportMessage = () => {
    chModal.open({
      component: supportMessage,
    });
  };

  FindAccount.cancelClick = () => {
    window?.location?.replace(`/${getRoute(ROUTES.loginPage)}`);
  };
}

app.component('findAccount', {
  bindings: {
    errorCode: '<',
  },
  controller: FindAccountCtrl,
  controllerAs: 'FindAccount',
  templateUrl,
});

export default 'findAccount';
