import app from 'app';
import templateUrl from './settingsMenu.html';
import './settingsMenu.scss';
import { unsetAmplitudeUser } from 'helpers/analytics';
import getPreferredName from 'helpers/getPreferredName';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

function SettingsMenuCtrl(
  App,
  Cards,
  matchmedia,
  Session,
  SubmitClaimService,
  FeatureFlagService,
) {
  const SettingsMenu = this;

  SettingsMenu.$onInit = () => {
    SettingsMenu.isSubscriber =
      SettingsMenu.currentUser.user.id ===
      SettingsMenu.currentSponsorship.subscriber.id;

    SettingsMenu.cardsUrl =
      '/landing?sponsorId=' +
      SettingsMenu.currentSponsorship.sponsor.id +
      '#cards';

    SettingsMenu.activeMedical =
      SettingsMenu.currentSponsorship.activePlanTypes.includes('MEDICAL');

    SettingsMenu.mostRecentPastPlan =
      SubmitClaimService.getMostRecentPlanOfEachType(
        SettingsMenu.planMemberships.past,
      );
    const client = FeatureFlagService.getLDClient();

    SettingsMenu.showPaymentMethodButton = false;

    client.waitUntilReady().then(() => {
      // initialization succeeded, flag values are now available

      SettingsMenu.showPaymentMethodButton =
        SettingsMenu.isSubscriber &&
        (SettingsMenu.activeMedical ||
          SettingsMenu.mostRecentPastPlan?.MEDICAL);
    });
  };

  SettingsMenu.$onChanges = () => {
    const client = FeatureFlagService.getLDClient();

    client.waitUntilReady().then(() => {
      const isPreferredNamesEnabled = getFFVariation(
        client,
        'isPreferredNamesEnabled',
      );

      SettingsMenu.memberName = getPreferredName({
        person: SettingsMenu.currentUser.user,
        includeLastName: true,
        shouldGetPreferredName: isPreferredNamesEnabled,
      });
    });

    if (SettingsMenu.currentUser) {
      matchmedia.onDesktop((mql) => {
        SettingsMenu.isOnDesktop = mql.matches;
      });
    }
  };

  SettingsMenu.hasMultipleSponsorships = () => {
    return (
      SettingsMenu.allSponsorships &&
      SettingsMenu.allSponsorships.sponsorships.length > 1
    );
  };

  SettingsMenu.viewCards = () => {
    SettingsMenu.$close();

    Cards.openModal({
      currentUser: SettingsMenu.currentUser,
      currentSponsorship: SettingsMenu.currentSponsorship,
    });
  };

  SettingsMenu.signOut = () => {
    SettingsMenu.$close();
    unsetAmplitudeUser();
    Session.logout();
  };
}

app.component('settingsMenu', {
  bindings: {
    isSubscriber: '<',
    allSponsorships: '<',
    $close: '<',
    hasCards: '<',
    currentUser: '<',
    currentSponsorship: '<',
    planMemberships: '<',
  },
  controller: SettingsMenuCtrl,
  controllerAs: 'SettingsMenu',
  templateUrl,
});

export default 'settingsMenu';
