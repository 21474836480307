import app from 'app';
import MemberAlert from 'models/MemberAlert';

app.factory('MemberAlertService', (Api, Raven) => {
  function getAllActiveWebAlerts() {
    return Api.get('/pch/alerts?isActive=true&platformId=1')
      .then((response) =>
        response.alerts.map((alert) => new MemberAlert(alert))
      )
      .catch((error) => {
        Raven.captureMessage('Error fetching active web alerts', {
          extra: error,
        });
        // If Member Alert API fails, render as if no active alerts
        return [];
      });
  }

  return {
    getAllActiveWebAlerts,
  };
});
