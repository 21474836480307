import { ALERT_FEATURE_PRIORITY, DEFAULT_PAGE } from 'constants/memberAlert';

export default class MemberAlert {
  constructor(alert) {
    this.alert = alert;
  }

  get message() {
    return this.alert.message;
  }

  get featureCode() {
    return this.alert.feature.code;
  }

  get pages() {
    switch (this.featureCode) {
      case 'phone':
        return ['landing', 'login', 'registration-warm'];
      case 'chat':
        return ['landing'];
      case 'cards':
      case 'doctor-finder':
      case 'cost-transparency':
      case 'msoon':
        return [''];
      default:
        return [DEFAULT_PAGE];
    }
  }

  get priority() {
    return ALERT_FEATURE_PRIORITY.indexOf(this.featureCode);
  }

  isOnPage(pageName) {
    return this.pages.includes(pageName);
  }
}
