import app from 'app';
import templateUrl from './fundsSsoLink.html';

import { logEvent, eventCategories } from 'helpers/analytics';

function FundsSsoLink() {
  const FundsSsoLink = this;

  FundsSsoLink.trackClick = function() {
    logEvent('fundsSSO', {
      category: eventCategories.FUNDS,
      partner: FundsSsoLink.partnerTrackingName,
      accountType: FundsSsoLink.accountType
    });
  };
}

app.component('fundsSsoLink', {
  bindings: {
    accountType: '<',
    partnerId: '<?',
    partnerTrackingName: '@?',
    spendingAccountId: '<?'
  },
  transclude: true,
  controller: FundsSsoLink,
  controllerAs: 'FundsSsoLink',
  templateUrl
});
