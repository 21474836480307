import app from 'app';
import templateUrl from './legalDeclineConfirm.html';
import './legalDeclineConfirm.scss';

function LegalDeclineConfirm() {}

app.component('legalDeclineConfirm', {
  bindings: {
    back: '&',
  },
  controller: LegalDeclineConfirm,
  controllerAs: 'LegalDeclineConfirm',
  templateUrl,
});
