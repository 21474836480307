import app from 'app';
import templateUrl from './memberCardImg.html';
import './memberCardImg.scss';

function MemberCardImg($element) {
  const MemberCardImg = this;

  MemberCardImg.$onInit = function () {
    $element.find('img').on('error', MemberCardImg.imgError);
  };

  MemberCardImg.$onDestroy = function () {
    $element.find('img').off('error', MemberCardImg.imgError);
  };
}

app.component('memberCardImg', {
  bindings: {
    imgSrc: '<',
    imgError: '&',
  },
  controller: MemberCardImg,
  controllerAs: 'MemberCardImg',
  templateUrl,
});
