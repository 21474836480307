export default class CobPlan {
  constructor(cobPlan) {
    this.plan = cobPlan;
  }

  get cobPlanType() {
    return this.plan.cobPlanType;
  }

  get cobSubscriber() {
    return this.plan.cobSubscriber;
  }

  get subscriberMemberId() {
    return this.plan.subscriberMemberId;
  }

  get cobPatient() {
    return this.plan.cobPatient;
  }

  get sponsorName() {
    return this.plan.sponsorName;
  }

  get networkPartnerName() {
    return this.plan.networkPartnerName;
  }

  get startDate() {
    return this.plan.startDate;
  }

  get endDate() {
    return this.plan.endDate;
  }
}
