import moment from 'moment';
import _ from 'lodash';
import { SERVER_DATE_FORMAT } from 'constants/dateTime';
import { isOverAge } from 'helpers/age';
const planOrder = ['MEDICAL', 'PHARMACY', 'DENTAL', 'VISION'];

function _getServerDateMoment(date) {
  return moment(date, SERVER_DATE_FORMAT);
}

export default class PlanMemberships {
  constructor(memberships) {
    this.memberships = memberships;
  }

  sortPlans(plans) {
    return plans.sort((a, b) => {
      return planOrder.indexOf(a.planType) - planOrder.indexOf(b.planType);
    });
  }

  get current() {
    if (!this._current) {
      this._current = this.sortPlans(this.memberships.current);
    }
    return this._current;
  }

  getCurrentPlanByType(type) {
    return _.find(
      this._current,
      (plan) => plan.planType.toLowerCase() === type.toLowerCase()
    );
  }

  get past() {
    if (!this._past) {
      this._past = this.sortPlans(this.memberships.past);
    }
    return this._past;
  }

  get future() {
    if (!this._future) {
      this._future = this.sortPlans(this.memberships.future);
    }
    return this._future;
  }

  get allPlans() {
    if (!this._allPlans) {
      this._allPlans = this.past.concat(this.current).concat(this.future);
    }
    return this._allPlans;
  }

  get hasCurrentOrFuturePlanMembership() {
    if (!this._hasCurrentOrFuturePlanMembership) {
      this._hasCurrentOrFuturePlanMembership =
        this.hasCurrentCHPlanMembership() || this.hasFutureCHPlanMembership();
    }
    return this._hasCurrentOrFuturePlanMembership;
  }

  get hasCurrentOrFutureMedicalPlanMembership() {
    if (!this._hasCurrentOrFutureMedicalPlanMembership) {
      this._hasCurrentOrFutureMedicalPlanMembership =
        this.current.filter((pm) => pm.planType.toLowerCase() === 'medical')
          .length > 0 ||
        this.future.filter((pm) => pm.planType.toLowerCase() === 'medical')
          .length > 0;
    }
    return this._hasCurrentOrFutureMedicalPlanMembership;
  }

  get hasCards() {
    if (!this._hasCards) {
      this._hasCards =
        this.hasCurrentCHPlanMembership() || this.hasFutureCHPlanMembership();
    }

    return this._hasCards;
  }

  get hasCurrentDentalMembership() {
    if (!this._hasCurrentDentalMembership) {
      this._hasCurrentDentalMembership =
        this.current.filter((pm) => pm.planType.toLowerCase() === 'dental')
          .length > 0;
    }
    return this._hasCurrentDentalMembership;
  }

  get hasCurrentVisionMembership() {
    if (!this._hasCurrentVisionMembership) {
      this._hasCurrentVisionMembership =
        this.current.filter((pm) => pm.planType.toLowerCase() === 'vision')
          .length > 0;
    }
    return this._hasCurrentVisionMembership;
  }

  get coveredMembers() {
    if (!this._coveredMembers) {
      this._coveredMembers = this.constructCoveredMembers(this.memberships);
    }
    return this._coveredMembers;
  }

  get currentCoveredMembers() {
    if (!this._currentCoveredMembers) {
      this._currentCoveredMembers = this.constructCurrentCoveredMembers(
        this.memberships
      );
    }
    return this._currentCoveredMembers;
  }

  get nonSubscriberCoveredMembers() {
    if (!this._nonSubscriberCoveredMembers) {
      this._nonSubscriberCoveredMembers = this.coveredMembers.filter(
        (member) => !member.isSubscriber
      );
    }
    return this._nonSubscriberCoveredMembers;
  }

  get coveredMembersOver13() {
    if (!this._coveredMembersOver13) {
      this._coveredMembersOver13 = this.coveredMembers.filter((member) =>
        isOverAge(member.dateOfBirth, 12)
      );
    }
    return this._coveredMembersOver13;
  }

  get currentNonPharmacyPlans() {
    if (!this._currentNonPharmacyPlans) {
      this._currentNonPharmacyPlans = this.current.filter(
        (pm) => pm.planType.toLowerCase() !== 'pharmacy'
      );
    }
    return this._currentNonPharmacyPlans;
  }

  get futureNonPharmacyPlans() {
    if (!this._futureNonPharmacyPlans) {
      return this._future.filter(
        (pm) => pm.planType.toLowerCase() !== 'pharmacy'
      );
    }
    return this._futureNonPharmacyPlans;
  }

  // CH-46368 allow expired membership (up to 1 year) for claim submission
  get planTypesClaimSubmission() {
    if (this.current && this.current.length > 0) {
      return this.current.map((pm) => pm.planType);
    }

    const oneYearAgo = moment().subtract(1, 'years');
    return this.past
      .filter((pm) => oneYearAgo.isBefore(moment(pm.endDate)))
      .map((pm) => pm.planType);
  }

  get activePlanTypes() {
    if (!this._activePlanTypes) {
      this._activePlanTypes = this.current.map((pm) => pm.planType);
    }
    return this._activePlanTypes;
  }

  get activePlanIds() {
    if (!this._activePlanIds) {
      this._activePlanIds = this.current.map((pm) => pm.planId);
    }
    return this._activePlanIds;
  }

  get allPossiblePlanTypes() {
    if (!this._allPossiblePlanTypes) {
      const planTypes = {};

      this.allPlans.forEach((plan) => {
        if (!planTypes[plan.planType]) {
          planTypes[plan.planType.toLowerCase()] = true;
        }
      });

      this._allPossiblePlanTypes = Object.keys(planTypes).sort((a, b) => {
        return (
          planOrder.indexOf(a.toUpperCase()) -
          planOrder.indexOf(b.toUpperCase())
        );
      });
    }
    return this._allPossiblePlanTypes;
  }

  get coveredMembersWithoutSubscriber() {
    if (!this._coveredMembersWithoutSubscriber) {
      this._coveredMembersWithoutSubscriber = this._coveredMembers.filter(
        (coveredMember) => !coveredMember.isSubscriber
      );
    }
    return this._coveredMembersWithoutSubscriber;
  }

  latestPastMembershipEndDate() {
    return _getServerDateMoment(
      this.past.sort(
        (pm1, pm2) =>
          _getServerDateMoment(pm2.endDate) - _getServerDateMoment(pm1.endDate)
      )[0].endDate
    );
  }

  nextFutureMembershipStartDate() {
    return _getServerDateMoment(
      this.future.sort(
        (pm1, pm2) =>
          _getServerDateMoment(pm1.startDate) -
          _getServerDateMoment(pm2.startDate)
      )[0].startDate
    );
  }

  getCoveredMember(id) {
    return this.coveredMembers.find((member) => member.id === id);
  }

  constructCoveredMembers(memberships) {
    const personMap = {};

    Object.values(memberships).forEach((planMembershipByTimePeriod) => {
      planMembershipByTimePeriod.forEach((plan) => {
        plan.coveredPersonList.forEach((person) => {
          if (!personMap[person.id]) {
            personMap[person.id] = person;
          }
        });
      });
    });

    return Object.values(personMap);
  }

  constructCurrentCoveredMembers(memberships) {
    const personMap = {};

    Object.values(memberships.current).forEach((plan) => {
      plan.coveredPersonList.forEach((person) => {
        if (!personMap[person.id]) {
          personMap[person.id] = person;
        }
      });
    });

    return Object.values(personMap);
  }

  hasCHPlanMembership() {
    return (
      this.hasCurrentCHPlanMembership() ||
      this.hasPastCHPlanMembership() ||
      this.hasFutureCHPlanMembership()
    );
  }

  hasCurrentCHPlanMembership() {
    return (
      this.current.length > 0 &&
      this.current.filter((pm) => pm.plan.isCHPlan).length > 0
    );
  }

  hasFutureCHPlanMembership() {
    return (
      this.future.length > 0 &&
      this.future.filter((pm) => pm.plan.isCHPlan).length > 0
    );
  }

  hasPastCHPlanMembership() {
    return (
      this.past.length > 0 &&
      this.past.filter((pm) => pm.plan.isCHPlan).length > 0
    );
  }

  canSubmitClaim() {
    if (this.current.length > 0) {
      return true;
    }

    // members are able to submit claims up to 1 year after their plan ends
    if (this.past.length > 0) {
      const oneYearAgo = moment().subtract(1, 'years');
      for (let i = 0; i < this.past.length; i++) {
        if (oneYearAgo.isBefore(moment(this.past[i].endDate))) {
          return true;
        }
      }
    }

    return false;
  }
}
