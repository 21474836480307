import app from 'app';
import templateUrl from './landingRecommendationSuggestions.html';
import './landingRecommendationSuggestions.scss';

import './recommendationCardSuggestion/recommendationCardSuggestion';
import './faqRecommendationSuggestion/faqRecommendationSuggestion';
import { logEvent, eventCategories } from 'helpers/analytics';

import NAVIGATION from 'constants/navigation';

function LandingRecommendationSuggestions(Recommendations, chModal, $state) {
  const LandingRecommendationSuggestions = this;

  LandingRecommendationSuggestions.$onInit = function() {
    Recommendations.selectedRecommendation = null;
  };

  LandingRecommendationSuggestions.openFaqModal = function() {
    logEvent('openRecommendationDisclaimer', {
      category: eventCategories.PERSONALIZATION,
      page: NAVIGATION.HOME.toLowerCase()
    });

    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: 'faqRecommendationSuggestion'
    });
  };
}

app.component('landingRecommendationSuggestions', {
  bindings: {
    recommendations: '<',
    onDismissRecommendation: '<'
  },
  controller: LandingRecommendationSuggestions,
  controllerAs: 'LandingRecommendationSuggestions',
  templateUrl
});
