import app from 'app';
import _ from 'lodash';
import templateUrl from './registrationForm.html';
import './registrationForm.scss';
import './registrationFormService';
import PATTERNS from 'constants/patterns';
import { CONFIG_NAME } from 'constants/legal';
import mxPopover from 'components/tooltips/mxPopover/mxPopover';
import { logEvent, eventCategories } from 'helpers/analytics';
import { COLLECTIVE_TITLE } from '../../../collectiveTitle';

function RegistrationCtrl(
  $state,
  chModal,
  Registration,
  RegistrationForm,
  ServerErrorCodes,
  animatedScrollTop,
  FrontendLegal,
) {
  const RegistrationCtrl = this;
  RegistrationCtrl.COLLECTIVE_TITLE = COLLECTIVE_TITLE;

  RegistrationCtrl.passwordPopover = {
    component: mxPopover,
    locals: {
      content: `Your password must be at least 8 characters and include 1 uppercase letter,
        1 lowercase letter, and 1 number`,
    },
    attachment: 'top left',
    targetAttachment: 'bottom left',
    offset: '-4px 0',
  };

  function setError(code) {
    RegistrationCtrl.error = ServerErrorCodes.getMessageForCode(code);
    animatedScrollTop();
  }

  function register() {
    const loadingModal = chModal.open({
      template: `
        <mx-modal>
          <mx-modal-header>
            Please wait while we activate your account.
          </mx-modal-header>
          <mx-modal-body>
            <ch-spinner class="RegistrationPage__spinner"></ch-spinner>
          </mx-modal-body>
        </mx-modal>
      `,
    });

    RegistrationCtrl.member.email = RegistrationCtrl.member.email.toLowerCase();

    // Make API call.
    return Registration.register(RegistrationCtrl.member)
      .then((response) => {
        logRegEvent('registrationSuccess', {
          emailChanged:
            RegistrationCtrl.member.email !== RegistrationCtrl.initialEmail,
        });
        return response;
      })
      .then(RegistrationForm.handleRegistrationSuccess)
      .catch((error) => {
        if (_.has(error, 'data.errorNumber')) {
          setError(error.data.errorNumber);
        } else {
          setError(901);
        }
      })
      .finally(() => {
        loadingModal.$close();
      });
  }

  function logRegEvent(eventName, properties = {}) {
    logEvent(
      eventName,
      Object.assign(
        {
          category: eventCategories.REGISTRATION,
          source: RegistrationCtrl.isColdReg ? 'coldRegFlow' : 'warmRegFlow',
        },
        properties,
      ),
    );
  }

  RegistrationCtrl.$onInit = () => {
    const regToken = $state.params.regtoken;

    RegistrationCtrl.member.token = regToken;
    RegistrationCtrl.patterns = PATTERNS;
    RegistrationCtrl.initialEmail = RegistrationCtrl.member.email;

    RegistrationCtrl.memberInfo = {
      email: RegistrationCtrl.member.email,
      firstName: RegistrationCtrl.member.firstName,
      lastName: RegistrationCtrl.member.lastName,
      showDoBField: $state.params.coldReg !== 'true',
    };

    RegistrationCtrl.hasBlurred = {};

    RegistrationCtrl.termsStateName =
      FrontendLegal.configurations[CONFIG_NAME].terms.stateName;

    logRegEvent('registrationPageArrived', {});
  };

  RegistrationCtrl.isFormValid = (form) => {
    return form.$invalid;
  };

  RegistrationCtrl.setBlurHistory = (fieldName) => {
    RegistrationCtrl.hasBlurred[fieldName] = true;
  };

  RegistrationCtrl.shouldShowError = (fieldName) => {
    return (
      (RegistrationCtrl.regForm[fieldName].$dirty &&
        RegistrationCtrl.regForm[fieldName].$invalid &&
        RegistrationCtrl.hasBlurred[fieldName]) ||
      (RegistrationCtrl.regForm.$submitted &&
        RegistrationCtrl.regForm[fieldName].$invalid)
    );
  };

  RegistrationCtrl.precheckRegistrationForm = () => {
    if (RegistrationCtrl.regForm.$invalid) {
      setError(900);
      return;
    }

    return register();
  };

  RegistrationCtrl.trackTosAccept = function () {
    if (RegistrationCtrl.member.tosAgreement) {
      logRegEvent('registrationTermsAccepted', {});
    }
  };
}

app.component('registrationForm', {
  bindings: {
    supportPhone: '<',
    member: '<',
    termsCopy: '<',
    isColdReg: '<',
    campaign: '<',
  },
  controller: RegistrationCtrl,
  controllerAs: 'Registration',
  templateUrl,
});

export default 'registrationForm';
