import app from 'app';
import templateUrl from './legalAcceptance.html';
import './legalAcceptance.scss';

import './legalCopy/legalCopy';
import './legalDeclineConfirm/legalDeclineConfirm';

function LegalAcceptanceCtrl($state, FlLegalService, Raven, Session) {
  const LegalAcceptance = this;
  LegalAcceptance.hasDeclined = false;

  LegalAcceptance.acceptLegal = () => {
    return FlLegalService.acceptLegalContract(LegalAcceptance.token)
      .then((response) => {
        const { loginToken } = response;
        Session.beginSession(loginToken);
        return Session.getSession();
      })
      .then(() => {
        /**
         * This is the first time that the user logs in.
         * Because of this, it'll always go to landing page first.
         */
        $state.go('Landing');
      })
      .catch(() => {
        Raven.captureMessage('Error accepting new legal terms.');
        $state.go('Login', { errorCode: 500 });
      });
  };

  LegalAcceptance.setHasDeclined = function (hasDeclined = false) {
    LegalAcceptance.hasDeclined = hasDeclined;
  };
}

app.component('legalAcceptance', {
  bindings: {
    token: '<',
    copyHtml: '<',
    legalTypes: '<',
  },
  controller: LegalAcceptanceCtrl,
  controllerAs: 'LegalAcceptance',
  templateUrl,
});

export default 'legalAcceptance';
