import app from 'app';
import _ from 'lodash';

/**
 * Works just like the stock currency filter, but only shows decimals if they
 * are non-zero.
 *
 * @param  {*} input - String or number to parse.
 * @param  {string} [symbol] - Optional currency symbol to use. Defers to
 *   $locale otherwise.
 * @param {string|number} [precision=2] - Number of decimal places to display, if
 *   'input' has a non-zero decimal value.
 * @return {string} - Formatted string.
 */
app.filter('chCurrency', ($filter, $locale) => {
  return (input, symbol, precision) => {
    if (isNaN(input)) {
      return input;
    }
    const value = parseFloat(input);
    const hasDecimals = value % 1;

    symbol = symbol || $locale.NUMBER_FORMATS.CURRENCY_SYM;
    precision = _.isUndefined(precision) ? 2 : precision;

    return $filter('currency')(value, symbol, hasDecimals ? precision : '0');
  };
});
