import app from 'app';
import _ from 'lodash';
import templateUrl from './supportMessage.html';
import './supportMessage.scss';

import mxConfirmationModal from 'components/modals/mxConfirmationModal/mxConfirmationModal';

function SupportMessageCtrl($q, $state, Api, chModal, Sponsor, User) {
  const SupportMsg = this;

  SupportMsg.$onInit = () => {
    // Init message model.
    SupportMsg.message = {};

    SupportMsg.subjectOptions = [
      'Insurance cards, plan sign-up / registration, or personal information change',
      'My enrollment status or changes and additions to my plan',
      'Medical services coverage and benefits',
      'Dental, pharmacy, or vision coverage and benefits',
      'Find a provider or verify a provider is in network',
      'Claims, provider bills, or benefits statements',
      'None of these',
    ].map((option) => ({ label: option, value: option }));
    // default subject
    SupportMsg.selectedSubject = SupportMsg.subjectOptions[0].value;

    const sponsorship = Sponsor.getCurrentSponsorship();
    SupportMsg.message.sponsorCode =
      SupportMsg.message.sponsorCode || sponsorship.sponsor.sponsorCode;

    User.getUser().then((data) => {
      const { user } = data;
      SupportMsg.message.name =
        SupportMsg.message.name || `${user.firstName} ${user.lastName}`;

      // Clone and pre-fill email.
      SupportMsg.message.emailAddress = _.clone(user.email);
    });

    // Allow for pre-population of the message, ie from Plan Ask Question.
    if (SupportMsg.populateMessage) {
      SupportMsg.message = Object.assign(
        {},
        SupportMsg.message,
        SupportMsg.populateMessage
      );
    }

    SupportMsg.updateSubject = (subject) => {
      SupportMsg.selectedSubject = subject.value;
    };
  };

  function sendSupportMessage(message) {
    const url = '/support_message';

    // Optional attrs: sponsorCode
    // Optional Coming Soon: planId (not suppored by API yet)
    var required = ['name', 'emailAddress', 'message', 'emailUnsecureAck'];

    // Check that all required keys are present
    if (_.difference(required, _.keys(message)).length) {
      return $q.reject('sendSupportMessage() missing required params');
    }

    // Email unsecure acknowledgement must be true
    if (!message.emailUnsecureAck) {
      return $q.reject(
        'sendSupportMessage() requires emailUnsecureAck param to be "true"'
      );
    }

    message.emailAddress = message.emailAddress.toLowerCase();
    return Api.post(url, message);
  }

  // Main handler exposed to templates to send message
  SupportMsg.sendSupportMessage = () => {
    if (!SupportMsg.messageForm.$valid) {
      return;
    }

    return sendSupportMessage(
      _.assign({}, SupportMsg.message, {
        subject: SupportMsg.selectedSubject,
        message: _.compact([
          'SOURCE: Member Portal - Generic Support Request',
          SupportMsg.currentSponsor
            ? 'EMPLOYER: ' + SupportMsg.currentSponsor.name
            : null,
          'MEMBER: ' + SupportMsg.message.name,
          'EMAIL: ' + SupportMsg.message.emailAddress,
          'MESSAGE: ' + SupportMsg.message.message,
        ]).join('\n\n'),
      })
    )
      .then(() => {
        SupportMsg.$close();
        chModal.open({
          component: mxConfirmationModal,
          locals: {
            body: 'We will get back to you within one business day.',
            confirm: 'Got it',
            header: 'Your email has been sent!',
          },
        });
      })
      .catch((response) => {
        if (response.status === 400) {
          SupportMsg.showErrorToast = true;
        } else if (response.status === 500) {
          SupportMsg.$close();
        }
      });
  };
}

app.component('supportMessage', {
  bindings: {
    $close: '<',
    populateMessage: '<',
  },
  controller: SupportMessageCtrl,
  controllerAs: 'SupportMsg',
  templateUrl,
});

export default 'supportMessage';
