import app from 'app';
import templateUrl from './mxRegisteredModal.html';
import './mxRegisteredModal.scss';
import { COLLECTIVE_TITLE } from '../../../collectiveTitle';

function MxRegisteredModal() {
  const MxRegisteredModal = this;
  MxRegisteredModal.COLLECTIVE_TITLE = COLLECTIVE_TITLE;
}
app.component('mxRegisteredModal', {
  bindings: {
    $close: '<',
  },
  controller: MxRegisteredModal,
  controllerAs: 'MxRegisteredModal',
  templateUrl,
});

export default 'mxRegisteredModal';
