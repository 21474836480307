import app from 'app';
import _ from 'lodash';
import templateUrl from './benefitCardLight.html';
import './benefitCardLight.scss';

import { logEvent, eventCategories } from 'helpers/analytics';
import { getLgIconForKey } from 'helpers/plans';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-right';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-premium-perks';

function BenefitCardLight($state, PartnersService) {
  const BenefitCardLight = this;

  BenefitCardLight.$onInit = () => {
    BenefitCardLight.benefit = _.find(BenefitCardLight.planBenefits, {
      key: BenefitCardLight.canonicalBenefit.key
    });

    BenefitCardLight.hasPartner = PartnersService.hasValidBenefitPartners(
      BenefitCardLight.benefit.partners,
      BenefitCardLight.allPartners
    );

    BenefitCardLight.iconKey = getLgIconForKey(
      BenefitCardLight.canonicalBenefit.key
    );
  };

  BenefitCardLight.goToBenefit = benefitKey => {
    logEvent('benefitViewed', {
      category: eventCategories.PLANS,
      benefitType: BenefitCardLight.benefit.key,
      planType: BenefitCardLight.planType
    });
    $state.go('BenefitDetail', { benefitKey: benefitKey });
  };
}

app.component('benefitCardLight', {
  bindings: {
    canonicalBenefit: '<',
    planBenefits: '<',
    allPartners: '<',
    planType: '@'
  },
  controllerAs: 'BenefitCardLight',
  controller: BenefitCardLight,
  templateUrl
});

export default 'benefitCardLight';
