import app from 'app';
import { capitalize, remove } from 'lodash';
import 'components/supportMessageLink/supportMessageLink';
import { logEvent, eventCategories } from 'helpers/analytics';
import templateUrl from './proofOfCoverage.html';
import './proofOfCoverage.scss';

// Assets
import errorImageUrl from './Image.png';
import getPreferredName from 'helpers/getPreferredName';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

function ProofOfCoverageCtrl($window, Cards, FeatureFlagService) {
  const ProofOfCoverageCtrl = this;

  const client = FeatureFlagService.getLDClient();

  client.waitUntilReady().then(() => {
    const isPreferredNamesEnabled = getFFVariation(
      client,
      'isPreferredNamesEnabled',
    );

    if (isPreferredNamesEnabled) {
      ProofOfCoverageCtrl.isPreferredNamesEnabled = isPreferredNamesEnabled;
    }
  });

  function getMemberOption(member) {
    const memberName = getPreferredName({
      person: member,
      shouldGetPreferredName: ProofOfCoverageCtrl.isPreferredNamesEnabled,
    });
    return {
      label: memberName,
      value: member.planMembershipId,
    };
  }

  function getPlanOption(plan, members) {
    return {
      label: capitalize(plan),
      value: plan,
      coveredPersonList: members,
    };
  }

  ProofOfCoverageCtrl.$onInit = () => {
    Cards.getPlansAndMembersForPoCov(
      ProofOfCoverageCtrl.currentSponsorship.id,
      ProofOfCoverageCtrl.currentUser.user.userIdentifier,
    ).then((response) => {
      const plans = [];
      let eligibleMembers = [];
      const order = ['MEDICAL', 'DENTAL', 'VISION'];
      order.forEach((plan) => {
        if (response[plan]) {
          let planOption = getPlanOption(plan, response[plan]);
          plans.push(planOption);
          planOption.coveredPersonList.forEach(({ memberName }) =>
            eligibleMembers.push(memberName),
          );
        }
      });
      eligibleMembers = Array.from(new Set(eligibleMembers));
      ProofOfCoverageCtrl.eligibleMembers = eligibleMembers;

      ProofOfCoverageCtrl.coverage = plans;
      const selectedCoverage = ProofOfCoverageCtrl.coverage[0];
      ProofOfCoverageCtrl.family = selectedCoverage.coveredPersonList.map(
        (member) => getMemberOption(member),
      );
      if (ProofOfCoverageCtrl.family.length) {
        ProofOfCoverageCtrl.selectedMembers = [
          ProofOfCoverageCtrl.family[0].value,
        ];
      }

      ProofOfCoverageCtrl.selectedCoverage = selectedCoverage;
      ProofOfCoverageCtrl.loading = false;
    });

    // Assets
    ProofOfCoverageCtrl.errorImageUrl = errorImageUrl;
    // Initial Values
    ProofOfCoverageCtrl.family = [];
    ProofOfCoverageCtrl.coverage = [];
    ProofOfCoverageCtrl.loading = true;
    ProofOfCoverageCtrl.generated = false;
    ProofOfCoverageCtrl.selectedMembers = [];
    ProofOfCoverageCtrl.error = false;
  };

  ProofOfCoverageCtrl.selectCoverage = (selected) => {
    //logEvent('proofOfCoveragePlanSelected', { category: eventCategories.CARDS });
    // TO FIX: Find selected member because native dropdown only returns id, not full object
    const plan = ProofOfCoverageCtrl.coverage.find(
      (plan) => plan.value === selected.value,
    );
    const currentPersons =
      ProofOfCoverageCtrl.selectedCoverage.coveredPersonList;
    const selectedPersons = ProofOfCoverageCtrl.selectedMembers.map(
      (membershipId) => {
        return currentPersons.find(
          (person) => person.planMembershipId === membershipId,
        );
      },
    );
    ProofOfCoverageCtrl.family = plan.coveredPersonList.map((member) =>
      getMemberOption(member),
    );
    const newSelectedPersons = plan.coveredPersonList.filter((person) => {
      return selectedPersons.find(
        (selected) => selected.memberName === person.memberName,
      );
    });
    ProofOfCoverageCtrl.selectedCoverage = plan;
    ProofOfCoverageCtrl.selectedMembers = newSelectedPersons.map(
      (person) => person.planMembershipId,
    );
  };

  ProofOfCoverageCtrl.selectMember = (selected) => {
    if (ProofOfCoverageCtrl.selectedMembers.indexOf(selected) === -1) {
      ProofOfCoverageCtrl.selectedMembers.push(selected);
    } else {
      ProofOfCoverageCtrl.selectedMembers = remove(
        ProofOfCoverageCtrl.selectedMembers,
        (n) => n !== selected,
      );
    }
  };

  ProofOfCoverageCtrl.generatePoCov = () => {
    logEvent('pocGenerateButtonClicked', {
      category: eventCategories.PROOF_OF_COVERAGE,
      planType: ProofOfCoverageCtrl.selectedCoverage.label.toLowerCase(),
      numMemberSelected: ProofOfCoverageCtrl.selectedMembers.length,
    });
    Cards.requestProofOfCoverage({
      userIdentifier: ProofOfCoverageCtrl.currentUser.user.userIdentifier,
      planMembershipIds: ProofOfCoverageCtrl.selectedMembers,
      type: ProofOfCoverageCtrl.selectedCoverage.label.toUpperCase(),
    })
      .then((response) => {
        logEvent('pocLetterSuccess', {
          category: eventCategories.PROOF_OF_COVERAGE,
          pocCreationSuccess: true,
        });
        ProofOfCoverageCtrl.generated = true;
        $window.open(response.pdf_version_path, '_blank');
      })
      .catch(() => {
        logEvent('pocLetterSuccess', {
          category: eventCategories.PROOF_OF_COVERAGE,
          pocCreationSuccess: false,
        });
        ProofOfCoverageCtrl.error = true;
      });
  };

  ProofOfCoverageCtrl.retry = () => {
    ProofOfCoverageCtrl.error = false;
  };

  ProofOfCoverageCtrl.handleSubmitASecureMessageLinkClicked = () => {
    logEvent('pocSubmitMessageSelected', {
      category: eventCategories.PROOF_OF_COVERAGE,
    });
    ProofOfCoverageCtrl.$close();
  };

  ProofOfCoverageCtrl.handleCloseButtonClicked = () => {
    logEvent('pocExited', {
      category: eventCategories.PROOF_OF_COVERAGE,
      page: 'coverageAndMemberSelection',
    });
    ProofOfCoverageCtrl.$close();
  };
}

app.component('proofOfCoverage', {
  bindings: {
    $close: '<',
    currentUser: '<',
    currentSponsorship: '<',
    planMemberships: '<',
  },
  controller: ProofOfCoverageCtrl,
  controllerAs: 'ProofOfCoverage',
  templateUrl,
});

export default 'proofOfCoverage';
