export default {
  serviceDays: 'Monday - Friday',
  serviceHours: '5am to 6pm Pacific Time',
  defaultSupportEmail: 'help@collectivehealth.com',
  defaultSupportPhone: '844-803-0210',
  requiredSponsorDocs: [
    {
      title: 'Notice of Privacy Practices',
      description: [
        'We care about keeping your healthcare information private. This notice summarizes your healthcare privacy ',
        'rights, and details how Collective Health may use or disclose your healthcare information. Read it carefully.',
      ].join(''),
      expandKey: 'privacyPolicy',
    },
    {
      title: 'Notice of HIPAA Special Enrollment Rights',
      description: [
        'Usually you can only change your benefit selections during open enrollment. This notice describes some ',
        'circumstances when you may enroll in these health plans after the open enrollment period is over.',
      ].join(''),
      expandKey: 'hipaaNotice',
    },
    {
      title: 'Premium Assistance Under Medicaid and CHIP',
      description: [
        "Your state Children's Health Insurance program (CHIP) or Medicaid program may provide subsidies you can ",
        'use to pay for health plans sponsored by your employer. This notice provides information about these ',
        'programs and who to contact for more information.',
      ].join(''),
      expandKey: 'premiumAssistanceNotice',
    },
    {
      title: "Women's Health and Cancer Rights Notice",
      description: [
        "If you have to have a mastectomy, the Women's Health and Cancer Rights Act entitles you to certain ",
        'benefits from your health plan. This notice describes those benefits.',
      ].join(''),
      expandKey: 'womensHealthNotice',
    },
    {
      title: 'Notice of Surprise Medical Bills Protection',
      description: [
        'The notice describes your protections from surprise medical bills or ',
        'balance billing for certain out-of-network services starting 1/1/2022.',
      ].join(''),
      expandKey: 'surpriseMedicalBills',
    },
    {
      title: 'Disabled Dependent Review Form',
      description:
        'Complete this form to begin the over-age dependent (OAD) review process.',
      expandKey: 'overAgeDependentForm',
    },
  ],
  spdSponsorDocs: [
    {
      title:
        'Summary Plan Description: Healthcare Flexible Spending Account (FSA)',
      description:
        'This Summary Plan Description outlines the benefits and rights you have under your FSA.',
      expandKey: 'fsaSPD',
    },
    {
      title:
        'Summary Plan Description: Dependent Care Flexible Spending Account (DCA)',
      description:
        'This Summary Plan Description outlines the benefits and rights you have under your DCA.',
      expandKey: 'dcfsaSPD',
    },
    {
      title: 'Summary Plan Description: Health Reimbursement Account (HRA)',
      description:
        'This Summary Plan Description outlines the benefits and rights you have under your HRA.',
      expandKey: 'hraSPD',
    },
  ],
};
