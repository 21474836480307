import app from 'app';
import templateUrl from './benefitCardFull.html';
import './benefitCardFull.scss';
import _ from 'lodash';

import { logEvent, eventCategories } from 'helpers/analytics';
import { getLgIconForKey } from 'helpers/plans';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-right';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-premium-perks';

function BenefitCardFull($state, BenefitService, PartnersService) {
  const BenefitCardFull = this;

  BenefitCardFull.$onInit = () => {
    BenefitCardFull.benefit = _.find(BenefitCardFull.planBenefits, {
      key: BenefitCardFull.canonicalBenefit.key
    });

    BenefitCardFull.isAvailable = BenefitService.isBenefitAvailable(
      BenefitCardFull.planBenefits,
      BenefitCardFull.canonicalBenefit.key
    );

    BenefitCardFull.hasPartner = PartnersService.hasValidBenefitPartners(
      BenefitCardFull.benefit.partners,
      BenefitCardFull.allPartners
    );

    BenefitCardFull.iconKey = getLgIconForKey(
      BenefitCardFull.canonicalBenefit.key
    );
  };

  BenefitCardFull.goToBenefit = benefitKey => {
    if (BenefitCardFull.isAvailable) {
      logEvent('benefitViewed', {
        category: eventCategories.PLANS,
        benefitType: BenefitCardFull.benefit.key,
        planType: BenefitCardFull.planType
      });
      $state.go('BenefitDetail', { benefitKey: benefitKey });
    }
  };
}

app.component('benefitCardFull', {
  bindings: {
    canonicalBenefit: '<',
    planBenefits: '<',
    allPartners: '<',
    planType: '@'
  },
  controllerAs: 'BenefitCardFull',
  controller: BenefitCardFull,
  templateUrl
});

export default 'benefitCardFull';
