export default [
  {
    title: 'Notice of Privacy Practices',
    expandKey: 'privacyPolicy',
    description: `
      We care about keeping your healthcare information private. This notice
      summarizes your healthcare privacy rights, and details how Collective
      Health may use or disclose your healthcare information. Read it carefully.
    `,
  },
  {
    title: 'Notice of HIPAA Special Enrollment Rights',
    expandKey: 'hipaaNotice',
    description: `
      Usually you can only change your benefit selections during open
      enrollment. This notice describes some circumstances when you may enroll
      in these health plans after the open enrollment period is over.
    `,
  },
  {
    title: 'Premium Assistance Under Medicaid and CHIP',
    expandKey: 'premiumAssistanceNotice',
    description: `
      Your state Children's Health Insurance program (CHIP) or Medicaid program
      may provide subsidies you can use to pay for health plans sponsored by
      your employer. This notice provides information about these programs and
      who to contact for more information.
    `,
  },
  {
    title: "Women's Health and Cancer Rights Notice",
    expandKey: 'womensHealthNotice',
    description: `
      If you have to have a mastectomy, the Women's Health and Cancer Rights
      Act entitles you to certain benefits from your health plan. This notice
      describes those benefits.
    `,
  },
  {
    title: 'Notice of Surprise Medical Bills Protection',
    expandKey: 'surpriseMedicalBills',
    description: `
      The notice describes your protections from surprise medical bills or
      balance billing for certain out-of-network services starting 1/1/2022.
    `,
  },
  {
    title: 'Disabled Dependent Review Form',
    expandKey: 'overAgeDependentForm',
    description: `
      Complete this form to begin the over-age dependent (OAD) review process.
    `,
  },
];
