import app from 'app';
import templateUrl from './checkboxListItem.html';
import './checkboxListItem.scss';

app.component('checkboxListItem', {
  bindings: {
    description: '<',
    disabled: '<?',
    label: '<',
    value: '=',
  },
  controllerAs: 'CheckboxListItem',
  templateUrl,
});

export default 'checkboxListItem';
