import app from 'app';
import templateUrl from './landingClaim.html';
import './landingClaim.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-right';

function LandingClaim() {
  const LandingClaim = this;

  LandingClaim.$onInit = function () {
    LandingClaim.patient =
      LandingClaim.claim.patient.id === LandingClaim.currentUser.id
        ? 'you'
        : LandingClaim.claim.patient.firstName;
  };
}

app.component('landingClaim', {
  bindings: {
    claim: '<',
    currentUser: '<',
  },
  controller: LandingClaim,
  controllerAs: 'LandingClaim',
  templateUrl,
});
