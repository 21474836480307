import app from 'app';

const MEMBER_PORTAL = 'member_portal';
app.factory('AssetService', ($q, Api) => {
  // TODO: refactor since logic is already presented in https://github.com/collectivehealth/frontend-web-member/blob/015da79e4b91c910e12abd0a2e16a8e8703517fb/libraries/mx-machines/src/AuthMachine/AuthMachine.js#L93
  function getSubdomainName() {
    const { hostname } = window.location;
    return hostname.split('.')[0];
  }

  /**
   * Determine if current client is an affiliate
   * @return true if hostname contains client name; otherwise, false.
   * */
  function isAffiliate() {
    return getSubdomainName() !== 'my';
  }

  /***
   * Retrieve assets data for current affiliate.
   * @param {Object} reqParams the parameter objects
   * @return {Promise} represents service request for asset data.
   */
  function retrieveAffiliateAssets(reqParams) {
    if (!reqParams) {
      return;
    }

    const params = Object.assign(
      {},
      {
        tags: [MEMBER_PORTAL],
        ...reqParams,
      },
    );

    return Api.req({
      method: 'GET',
      endPoint: '/asset/assets',
      cache: true,
      params,
    })
      .then((response) => {
        return {
          assets: response.data.filter((assetEntry) => {
            return assetEntry.tags.indexOf(MEMBER_PORTAL) >= 0;
          }),
        };
      })
      .catch((e) => {
        $q.reject(new Error(`${e.status} - ${e.data.errorMessage}`));
      });
  }

  function getAssetByAssetKey(assetKey, params) {
    // quick exit when empty params are submitted.
    if (!params || Object.keys(params).length === 0) {
      return Promise.resolve();
    }

    return retrieveAffiliateAssets(params).then((response) => {
      let assets = response.assets.filter((asset) => {
        return asset.key === assetKey;
      });

      if (assets.length > 1) {
        // we need to default to cchhdefault when there aren't
        // assets being returned but there is a subdomain
        // like we currently have wonderful company

        let find = assets.filter((asset) => {
          return asset.collectionCode === 'cchhdefault';
        })[0];

        return {
          asset: find,
        };
      } else {
        return {
          asset: assets[0],
        };
      }
    });
  }

  return {
    isAffiliate,
    getAssetByAssetKey,
    getSubdomainName,
    retrieveAffiliateAssets,
  };
});
