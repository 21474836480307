import app from 'app';
import _ from 'lodash';

// Pages.
import coverage from 'pages/coverage/coverage';
import planDetail from 'pages/coverage/plans/planDetail';
import benefitDetail from 'pages/coverage/plans/benefits/benefitDetail';

import PLAN_TYPES from 'constants/planTypes';
import ROUTES from './routes.json';
import appendQueryParam from 'helpers/appendQueryParam';
import getRoute from 'helpers/getRoute';

import { logEvent, eventCategories } from 'helpers/analytics';

app.config(($stateProvider) => {
  $stateProvider.state('Coverage', {
    parent: 'Auth',
    url: 'coverage-v1',
    resolve: {
      activePlans(PlanDetails, currentSponsorship) {
        return PlanDetails.getPlanDetails(currentSponsorship.id);
      },
      // medical, dental, and vision
      filteredActivePlans(activePlans) {
        return activePlans.filter(
          (plan) => PLAN_TYPES.ordered.indexOf(plan.type) !== -1,
        );
      },
      subNavLinks(filteredActivePlans) {
        function getPlanTypeLinks() {
          return filteredActivePlans.map((plan) => ({
            name: plan.type,
            sref: `Plan({planType: '${plan.type}'})`,
          }));
        }

        const planTypeLinks = getPlanTypeLinks();
        return planTypeLinks;
      },
    },
    onEnter() {
      if (window.location.pathname === '/coverage-v1') {
        window.location.replace(getRoute(`/${ROUTES.plans.react}`));
      }
      logEvent('plansHomeViewed', {
        category: eventCategories.PLANS,
      });
    },
    views: {
      'content@Base': {
        component: coverage,
      },
    },
  });

  $stateProvider.state('PlanRedirect', {
    parent: 'Auth',
    url: `${ROUTES.plans.angular}/:planType`,
    onEnter($state) {
      // location: replace replaces current entry in navigation history
      return $state.go('Plan', $state.planType, { location: 'replace' });
    },
  });

  // Provides redirect for backwards compatibility to older plans links
  $stateProvider.state('OldPlansRoute', {
    parent: 'Auth',
    url: ROUTES.plans.angular,
    onEnter($state) {
      $state.go('PlansNoType');
    },
  });

  $stateProvider.state('PlansNoType', {
    parent: 'Coverage',
    url: `/${ROUTES.plans.angular}`,
    onEnter($state) {
      // location: replace replaces current entry in navigation history
      return $state.go('Plan', {}, { location: 'replace' });
    },
  });

  $stateProvider.state('Plan', {
    url: `/${ROUTES.plans.angular}/:planType`,
    parent: 'Coverage',
    params: {
      planType: 'medical',
    },
    resolve: {
      plan($stateParams, filteredActivePlans) {
        return _.find(
          filteredActivePlans,
          (plan) => plan.type === $stateParams.planType,
        );
      },
    },
    onEnter($stateParams) {
      let url = getRoute(`/${ROUTES.plans.react}`);
      if ($stateParams?.planType) {
        url = appendQueryParam(url, 'planType', $stateParams.planType);
      }
      window.location.replace(url);
    },
    views: {
      coverageContent: {
        component: planDetail,
      },
    },
  });

  /**
   * Benefit Detail Page
   *
   * Provides a benefit detail header and benefit examples.
   */
  $stateProvider.state('BenefitDetail', {
    url: '/benefits/:benefitKey',
    parent: 'Plan',
    resolve: {
      benefit($state, $stateParams, plan, BenefitService) {
        const { benefitKey } = $stateParams;
        return (
          BenefitService.getBenefitInPlan(plan, benefitKey) ||
          $state.go('Plan', { planType: plan.type })
        );
      },
    },
    views: {
      'content@Base': {
        component: benefitDetail,
      },
    },
  });
});
