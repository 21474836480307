import app from 'app';
import templateUrl from './inboxAvatar.html';
import './inboxAvatar.scss';

function InboxAvatar() {
  const InboxAvatar = this;

  InboxAvatar.getFirstAndLastInitial = () => {
    if (!InboxAvatar.username || typeof InboxAvatar.username !== 'string') {
      return '';
    }

    const names = InboxAvatar.username.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials =
        initials + names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };
}

app.component('inboxAvatar', {
  bindings: {
    iconType: '@',
    username: '@',
  },
  controller: InboxAvatar,
  controllerAs: 'InboxAvatar',
  templateUrl,
});
