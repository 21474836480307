import app from 'app';
import templateUrl from './printCards.html';
import './printCards.scss';

import 'components/cards/memberCard/memberCard';
import 'components/cards/memberCardImg/memberCardImg';

function PrintCards($timeout) {
  const PrintCards = this;

  PrintCards.cardErrors = {};

  PrintCards.setCardError = function (type) {
    $timeout(() => {
      PrintCards.cardErrors[type] = true;
    });
  };
}

app.component('printCards', {
  bindings: {
    cardsForPeriod: '<',
    selectedUser: '<',
  },
  controller: PrintCards,
  controllerAs: 'Card',
  templateUrl,
});

export default 'printCards';
