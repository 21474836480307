import app from 'app';
import _ from 'lodash';
import User from 'models/User';
import SPONSOR_DOCUMENTS from 'constants/sponsorDocuments';
const dolSurpriseBillingModelNotice = '/dol-surprise-billing-model-notice.pdf';

app.service('User', ($q, Api, Session) => {
  const USER_EXPANDS = [
    'address',
    'addresses',
    'mailingAddress',
    'preferredAddress',
    'memberId',
    'settings',
  ];

  const USER_EXPANDS_FOR_LEGAL = [
    'sponsor',
    'sponsorships',
    'privacyPolicy',
    'hipaaNotice',
    'premiumAssistanceNotice',
    'womensHealthNotice',
    'surpriseMedicalBills',
    'summaryOfBenefitsAndCoverage',
    'overAgeDependentForm',
    'url',
  ];

  function getUser() {
    return $q
      .when(Session.getSession())
      .then((session) => session || $q.reject())
      .then((session) =>
        Api.req({
          endPoint: `/person/${session.id}`,
          cache: true,
          params: {
            expand: USER_EXPANDS,
            // only returns 'valid' planMemberships
            'planMemberships.isValid': true,
          },
        }),
      )
      .then((user) => new User(user))
      .catch(() => {
        // If unable to get User and session exists clear out SESSION
        // Something is wrong. Without this Session check gets stuck in loop (CH-22510)
        if (Session.hasSession()) {
          Session.logout();
        }

        return $q.reject(new Error('noUser'));
      });
  }

  function getUserLegalInfo(sponsorshipId) {
    return $q
      .when(Session.getSession())
      .then((session) => session || $q.reject())
      .then((session) =>
        Api.req({
          endPoint: `/person/${session.id}`,
          cache: true,
          params: {
            expand: USER_EXPANDS_FOR_LEGAL,
            // only returns 'valid' planMemberships
            'planMemberships.isValid': true,
          },
        }),
      )
      .then((user) => {
        let sponsor;

        if (sponsorshipId) {
          sponsor = user.sponsorships.find(
            (sponsorship) => (sponsorship.id = sponsorshipId),
          ).sponsor;
        } else {
          sponsor = user.sponsorships[0].sponsor;
        }

        // Currently all healthcare rights documents are tracked on the
        // sponsor model (privacyPolicy, hipaaNotice, premiumAssistanceNotice,
        // womensHealthNotice).  The current design makes them sponsor specific.
        // All of this information is coming from bizcore and the documents
        // are in a S3 bucket.
        //
        // The new healthcare rights document for surpriseMedicalBills is
        // different as it is meant to be shown for all sponsors and members.
        // We will be serving this document from this repo in order to
        // allow us to better track static documents related to
        // member-portal moving forward.
        sponsor['surpriseMedicalBills'] = {
          url: dolSurpriseBillingModelNotice,
        };

        const docs = SPONSOR_DOCUMENTS.filter(
          (doc) => sponsor[doc.expandKey],
        ).map((doc) => {
          doc.url = sponsor[doc.expandKey].url;
          return doc;
        });

        return docs;
      })
      .catch(() => {
        // If unable to get User and session exists clear out SESSION
        // Something is wrong. Without this Session check gets stuck in loop (CH-22510)
        if (Session.hasSession()) {
          Session.logout();
        }
        return $q.reject(new Error('noUser'));
      });
  }

  function getFamilyUnitRegStatus(personId) {
    if (!personId) {
      return $q.reject('Missing personId');
    }

    return Api.req({
      endPoint: `/person/${personId}/family_unit/registration_status`,
      cache: false,
    }).then((regStatuses) => {
      return _.map(regStatuses.data, (registration) => {
        return {
          invited: _.get(registration, 'isInvited', false),
          key: _.get(registration, 'isVerified', false)
            ? 'is_verified'
            : _.get(registration, 'isRegistered', false)
            ? 'is_registered'
            : 'not_registered',
          value: _.get(registration, 'isVerified', false),
          personId: _.get(registration, 'personId'),
        };
      });
    });
  }

  function getFirstUninvitedDependent(personId, coveredMembersOver13) {
    return getFamilyUnitRegStatus(personId).then((registrationStatus) => {
      const registrationMap = registrationStatus.reduce(function (map, obj) {
        map[obj.personId] = obj.invited || obj.key !== 'not_registered';
        return map;
      }, {});

      return coveredMembersOver13.find((member) => {
        return !member.isSubscriber && !registrationMap[member.id];
      });
    });
  }

  function getFamilyDesignees(sponsor, subscriber) {
    if (!_.has(sponsor, 'id')) {
      return $q.reject('Missing sponsor id');
    }

    if (!_.has(subscriber, 'id')) {
      return $q.reject('Missing subscriber id');
    }

    return Api.get('/financial_authorization', {
      sponsorId: sponsor.id,
      subscriberId: subscriber.id,
    });
  }

  function isMemberRegistered(userId, currentUserId) {
    return getFamilyUnitRegStatus(currentUserId).then(
      (registrationStatuses) => {
        const registrationStatus = _.find(registrationStatuses, {
          personId: userId,
        });
        if (
          registrationStatus.key === 'is_verified' &&
          registrationStatus.value
        ) {
          return true;
        }
        return false;
      },
    );
  }

  function inviteDependent(data) {
    if (!data || _.isEmpty(data)) {
      return $q.reject('Missing arguments.');
    }

    return Api.post(`/person/${data.id}/invite`, {
      email: data.email,
    });
  }

  function grantDesigneeStatus(subscriber, sponsor, personId) {
    return Api.post('/financial_authorization', {
      authorizedDependentId: personId,
      subscriberId: subscriber.id,
      sponsorId: sponsor.id,
    });
  }

  function revokeDesigneeStatus(authorizationId) {
    if (!authorizationId) {
      return $q.reject('Authorization not found');
    }

    return Api.delete(`/financial_authorization/${authorizationId}`);
  }

  return {
    getUser,
    getUserLegalInfo,
    getFamilyUnitRegStatus,
    getFamilyDesignees,
    isMemberRegistered,
    getFirstUninvitedDependent,
    inviteDependent,
    grantDesigneeStatus,
    revokeDesigneeStatus,
  };
});
