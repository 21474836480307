import app from 'app';
import Sponsorships from 'models/Sponsorships';
import moment from 'moment';

app.factory('Sponsor', (ApiMCM, Api, Logger) => {
  let currentSponsorship, sponsorshipsStore;
  // Returns all of users sponsors
  // these sponsors are sorted in order of sponsors importance to the user
  function getSponsorships() {
    return ApiMCM.get('sponsorships_list', {})
      .then((response) => {
        sponsorshipsStore = new Sponsorships(response.sponsorships);
        return sponsorshipsStore;
      })
      .catch((error) => {
        //UI-router issue calling resolves in auth on base route
        if (error.response.status !== 401) {
          Logger.captureApiError('sponsorships_list', {}, error.response.data);
        }
        return [];
      });
  }

  function setCurrentSponsorship(sponsorships, sponsorshipId) {
    for (let i = 0; i < sponsorships.sponsorships.length; i++) {
      if (sponsorships.sponsorships[i].id === sponsorshipId) {
        currentSponsorship = sponsorships.sponsorships[i];
        // save to session storage for use by affiliates service on legal pages
        window.sessionStorage.setItem(
          'affiliatesSponsorId',
          currentSponsorship?.sponsor?.id
        );
      }
    }
  }

  function getSponsorshipPeriod(id) {
    return ApiMCM.get('sponsorship_periods', {})
      .then((response) => {
        const curYear = moment().year();
        return response.sponsorshipPeriods.find((period) => {
          const periodStartYear = moment(period.startDate).year();
          const periodEndYear = moment(period.endDate).year();

          return (
            period.sponsorship.id === id &&
            curYear >= periodStartYear &&
            curYear <= periodEndYear
          );
        });
      })
      .catch((error) => {
        Logger.captureApiError('sponsorship_periods', {}, error.response.data);
        return null;
      });
  }

  // TODO refactor once ZenDesk is fully live
  function getSponsorZendeskSettings(sponsorIds) {
    if (!sponsorIds) {
      return getSponsorships().then((allSponsorships) => {
        const sponsorIds = allSponsorships.sponsorships.map(
          (sponsorship) => sponsorship.sponsor.id
        );
        return shouldShowInbox(sponsorIds);
      });
    } else {
      return shouldShowInbox(sponsorIds);
    }
  }

  function shouldShowInbox(sponsorIds) {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: `/sponsor_setting/settings`,
      params: {
        settingName: 'ZENDESK_ENABLED',
        sponsorIds,
      },
    })
      .then((response) => {
        return response.filter((sponserSetting) => sponserSetting.isEnabled)
          .length > 0
          ? true
          : false;
      })
      .catch((err) => {
        Logger.captureApiError('/sponsor_setting/settings', {}, err.data);
        return false;
      });
  }

  function getCurrentSponsorship() {
    return currentSponsorship;
  }

  return {
    getSponsorships,
    setCurrentSponsorship,
    getCurrentSponsorship,
    getSponsorZendeskSettings,
    getSponsorshipPeriod,
  };
});
