import _ from 'lodash';
import moment from 'moment';

const SSN_REGEX = /^[0-9]{9}$/;

export function parseSSN(rawSSN) {
  var SSN = rawSSN.replace(/-|\s/g, '');
  if (SSN_REGEX.test(SSN)) {
    return `${SSN.substr(0, 3)}-${SSN.substr(3, 2)}-${SSN.substr(5)}`;
  }
  return;
}

// Format Date from MMDDYYYY to YYYY-MM-DD
export function parseDate(date, inputFormat) {
  return moment(date, inputFormat, !!inputFormat).format('YYYY-MM-DD');
}

// Format name to First_Name Middle_Name Last_Name Suffix, Degrees
export function parseName(rawData) {
  var name = '';

  name += rawData.firstName ? rawData.firstName : '';
  name += rawData.middleName ? ' ' + rawData.middleName : '';
  name += rawData.lastName ? ' ' + rawData.lastName : '';
  name += rawData.suffix ? ' ' + rawData.suffix : '';
  name += !_.isEmpty(rawData.degrees) ? ', ' + rawData.degrees.join(', ') : '';

  return name.trim();
}

/**
 * Formats a U.S. address.
 *
 * @example
 * {
 *   streetAddressLine1: '60 E 3rd Ave',
 *   streetAddressLine2: 'Suite 300',
 *   city: 'San Mateo',
 *   state: 'CA',
 *   zip: '94403',
 *   ...
 * } =>
 * '60 E 3rd Ave\nSuite 300\nSan Mateo, CA 94403'
 * '60 E 3rd Ave, Suite 300, San Mateo' (short)
 *
 * @param  {object}  address - Address model.
 * @param  {boolean} shortFormat   - Use terse format.
 * @param  {string} lineSeparator   - string to use between address lines
 * @return {string}  - Formatted address.
 */
export function parseAddress(address, shortFormat, lineSeparator) {
  lineSeparator = lineSeparator || '<br>';
  if (shortFormat) {
    return _.compact([
      address.streetAddressLine1,
      address.streetAddressLine2,
      address.city,
    ]).join(', ');
  } else {
    const stateZip = _.compact([
      address.state || address.stateCode,
      address.zip || address.postalCode,
    ]).join(' ');
    const cityStateZip = _.compact([address.city, stateZip]).join(', ');
    return _.compact([
      address.streetAddressLine1,
      address.streetAddressLine2,
      cityStateZip,
    ]).join(lineSeparator);
  }
}

// Format phone number to XXX-XXX-XXXX
export function parsePhone(rawPhone) {
  if (_.size(rawPhone) === 10) {
    return `${rawPhone.substr(0, 3)}-${rawPhone.substr(3, 3)}-${rawPhone.substr(
      6,
      4
    )}`;
  }
  return rawPhone;
}

/**
 * Calm that ish down, yo.
 * Warning: _.startCase strips all punctuations ex: children's hospital -> Childrens Hospital
 *
 * @param  {string} str
 * @return {string}
 */
export function startCase(str) {
  return _.startCase(String(str).toLowerCase());
}

/**
 * Capitalize each word in a string. Return empty string if input is not a type of string
 *
 * @param  {string} str
 * @return {string}
 */
export function capitalizeWords(str) {
  if (typeof str !== 'string') return '';
  return str.split(' ').map(_.upperFirst).join(' ');
}

/**
 * Checks if a string is "true" or "false". That is, if it is a booleans being passed as a string.
 * Then return the propper boolean value.
 * @param  {[string, boolean]} booleanString The string "true" or "false" or a boolean value
 * @return {boolean}                         Boolean value
 */
export function parseBoolean(booleanString) {
  if (typeof booleanString === 'boolean') {
    return booleanString;
  }
  return booleanString.toLowerCase() === 'true';
}

export default {
  capitalizeWords,
  parseSSN,
  parseDate,
  parseName,
  parseAddress,
  parsePhone,
  startCase,
  parseBoolean,
};
