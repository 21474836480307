import defaultFFs from './config/defaults.json';

/**
 * Different context kinds.
 */
export const LAUNCHDARKLY_CONTEXT_KINDS = {
  user: 'user',
};

/**
 * Different context keys for different contexts.
 */
export const LAUNCHDARKLY_CONTEXT_KEYS = {
  memberPortal: 'member-portal',
  employerPortal: 'employer-portal',
};

/**
 * Different client-side IDs for different environments.
 */
export const LAUNCHDARKLY_SDK_CLIENT_SIDE_IDS = {
  'crm-training': '63bc802c71e5da12c6a4fabc', // same as demo
  demo: '63bc802c71e5da12c6a4fabc',
  dev: '63ffbb37f61c32137928a439',
  perf: '649eed6224aa84133676dd62',
  prod: '6364220d7d401b11600647b8',
  staging: '6388ff22413bac11a3667b16',
  testing: '6364220d7d401b11600647b7',
  'eligibility-pit': '64b5ab4be17847142e9167ad',
  'eligibility-dev-pit': '64b5ab423f308d133448f202',
};

/**
 *
 * Get the default value for a feature flag, if it exists. If not, return false.
 *
 * @param {string} featureFlag - The feature flag to get the default value for.
 * @returns {boolean} - The default value for the feature flag.
 */
export function getFeatureFlagDefaultValue(featureFlag) {
  if (defaultFFs[featureFlag]) {
    return defaultFFs[featureFlag];
  }

  return false;
}

/**
 *
 * Get the value of a feature flag from the LaunchDarkly client. If the client
 * is not available, return the default value.
 *
 * @param {launchDarklyClient} client
 * @param {string} featureFlag
 * @returns {boolean} - The value of the feature flag.
 */
export const getFFVariation = (client, featureFlag) => {
  const defaultValue = getFeatureFlagDefaultValue(featureFlag);
  if (client && client.variation) {
    return client.variation(featureFlag, defaultValue);
  }

  return defaultValue;
};
