import app from 'app';
import templateUrl from './landingCob.html';
import './landingCob.scss';

import _ from 'lodash';
import getCommaDelimitedList from 'helpers/commaDelimitedList';

function LandingCob(CobService) {
  const LandingCob = this;

  LandingCob.$onInit = () => {
    const uniqueActiveSponsorNames = _.uniq(
      LandingCob.allSponsorships.sponsorships.map((s) => s.sponsor.name)
    );

    LandingCob.activeSponsorNames = getCommaDelimitedList(
      uniqueActiveSponsorNames
    ).join('');
    LandingCob.showCobAction = LandingCob.cob.showCobQuestion;
  };

  LandingCob.respondNo = function () {
    LandingCob.onNoClick();
    return CobService.updateCob(LandingCob.user, 'No').then((response) => {
      LandingCob.cob.updateMemberCob(response);
    });
  };
}

app.component('landingCob', {
  bindings: {
    allSponsorships: '<',
    cob: '<',
    onNoClick: '<',
    user: '<',
  },
  controller: LandingCob,
  controllerAs: 'LandingCob',
  templateUrl,
});
