import app from 'app';
import templateUrl from './planDetail.html';
import './planDetail.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

// Sub-components.
import './balanceBilling/balanceBilling';
import './benefitOverviewDental/benefitOverviewDental';
import './benefitOverviewMedical/benefitOverviewMedical';
import './benefitOverviewVision/benefitOverviewVision';
import './header/header';

function PlanDetail(Cards) {
  const PlanDetail = this;

  function getDocumentType(planType) {
    if (planType === 'medical') {
      return 'SBC';
    } else {
      return `${planType.toLowerCase()}PlanSummary`;
    }
  }

  /**
   * Returns the correct link title to use for the SBC document based on what
   * type of plan the user is viewing.
   *
   * @return {string} - Link title copy.
   */
  PlanDetail.getSbcLinkTitle = () => {
    switch (PlanDetail.plan.type) {
      case 'medical':
        return 'Summary of Benefits & Coverage';
      default:
        return `${PlanDetail.plan.type} Plan Summary`;
    }
  };

  PlanDetail.clickSbcLink = () => {
    logEvent('planDocumentViewed', {
      category: eventCategories.PLANS,
      documentType: getDocumentType(PlanDetail.plan.type)
    });
  };

  PlanDetail.clickRightsLink = () => {
    logEvent('planDocumentViewed', {
      category: eventCategories.PLANS,
      documentType: 'healthcareRights'
    });
  };

  PlanDetail.showSBC = () => {
    return (
      PlanDetail.plan.summaryOfBenefitsAndCoverage &&
      !PlanDetail.plan.planDescription
    );
  };

  PlanDetail.viewCards = () => {
    return Cards.openModal({
      currentUser: PlanDetail.currentUser,
      currentSponsorship: PlanDetail.currentSponsorship
    });
  };
}

app.component('planDetail', {
  bindings: {
    isSubscriber: '<',
    plan: '<',
    currentUser: '<',
    currentSponsorship: '<'
  },
  controller: PlanDetail,
  controllerAs: 'PlanDetail',
  templateUrl
});

export default 'planDetail';
