import app from 'app';
import templateUrl from './feedback.html';
import './feedback.scss';

app.component('feedback', {
  controllerAs: 'Feedback',
  templateUrl,
});

export default 'feedback';
