import app from 'app';
import templateUrl from './faqRecommendation.html';
import './faqRecommendation.scss';

import LEGAL from 'constants/legal';

function FaqRecommendation(FrontendLegal, $state) {
  const FaqRecommendation = this;

  FaqRecommendation.$onInit = () => {
    const legalConfig = FrontendLegal.configurations[LEGAL.CONFIG_NAME];
    FaqRecommendation.privacyStateName = legalConfig.privacy.stateName;
  };

  FaqRecommendation.clickPrivacyLink = () => {
    FaqRecommendation.$close();
    $state.go(FaqRecommendation.privacyStateName);
  };
}

app.component('faqRecommendation', {
  bindings: {
    $close: '<',
  },
  controller: FaqRecommendation,
  controllerAs: 'FaqRecommendation',
  templateUrl,
});

export default 'faqRecommendation';
