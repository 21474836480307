import app from 'app';
import templateUrl from './partnerModal.html';
import './partnerModal.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

function getActionUrl(action) {
  if (action.type === 'url') {
    return action.value;
  }

  return 'Null';
}

function getAmplitudePage(name) {
  const pageMapping = {
    benefitdetail: 'planBenefit',
    getcare: 'partnerPlan',
    landing: 'home'
  };

  const key = name.toLowerCase();
  if (pageMapping.hasOwnProperty(key)) {
    return pageMapping[key];
  }
  return '';
}

function PartnerModal($state) {
  const PartnerModal = this;

  function closeIfStateAction(action) {
    if (action.type === 'state') {
      PartnerModal.$close();
    }
  }

  PartnerModal.actionClick = function(action) {
    logEvent('memberReferred', {
      category: eventCategories.PARTNERS,
      sponsorCode: PartnerModal.currentSponsorship.sponsor.sponsorCode,
      partnerID: PartnerModal.partner.partnerId,
      partnerName: PartnerModal.partner.summary.title,
      referralUrl: getActionUrl(action),
      buttonText: action.title,
      page: getAmplitudePage($state.$current.name)
    });

    closeIfStateAction(action);
  };
}

app.component('partnerModal', {
  bindings: {
    $close: '<',
    partner: '<',
    currentUser: '<',
    currentSponsorship: '<'
  },
  controller: PartnerModal,
  controllerAs: 'PartnerModal',
  templateUrl
});

export default 'partnerModal';
