import { Analytics, Posing } from '@collectivehealth/cog';
import { isProd } from 'helpers/checkEnv';

const TEST_KEYS = {
  POSING: '9de0270aae959b3decbe4aaf000acbed',
  REGULAR: '77eb4fddc8b626c311ea9c7701ae4f05',
};

const PROD_KEYS = {
  POSING: '88f0832ae57077f1cdf07ea06d62ad3a',
  REGULAR: '7be23f82fb35211e130aa59c028f5482',
};

const getAmplitudeKeys = () => {
  const keys = isProd() ? PROD_KEYS : TEST_KEYS;
  return {
    POSING: keys.POSING,
    REGULAR: keys.REGULAR,
  };
};

export const eventCategories = {
  BLUE_DISTINCTION: 'blueDistinction',
  CARDS: 'cards',
  CARE: 'getCare',
  CHAT: 'chat',
  CLAIMS: 'claims',
  COST: 'costTransparency',
  DOCUMENTS: 'documents',
  FOOTER: 'footer',
  FUNDS: 'funds',
  HELP: 'helpCenter',
  HOME: 'home',
  HOW_WE_HELP: 'howWeHelp',
  INBOX: 'inbox',
  LOGIN: 'login',
  MSOON: 'msoon',
  NAV: 'nav',
  NEW_USER_REGISTRATION: 'newUserRegistration',
  PARTNERS: 'partners',
  PEOPLE: 'people',
  PERSONALIZATION: 'personalization',
  PLANS: 'plans',
  PROOF_OF_COVERAGE: 'proofOfCoverage',
  REGISTRATION: 'registration',
  SESSION: 'session',
  SETTINGS: 'settings',
};

export const eventNames = {
  CHAT_LAUNCHED: 'Chat Launched',
  CONTACT_BUTTON_CLICKED: 'contactButtonClicked',
};

function getPosingKey(isPosing) {
  return isPosing ? 'POSING' : 'REGULAR';
}

export async function initializeAmplitude() {
  const isPosing = Posing.isPosing();
  const amplitudeKeys = getAmplitudeKeys();
  const apiKey = amplitudeKeys[getPosingKey(isPosing)];
  await Analytics.install({ apiKey });
}

export function logEvent(eventName, properties) {
  Analytics.logEvent(eventName, properties);
}

export function setAmplitudeUser(userId, props) {
  Analytics.setUserId(userId);

  if (props) {
    Analytics.setUserProperties(props);

    logEvent('setUserProperties', {
      category: eventCategories.SESSION,
    });
  }
}
export function unsetAmplitudeUser() {
  setAmplitudeUser(undefined);
}

const pathNameToPageMapping = {
  '/coverage/plans/.*$': 'plans',
};

/**
 * Retrieves amplitude page key for current URL
 */
export const getPage = () => {
  const pathName = window?.location?.pathname;
  const foundKey = Object.keys(pathNameToPageMapping).find((key) =>
    pathName.match(new RegExp(key)),
  );

  let page = '';
  if (foundKey) {
    page = pathNameToPageMapping[foundKey];
  } else if (pathName) {
    page = pathName.substring(1);
  }
  return page;
};
