import app from 'app';
import templateUrl from './phiAuthBadge.html';

app.component('phiAuthBadge', {
  bindings: {
    level: '<',
  },
  controllerAs: 'phiAuthBadge',
  templateUrl: templateUrl,
});
