import app from 'app';
import templateUrl from './registration.html';
import './registration.scss';

app.component('registration', {
  bindings: {
    activeAlerts: '<',
    supportPhone: '<',
  },
  controllerAs: 'Registration',
  templateUrl,
});

export default 'registration';
