import app from 'app';
import templateUrl from './partnerDisclaimers.html';
import './partnerDisclaimers.scss';

function PartnerDisclaimersCtrl() {
  const PartnerDisclaimers = this;

  PartnerDisclaimers.$onInit = () => {
    const { title, subTitle, body } = PartnerDisclaimers.partnerDisclaimers;
    PartnerDisclaimers.title = title;
    PartnerDisclaimers.subTitle = subTitle;
    PartnerDisclaimers.body = body;
  };
}

app.component('partnerDisclaimers', {
  bindings: {
    partnerDisclaimers: '<',
  },
  controller: PartnerDisclaimersCtrl,
  controllerAs: 'PartnerDisclaimers',
  templateUrl,
});

export default 'partnerDisclaimers';
