import app from 'app';
import templateUrl from './unsubscribeTokenExpired.html';
import './feedback.scss';

app.component('unsubscribeTokenExpired', {
  controllerAs: 'UnsubscribeTokenExpired',
  templateUrl,
});

export default 'unsubscribeTokenExpired';
