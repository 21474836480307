export const ordered = ['medical', 'dental', 'vision'];

export const orderedWithPharmacy = ['medical', 'pharmacy', 'dental', 'vision'];

export const PLAN_TYPES = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
};

export const planDetailsAccumulatorKeys = {
  TYPE: 'type',
  NETWORK_TYPE: 'networkType',
  FAMILY_TIER: 'familyTier',
}

export const accumulatorTypes = {
  DEDUCTIBLE: 'DEDUCTIBLE',
  OUT_OF_POCKET_MAX: 'OUT_OF_POCKET_MAX',
  MAX_REIMBURSEMENT: 'MAX_REIMBURSEMENT',
}

export default {
  medical: 'Medical',
  pharmacy: 'Pharmacy',
  dental: 'Dental',
  vision: 'Vision',
  ordered,
  orderedWithPharmacy,
};
