function endsWithS(text) {
  if (text) {
    return text.match(/s$/i);
  }
}

export default function possessiveNoun(text) {
  if (text) {
    if (endsWithS(text)) {
      return `${text}'`;
    }

    return `${text}'s`;
  }
}
