import app from 'app';
import templateUrl from './cardRequest.html';
import './cardRequest.scss';
import _ from 'lodash';
import 'components/forms/radioGroup/radioGroup';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-phone';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-envelope';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-clock';
import 'components/supportMessageLink/supportMessageLink';

import { parseAddress, parseName } from 'helpers/stringParsers';
import { logEvent, eventCategories } from 'helpers/analytics';
import { COLLECTIVE_TITLE } from '../../../collectiveTitle';

import appStoreUrl from '../appstore-button.png';
import playStoreUrl from '../playstore-button.png';

import downloadAppTemplate from './_downloadApp.html';
import printCardsTemplate from './_printCards.html';
import contactInfoTemplate from './_cardRequestContactInfo.html';

import changeAddress from './states/changeAddress.html';
import reason from './states/reason.html';
import sent from './states/sent.html';
import updateInformation from './states/updateInformation.html';
import verifyAddress from './states/verifyAddress.html';
import whoNeedsCards from './states/whoNeedsCards.html';

import { ordered } from 'constants/planTypes';

function CardRequest($q, $state, Cards) {
  const CardRequest = this;
  CardRequest.COLLECTIVE_TITLE = COLLECTIVE_TITLE;

  CardRequest.downloadAppTemplate = downloadAppTemplate;
  CardRequest.printCardsTemplate = printCardsTemplate;
  CardRequest.contactInfoTemplate = contactInfoTemplate;

  CardRequest.appStoreUrl = appStoreUrl;
  CardRequest.playStoreUrl = playStoreUrl;

  CardRequest.membersWhoNeedCards = {};
  CardRequest.stateHistory = [];
  CardRequest.waitingOnRequest = false;

  // one request per member who needs cards
  function createCardRequests() {
    return _.chain(CardRequest.membersWhoNeedCards)
      .map(function(isSelected, memberId) {
        if (isSelected) {
          const member = _.find(CardRequest.peopleWhoCanRecieveCards, {
            id: parseInt(memberId, 10)
          });

          if (!member) return null;
          return Cards.requestCard({
            memberId: member.id,
            subscriberId: CardRequest.currentSponsorship.subscriber.id,
            sponsorId: CardRequest.currentSponsorship.sponsor.id,
            planMembershipIds: member.planMembershipIds
          });
        }
      })
      .filter()
      .value();
  }

  function getPeopleWhoCanRecieveCards() {
    return Cards.getCards(CardRequest.currentSponsorship.id).then(data => {
      return data.currentCardsMembers.map(buildCardRequestInfo);
    });
  }

  function buildCardRequestInfo(person) {
    const planMembershipIds = [];
    const planTypes = [];

    person.cards.forEach(card => {
      const cardType = card.type.toLowerCase();
      // medical, dental, vision
      if (ordered.indexOf(cardType) !== -1) {
        planMembershipIds.push(card.planMembershipId);
        planTypes.push(_.capitalize(cardType));
      }
    });

    return {
      id: person.id,
      name: parseName(person),
      planMembershipIds,
      plansText: planTypes.join(', ')
    };
  }

  function getShippingAddress() {
    return parseAddress(CardRequest.currentUser.preferredAddress);
  }

  function goToNextState(recentState) {
    CardRequest.stateHistory.push(recentState);
    CardRequest.state =
      CardRequest.stateHistory[CardRequest.stateHistory.length - 1];
  }

  function goToPreviousState() {
    CardRequest.stateHistory.pop();
    CardRequest.state =
      CardRequest.stateHistory[CardRequest.stateHistory.length - 1];
  }

  CardRequest.submitReason = function() {
    logEvent('cardNewRequestStepCompleted', {
      category: eventCategories.CARDS,
      stepName: 'why are you requesting new cards?'
    });

    if (!CardRequest.reason) {
      return;
    }

    if (CardRequest.reason === 'incorrectInformation') {
      goToNextState('updateInformation');
    } else if (!CardRequest.dependents.length) {
      goToNextState('verifyAddress');
    } else {
      goToNextState('whoNeedsCards');
    }
  };

  CardRequest.getTemplateUrl = function() {
    switch (CardRequest.state) {
      case 'changeAddress':
        CardRequest.title = 'Next steps';
        return changeAddress;
      case 'sent':
        CardRequest.title = 'Your new cards are on the way!';
        return sent;
      case 'updateInformation':
        CardRequest.title = 'Next steps';
        return updateInformation;
      case 'verifyAddress':
        CardRequest.title =
          'Let’s make sure these new cards go to the right place';
        return verifyAddress;
      case 'whoNeedsCards':
        CardRequest.title = 'Whose cards do you need?';
        return whoNeedsCards;
      case 'reason':
      default:
        CardRequest.title = 'Tell us about why you need new cards:';
        return reason;
    }
  };

  CardRequest.verifyAddress = function() {
    logEvent('cardNewRequestStepCompleted', {
      category: eventCategories.CARDS,
      stepName: 'whose cards do you need?'
    });
    goToNextState('verifyAddress');
  };

  CardRequest.changeAddress = function() {
    goToNextState('changeAddress');
  };

  CardRequest.requestCards = function() {
    logEvent('cardNewRequestCompleted', {
      category: eventCategories.CARDS
    });
    CardRequest.waitingOnRequest = true;
    return $q
      .all(createCardRequests())
      .then(() => {
        CardRequest.waitingOnRequest = false;
        goToNextState('sent');
      })
      .catch(error => (CardRequest.error = error));
  };

  CardRequest.showCards = function() {
    logEvent('cardViewed', {
      planType: CardRequest.planTypes,
      category: eventCategories.CARDS,
      page: 'requestcards'
    });
    CardRequest.$close();
    Cards.openModal({
      currentUser: CardRequest.currentUser,
      currentSponsorship: CardRequest.currentSponsorship
    });
  };

  CardRequest.didSelectFamilyMember = function() {
    return _.filter(CardRequest.membersWhoNeedCards).length > 0;
  };

  CardRequest.close = () => {
    logEvent('cardNewRequestCancelled', {
      category: eventCategories.CARDS
    });
    CardRequest.$close();
  };

  CardRequest.$onInit = function() {
    CardRequest.getShippingAddress = getShippingAddress;
    CardRequest.goToPreviousState = goToPreviousState;

    CardRequest.hasKaiser =
      _.filter(CardRequest.planMemberships.current, [
        'plan.networkName',
        'Kaiser'
      ]).length > 0;

    CardRequest.reasons = [
      {
        value: 'lostOrStolen',
        label: 'My cards were lost or stolen'
      },
      {
        value: 'incorrectInformation',
        label: 'The cards I received contain incorrect information'
      },
      {
        value: 'neverReceived',
        label: 'I never received my cards'
      }
    ];

    CardRequest.planTypes = CardRequest.currentSponsorship.activePlanTypes;
    CardRequest.dependents = Cards.getDependents(
      CardRequest.currentUser,
      CardRequest.currentSponsorship,
      CardRequest.planMemberships
    );

    getPeopleWhoCanRecieveCards().then(people => {
      CardRequest.peopleWhoCanRecieveCards = people;
      if (CardRequest.peopleWhoCanRecieveCards.length === 1) {
        const onlyMember = CardRequest.peopleWhoCanRecieveCards[0];
        CardRequest.membersWhoNeedCards[onlyMember.id] = true;
      }
    });

    goToNextState('reason');
  };

  CardRequest.goToInboxCompose = function() {
    $state.go('InboxCompose');
    CardRequest.$close();
  };

  CardRequest.contactUs = () => {
    CardRequest.$close();
  };
}

app.component('cardRequest', {
  bindings: {
    $close: '<',
    currentSponsorship: '<',
    planMemberships: '<',
    /**
     * We really should refactor this modal to just use these new models, but at the
     * 11th hour it's not worth risking the refactor. In addition, this whole modal
     * should be getting an overhaul early 2019, which means this should get done then.
     */
    currentUser: '<' // Uses UserV2 model and is needed for back button
  },
  controller: CardRequest,
  controllerAs: 'CardRequest',
  templateUrl
});

export default 'cardRequest';
