import app from 'app';

app.directive('chFeatureFlag', ($animate, FeatureFlagService) => {
  return {
    restrict: 'A',
    transclude: 'element',
    priority: 700,
    bindToController: {
      flag: '@chFeatureFlag',
    },
    controller($element, $scope, $transclude) {
      const Feature = this;

      Feature.$postLink = function () {
        if (!Feature.flag) {
          throw new Error('ch-feature-flag requires a value.');
        }

        FeatureFlagService.getFeatureFlagStatus(Feature.flag).then(
          (enabled) => {
            if (enabled) {
              $transclude($scope, (clone) => {
                $element.after(clone);
              });
            }
          }
        );
      };
    },
  };
});
