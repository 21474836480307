// Prevents collisions if more than one alert is active and assigned to display on a given page.
// The higher priority feature will display.
// Update this list when new features are added.
export const ALERT_FEATURE_PRIORITY = [
  'phone',
  'chat',
  'cards',
  'doctor-finder',
  'cost-transparency',
  'msoon',
];

export const DEFAULT_PAGE = 'landing';

export default {
  ALERT_FEATURE_PRIORITY,
  DEFAULT_PAGE,
};
