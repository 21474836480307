import app from 'app';

/**
 * Methods needed by React Chat app to interact with Angular parent app
 */
app.factory('Chat', ($state, $rootScope, $window, Api, User, jwtHelper) => {
  function setIsChatOpen(isChatOpen) {
    $window.sessionStorage.setItem('lastChatStatus', isChatOpen);
    return $state.go(
      '.',
      { isChatOpen },
      {
        custom: {
          noReload: true, // Custom transition option for Get Care
        },
      }
    );
  }

  function openChat() {
    return setIsChatOpen(true);
  }

  function closeChat() {
    return setIsChatOpen(false);
  }

  function initiateChat() {
    User.getUser().then((user) => {
      const sessionData = JSON.parse(
        $window.sessionStorage.getItem('chatInstance')
      );
      if (!sessionData || jwtHelper.isTokenExpired(sessionData.token)) {
        Api.req({
          apiPrefix: '/api/v1',
          endPoint: '/membercoremiddleware/chat/initiate',
          method: 'GET',
          headers: {
            'ch-user-identifier': user.userIdentifier,
          },
        }).then((data) => {
          $window.sessionStorage.setItem('chatInstance', JSON.stringify(data));
        });
      }
    });
  }

  return {
    openChat,
    closeChat,
    initiateChat,
  };
});
