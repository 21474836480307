import app from 'app';
import _ from 'lodash';
import templateUrl from './benefitDetail.html';
import './benefitDetail.scss';

// Sub-components.
import './benefitDetailExample/benefitDetailExample';
import './premiumPerk/premiumPerk';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-premium-perks';

import { getLgIconForKey } from 'helpers/plans';

function BenefitDetail(chModal, PartnersService) {
  const BenefitDetail = this;
  BenefitDetail.filteredPartners = [];

  BenefitDetail.$onInit = () => {
    BenefitDetail.hasOonBenefitDetailExamples = _.some(
      BenefitDetail.benefit.details.examples,
      {
        supportsOon: true,
      }
    );

    BenefitDetail.filteredPartners = PartnersService.getValidBenefitPartners(
      BenefitDetail.benefit.partners,
      BenefitDetail.plan.details.partners
    );

    BenefitDetail.iconKey = getLgIconForKey(BenefitDetail.benefit.key);
  };

  BenefitDetail.showBalanceBillingModal = () => {
    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: 'balanceBilling',
      locals: {
        pageSource: 'benefitPage',
      },
    });
  };
}

app.component('benefitDetail', {
  bindings: {
    currentUser: '<',
    currentSponsorship: '<',
    benefit: '<',
    plan: '<',
    plansOfType: '<',
    planIndex: '<',
    activeMemberships: '<',
  },
  controller: BenefitDetail,
  controllerAs: 'BenefitDetail',
  templateUrl,
});

export default 'benefitDetail';
