import app from 'app';

// Pages.
import sso from 'pages/sso/sso';
import ssoError from 'pages/sso/ssoError';

app.config(($stateProvider) => {
  $stateProvider.state('SSO', {
    // This state functions like an abstract state but it does need to be hit like a real state for the time being
    // It pretty much just redirects you to either, Register, Login or an error page. Nothing is really inherited
    // from the SSO state (e.g. SSORegister inherits from Registration). SSO state is a traffic cop and for the
    // time being i think it is fine to encapulate that logic in one place instead of muddling some existing
    // controller with the logic.
    // Terms of service acceptance: When the login token returns a token with no role for a user that is registered, we redirect them to a /accept page
    url: 'sso?login&err',
    parent: 'Base',
    onEnter: ($stateParams, SSOService) => {
      SSOService.routeSSOEntrance($stateParams.err, $stateParams.login);
    },
    views: {
      'content@Base': {
        component: sso,
      },
    },
  });

  $stateProvider.state('SSOError', {
    url: 'ssoError?err',
    parent: 'Base',
    views: {
      'content@Base': {
        component: ssoError,
      },
    },
  });
});
