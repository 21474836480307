export const NETWORKS = {
  IN_NETWORK: 'IN_NETWORK',
  IN_OR_OUT_OF_NETWORK: 'IN_OR_OUT_OF_NETWORK',
  OUT_OF_NETWORK: 'OUT_OF_NETWORK',
};

export const LABELS = {
  [NETWORKS.IN_NETWORK]: 'In-network',
  [NETWORKS.IN_OR_OUT_OF_NETWORK]: 'In- or Out-of-network',
  [NETWORKS.OUT_OF_NETWORK]: 'Out-of-network',
};

export const ACCUMULATOR_TYPES = {
  DEDUCTIBLE: 'Deductible',
  OUT_OF_POCKET_MAX: 'Out-of-pocket maximum',
};
