import app from 'app';
import templateUrl from './notFound.html';
import './notFound.scss';

import supportMessage from 'components/supportMessage/supportMessage';

function NotFoundCtrl($element, chModal) {
  const NotFound = this;

  NotFound.supportMessage = function () {
    chModal.open({
      component: supportMessage,
    });
  };

  NotFound.$onInit = () => {
    $element.addClass('PageNotFound__host');
  };
}

app.component('notFound', {
  controller: NotFoundCtrl,
  controllerAs: 'NotFound',
  templateUrl,
});

export default 'notFound';
