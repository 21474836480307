import app from 'app';
import templateUrl from './landingRecommendations.html';
import './landingRecommendations.scss';

import './recommendationCard/recommendationCard';
import './faqRecommendation/faqRecommendation';
import { logEvent, eventCategories } from 'helpers/analytics';

function LandingRecommendations(Recommendations, chModal) {
  const LandingRecommendations = this;

  LandingRecommendations.$onInit = function() {
    Recommendations.selectedRecommendation = null;
  };

  LandingRecommendations.toogleRecommendation = function(rec) {
    if (rec === LandingRecommendations.selectedRecommendation) {
      LandingRecommendations.selectedRecommendation = null;
    } else {
      LandingRecommendations.selectedRecommendation = rec;
      logEvent('openRecommendation', rec.tracker);
    }
  };

  LandingRecommendations.openFaqModal = function() {
    logEvent('openRecommendationDisclaimer', {
      category: eventCategories.PERSONALIZATION
    });

    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: 'faqRecommendation'
    });
  };
}

app.component('landingRecommendations', {
  bindings: {
    recommendations: '<',
    onDismissRecommendation: '<'
  },
  controller: LandingRecommendations,
  controllerAs: 'LandingRecommendations',
  templateUrl
});
