import app from 'app';

app.factory('ServerErrorCodes', (Raven) => {
  const SERVER_ERROR_CODES = {
    // Custom front end specific codes
    17: 'Our servers hit a snag trying to send your verification email. Please try again.',

    18: `Looks like your verification link has expired.
      Get a new link by clicking here, and try again!
      <a href="/resend_verification">Resend email</a><p/>`,

    19: `Looks like an account with this email address already exists.
      Please check to see if you’re using the right email. If you hit a snag, give us a call.`,

    80: `Almost there... You should receive an email from us shortly with a link to verify your email.
      If you don't see it in the next few minutes (and it's not in your spam folder) send us a
      message or email us at help@collectivehealth.com.`,

    81: 'Good to go! Your account has been successfully verified.',

    82: 'Good to go! Your account has been activated.',

    86: 'Success! Your password has been changed.',

    87: 'Success! Your email has been changed.',

    110: 'To verify your new email, please sign in using your old information.',

    111: `We don’t recognize this verification link. Please try again, or go to the settings page to
      request another link.`,

    901: 'Check your information to see what needs attention.',

    // HTTP status code
    200: 'Success!',

    401: 'Your session has expired, please sign in to continue.',

    500: `Well, this is embarrassing... We ran into a technical problem. Please refresh the page,
      and <a href="mailto:help@collectivehealth.com">send us a note</a> if there's still a problem.`,

    // JSON server errorNumber
    106: 'Invalid email or password. Please try again.',

    107: `Looks like you need to verify your account to do this. You should have received an
      email from us with a link, or click <a href="/resend_verification">here</a> to resend it.`,

    900: `Check your information to see what needs attention. Having trouble registering?
      Give us a call and we'll help you out.`,

    902: `Your password must be at least 8 characters long, with 1 uppercase letter, 1 lowercase
      letter, and 1 number`,

    903: "Passwords don't match. Please try again.",

    904: "You've already registered!",

    905: 'The link you used has expired. Please try resetting your password again',

    906: `We couldn't find you. Please double check your information and try again.
      If you don't have an account <a href="/register">register now</a>.`,

    909: "We're sorry, minors under the age of 13 are not eligible to register.",

    912: 'Oops! This email address is already registered with Collective Health.',
  };

  function getMessageForCode(code) {
    if (!SERVER_ERROR_CODES[code]) {
      if (typeof code !== 'undefined') {
        Raven.captureMessage('Unknown alert code ' + code);
      }
      return SERVER_ERROR_CODES[500];
    }

    return SERVER_ERROR_CODES[code];
  }

  return {
    getMessageForCode,
  };
});
