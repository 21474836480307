import app from 'app';
import templateUrl from './resendVerificationEmail.html';
import './resendVerificationEmail.scss';

import PATTERNS from 'constants/patterns';

import { required, pattern } from '@darkobits/formation-validators';

function ResendVerificationEmail($timeout, Registration) {
  const ResendVerificationEmail = this;

  ResendVerificationEmail.submit = function (values) {
    ResendVerificationEmail.showError = false;
    return Registration.createVerificationEmail(values.email)
      .then(() => {
        ResendVerificationEmail.$close();
      })
      .catch(() => {
        ResendVerificationEmail.showError = true;
      });
  };

  ResendVerificationEmail.$onInit = function () {
    ResendVerificationEmail.formControls = {
      email: {
        validators: {
          required,
          pattern: pattern(PATTERNS.email),
        },
        errors: [
          ['required', 'This field is required'],
          ['pattern', 'Please use a valid email address.'],
        ],
      },
    };
  };

  ResendVerificationEmail.fmReady = (fm) => {
    fm.setModelValues({
      email: ResendVerificationEmail.email,
    });
  };
}

app.component('resendVerificationEmail', {
  bindings: {
    $close: '<',
    title: '<',
    content: '<',
    email: '<',
  },
  controller: ResendVerificationEmail,
  controllerAs: 'ResendVerificationEmail',
  templateUrl,
});

export default 'resendVerificationEmail';
