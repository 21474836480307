import app from 'app';

import templateUrl from './barAccumulator.html';
import './barAccumulator.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-check-small';

app.directive('barAccumulator', () => {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      accumulator: '=barAccumulator',
    },
    link(scope) {
      scope.getCompletionPercentage = () => {
        return `${
          (scope.accumulator.currentAmount / scope.accumulator.maxAmount) * 100
        }%`;
      };
    },
    templateUrl,
  };
});

export default 'barAccumulator';
