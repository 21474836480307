import app from 'app';
import templateUrl from './radioGroup.html';

/**
 * Creates a group of radio buttons based off the options.
 *
 * Options: [
 *   {
 *     value: 'value to bind to the radio option'
 *     label: 'Label that gets class RadioGroup__label'
 *     subLabel: 'Sub-Label that gets class RadioGroup__subLabel'
 *     prompt: 'button that gets class RadioGroup__prompt',
 *     promptClick: 'function that is bound to the click of the prompt'
 *   }
 * ]
 */
app.component('radioGroup', {
  bindings: {
    model: '=',
    name: '@',
    options: '<',
  },
  transclude: true,
  controllerAs: 'RadioGroup',
  templateUrl,
});
