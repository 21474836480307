import app from 'app';
import templateUrl from './benefitDetailExample.html';
import './benefitDetailExample.scss';
import { getLgIconForKey } from 'helpers/plans';

function BenefitDetailExampleCtrl() {
  const BenefitDetailExample = this;

  /**
   * Accepts a line item from one of this benefit detail example's lineItems
   * array and returns a boolean indicating whether or not an asterisk should be
   * displayed directing users to a footnote describing balance billing.
   *
   * The method for determing this is slightly janky because the "supportsOon"
   * field is at the benefit detail example level, when it should really be at
   * the benefit detail example line item level. Therefore, we must check the
   * supportsOon flag on the example AND inspect the title of the line item to
   * see if it contains the copy "out-of-network".
   *
   * @param  {object} lineItem - Line item.
   * @return {boolean} - Whether the line item supports out-of-network coverage.
   */
  BenefitDetailExample.shouldDisplayAsterisk = (lineItem) => {
    return (
      BenefitDetailExample.data.supportsOon &&
      lineItem.title.toLowerCase().includes('out-of-network')
    );
  };

  BenefitDetailExample.$onInit = function () {
    BenefitDetailExample.iconKey = getLgIconForKey(
      BenefitDetailExample.data.key
    );
  };
}

app.component('benefitDetailExample', {
  bindings: {
    data: '<',
    showIcon: '<',
  },
  controller: BenefitDetailExampleCtrl,
  controllerAs: 'BenefitDetailExample',
  templateUrl,
});

export default 'benefitDetailExample';
