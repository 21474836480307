import _ from 'lodash';

export function nestedGroupBy(collection, keys) {
  if (!keys.length) return collection;
  const first = keys[0];
  const rest = keys.slice(1);

  return _.mapValues(_.groupBy(collection, first), (value) => {
    return nestedGroupBy(value, rest);
  });
}
