import app from 'app';
import templateUrl from './meDeepLink.html';
import './meDeepLink.scss';
import getHelpCenterRoute from 'helpers/helpCenterRoutes';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-book';

import { getPage, logEvent, eventCategories } from 'helpers/analytics';

function MeDeepLinkCtrl() {
  const MeDeepLink = this;

  MeDeepLink.onClick = () => {
    logEvent('deepLinkClicked', {
      category: eventCategories.HELP,
      label: MeDeepLink.linkText,
      page: MeDeepLink.page || getPage()
    });
  };

  MeDeepLink.getHelpCenterRoute = getHelpCenterRoute;
}

app.component('meDeepLink', {
  bindings: {
    linkText: '<',
    key: '<',
    page: '<'
  },
  controller: MeDeepLinkCtrl,
  controllerAs: 'MeDeepLink',
  templateUrl
});

export default 'meDeepLink';
