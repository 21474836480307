import app from 'app';
import templateUrl from './recommendationCard.html';
import './recommendationCard.scss';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-up';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-down';
import { logEvent } from 'helpers/analytics';

function RecommendationCard() {
  const RecommendationCard = this;

  RecommendationCard.$onInit = () => {
    if (RecommendationCard.data && !RecommendationCard.open) {
      logEvent('viewRecommendation', RecommendationCard.data.tracker);
    }
  };

  RecommendationCard.ctaButtonClick = ($event) => {
    $event.stopPropagation();

    //remove focus state after button click due to browser limitation
    // eslint-disable-next-line angular/document-service
    document.activeElement.blur();
    logEvent('clickRecommendation', RecommendationCard.data.tracker);
  };

  RecommendationCard.dismissRecommendation = ($event) => {
    $event.stopPropagation();
    logEvent('dismissRecommendation', RecommendationCard.data.tracker);
    RecommendationCard.onDismiss(RecommendationCard.data);
  };
}

app.component('recommendationCard', {
  bindings: {
    data: '<',
    open: '<',
    onDismiss: '<',
  },
  controller: RecommendationCard,
  controllerAs: 'RecommendationCard',
  templateUrl,
});
