const LOGIN_PATH = '/login';
const LANDING_PATH = '/landing';

export default {
  LOGIN: `${LOGIN_PATH}`,
  LANDING: `${LANDING_PATH}`,
  LOGOUT: '/logout',
  TOS: {
    login: `${LOGIN_PATH}`,
    portal: `${LANDING_PATH}`,

    agree: '/agree/',
    decline: '/agree/decline/',
  },
  RESET_PASSWORD: {
    email: '/resetPassword/email',
    sent: '/resetPassword/sent',
    reset: '/passwordReset',
    resetPassword: '/resetPassword',
    default: LOGIN_PATH,
  },
  PARAMS: { system_error: 'system-error=true', user_exists: 'user-error=true' },
  REGISTRATION: {
    angular: {
      register: '/register',
      lookup: '/register/lookup',
      confirm: '/confirm',
    },
    react: {
      register: '/register-v1',
      lookup: '/register-v1/lookup-v1',
      confirm: '/confirm-v1',
    },
  },
  PARTNER_DISCLAIMERS: {
    angular: 'partner-disclaimers-v1',
    react: 'partner-disclaimers',
  },
  RESEND_VERIFY_EMAIL: 'resendVerifyEmail',
  SPANISH_SUPPORT_HREF: 'https://twc.es.collectivehealth.com/',
  MEMBER_ADVOCATE_CONTACT_NUMBER: '844-803-0208',
  CH_HELP_EMAIL: 'help@collectivehealth.com',
  CH_HELP_EMAIL_HREF: 'mailto:help@collectivehealth.com',
};
