import app from 'app';
import templateUrl from './benefitOverviewMedical.html';
import _ from 'lodash';
import './benefitOverviewMedical.scss';

// Sub-components.
import '../benefitCards/benefitCardFull/benefitCardFull';
import '../benefitCards/benefitCardLight/benefitCardLight';

import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-premium-perks';

function BenefitOverviewMedical(chModal) {
  const Benefits = this;

  Benefits.$onInit = () => {
    logEvent('planViewed', {
      category: eventCategories.PLANS,
      planType: 'medical'
    });
  };

  Benefits.showBalanceBillingModal = () => {
    logEvent('balanceBillingWarningViewed', {
      category: eventCategories.PLANS,
      page: 'plan'
    });
    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: 'balanceBilling',
      locals: {
        pageSource: 'planPage'
      }
    });
  };
}

app.component('benefitOverviewMedical', {
  bindings: {
    allPartners: '<',
    planBenefitGroups: '<',
    definitions: '<'
  },
  controller: BenefitOverviewMedical,
  controllerAs: 'Benefits',
  templateUrl
});

export default 'benefitOverviewMedical';
