import path from 'path';

// Mock "require.context" when testing.
if (process.env.NODE_ENV === 'test') {
  require.context = () => ({ keys: () => [] });
}

const ASSETS = {};
const context = require.context('./', false, /.mp3$/);

context.keys().forEach((fileName) => {
  const baseName = path.basename(fileName);
  ASSETS[baseName] = context(fileName);
});

export default ASSETS;
