import app from 'app';
import _ from 'lodash';

app.factory('openViaAjax', ($q, $window) => {
  // Open a url that is provided in an ajax response.
  // typically used to open expirable links/files
  // Ported from frontend-core.
  return function (promiseWithUrl) {
    if (
      !promiseWithUrl ||
      !_.isFunction(promiseWithUrl.then) ||
      !_.isFunction(promiseWithUrl.catch)
    ) {
      return $q.reject('Missing required promise');
    }
    // Based on SO tip: http://stackoverflow.com/a/25050893/218248
    // avoid issues with popup blockers by using window.open
    // synchronously following a user's action
    const downloadWindow = $window.open('', '_blank');
    return promiseWithUrl
      .then(function (objectWithUrl) {
        if (!objectWithUrl || !objectWithUrl.url) {
          downloadWindow.close();
          return $q.reject('Missing url in the download object');
        }
        // and then change its url async based on the ajax response
        downloadWindow.location.href = objectWithUrl.url;
        return objectWithUrl;
      })
      .catch(function (error) {
        downloadWindow.close();
        return $q.reject(error);
      });
  };
});
