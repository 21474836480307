import app from 'app';

import templateUrl from './alertBanner.html';
import './alertBanner.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-close';
import {
  filterAlertsForPage,
  pickHighestPriorityAlert,
} from 'helpers/memberAlert';

function AlertBannerCtrl() {
  const AlertBanner = this;

  AlertBanner.$onInit = () => {
    const relevantAlerts = filterAlertsForPage(
      AlertBanner.alerts,
      AlertBanner.page
    );
    AlertBanner.displayedAlert = pickHighestPriorityAlert(relevantAlerts);
  };

  AlertBanner.dismissAlert = () => {
    AlertBanner.dismissed = true;
  };
}

app.component('alertBanner', {
  bindings: {
    alerts: '<',
    page: '@',
  },
  controller: AlertBannerCtrl,
  controllerAs: 'AlertBanner',
  templateUrl,
});

export default 'alertBanner';
