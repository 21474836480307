import app from 'app';
import templateUrl from './landingInviteDependents.html';
import './landingInviteDependents.scss';

function LandingInviteDependents(User) {
  const LandingInviteDependents = this;

  LandingInviteDependents.$onInit = function () {
    if (
      LandingInviteDependents.planMemberships &&
      LandingInviteDependents.planMemberships.getCoveredMember(
        LandingInviteDependents.currentUser.user.id
      ).isSubscriber
    ) {
      User.getFirstUninvitedDependent(
        LandingInviteDependents.currentUser.user.id,
        LandingInviteDependents.planMemberships.coveredMembersOver13
      ).then((dependent) => {
        LandingInviteDependents.unregisteredDependent = dependent;
      });
    }
  };
}

app.component('landingInviteDependents', {
  bindings: {
    currentUser: '<',
    planMemberships: '<',
  },
  controller: LandingInviteDependents,
  controllerAs: 'LandingInviteDependents',
  templateUrl,
});
