import app from 'app';

app.factory('phiCacheFactory', ($cacheFactory, $rootScope) => {
  const phiCaches = [];

  $rootScope.$on('sessionLogout', () => {
    phiCaches.forEach((cache) => {
      cache.removeAll();
    });
  });

  return function (cacheNamespace) {
    const phiCache = $cacheFactory(cacheNamespace);

    phiCaches.push(phiCache);

    return phiCache;
  };
});
