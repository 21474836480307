import app from 'app';
import { nestedGroupBy } from 'helpers/grouping';

app.factory('PlanDetails', ($q, ApiMCM, Logger) => {
  const apiName = 'plan_details';
  const sponsorshipPlanDetailsMap = {};

  function getPlanDetails(sponsorshipId) {
    if (sponsorshipPlanDetailsMap[sponsorshipId]) {
      return sponsorshipPlanDetailsMap[sponsorshipId];
    }

    const params = {
      sponsorshipId,
      includeContributions: true,
      newPartnerModel: true,
      platform: 'web',
    };

    const promise = ApiMCM.get(apiName, params)
      .then((response) => {
        response.planDetails.forEach(
          (plan) => (plan.type = plan.type.toLowerCase())
        );
        return response.planDetails;
      })
      .catch((error) => {
        Logger.captureApiError(apiName, params, error.response.data);
        return $q.reject(new Error('Error fetching plan details'));
      });

    sponsorshipPlanDetailsMap[sponsorshipId] = promise;
    return sponsorshipPlanDetailsMap[sponsorshipId];
  }

  function groupAccumulators(accumulators) {
    return nestedGroupBy(accumulators, ['type', 'networkType', 'familyTier']);
  }

  return {
    getPlanDetails,
    groupAccumulators,
  };
});
