import app from 'app';
import templateUrl from './unsubscribe.html';
import './feedback.scss';

import 'components/checkboxListItem/checkboxListItem';

import {
  COMM_SETTINGS,
  LEGALLY_REQUIRED_COMMS,
} from 'constants/communicationSettings';

function UnsubscribeCtrl($state, Raven, UnsubscribeService) {
  const Unsubscribe = this;

  Unsubscribe.communicationSettings = COMM_SETTINGS;
  Unsubscribe.legallyRequiredCommunications = LEGALLY_REQUIRED_COMMS;

  Unsubscribe.submit = () => {
    return UnsubscribeService.updateEmailSettings(
      Unsubscribe.token,
      Unsubscribe.emailSettings
    )
      .then(() => {
        Unsubscribe.submitted = true;
        return;
      })
      .catch((error) => {
        // TODO: Check with design to implement error handling (toast? banner?)
        Raven.captureMessage('Error updating email settings with token.', {
          extra: error,
        });
        return error;
      });
  };

  Unsubscribe.$onInit = () => {
    UnsubscribeService.getEmailSettings(Unsubscribe.token)
      .then((emailSettings) => {
        Unsubscribe.emailSettings = emailSettings;
      })
      .catch((error) => {
        Raven.captureMessage('Error fetching email settings with token.', {
          extra: error,
        });
        $state.go('UnsubscribeTokenExpired');
      });
  };
}

app.component('unsubscribe', {
  bindings: {
    token: '<',
    emailAddress: '<',
  },
  controller: UnsubscribeCtrl,
  controllerAs: 'Unsubscribe',
  templateUrl,
});

export default 'unsubscribe';
