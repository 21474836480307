import app from 'app';
import _ from 'lodash';

// Pages.
import registrationPage from 'pages/registration/registration';
import registrationForm from 'pages/registration/registrationForm/registrationForm';
import findAccount from 'pages/registration/findAccount/findAccount';
import confirmEmail from 'pages/registration/confirmEmail/confirmEmail';
import ROUTES from './routes.json';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

app.config(($stateProvider) => {
  const redirectIfNoForm = [
    '$q',
    '$stateParams',
    ($q, $stateParams) => {
      if (!$stateParams.email) {
        return $q.reject(new Error('registrationFormRequired'));
      }
    },
  ];

  $stateProvider.state('Registration', {
    abstract: true,
    url: `${ROUTES.register.angular}`,
    parent: 'Base',
    params: {
      errorCode: '',
    },
    resolve: {
      errorCode: ($stateParams) => $stateParams.errorCode,
      isReactRegistrationOn: (FeatureFlagService) => {
        const client = FeatureFlagService.getLDClient();
        return client.waitUntilReady().then(() => {
          return getFFVariation(client, 'isReactRegistrationOn');
        });
      },
    },
    onEnter(isReactRegistrationOn) {
      if (isReactRegistrationOn) {
        window?.location?.replace(ROUTES.register.react);
      }
    },
    data: {
      title: 'Collective Health | Register',
    },
    views: {
      'content@Base': {
        component: registrationPage,
      },
    },
  });

  $stateProvider.state('Register', {
    url: '?regtoken&coldReg&pk_campaign&pk_kwd',
    parent: 'Registration',
    resolve: {
      isColdReg($stateParams) {
        return $stateParams.coldReg === 'true';
      },
      member($q, $stateParams, Registration) {
        return Registration.getMemberByToken($stateParams.regtoken)
          .then((member) => _.set(member, 'settings.communication', 'email'))
          .catch((e) => {
            switch (_.get(e, 'data.errorNumber')) {
              case 904:
                return $q.reject(new Error('alreadyRegistered'));
              case 103:
                return $q.reject(new Error('expiredRegistrationToken'));
              default:
                return $q.reject(new Error('invalidRegistrationToken'));
            }
          });
      },
      termsCopy(FlLegalService) {
        return FlLegalService.getLegalCopy().then(
          (legalCopies) => legalCopies.all,
        );
      },
    },
    onEnter(isReactRegistrationOn) {
      if (isReactRegistrationOn) {
        const url = ROUTES.register.react;
        window?.location?.replace(`/${url + window?.location?.search}`);
      }
    },
    views: {
      'regContent@Registration': {
        component: registrationForm,
      },
    },
  });

  /**
   * Also known as "Cold Registration". Presents the user with a form they can
   * use to look up their account and continue to the main registration form.
   */
  $stateProvider.state('FindAccount', {
    url: `/${ROUTES.lookup.angular}`,
    parent: 'Registration',
    views: {
      'content@Base': {
        component: findAccount,
      },
    },
    onEnter(isReactRegistrationOn) {
      if (isReactRegistrationOn) {
        const url = `${ROUTES.register.react}/${ROUTES.lookup.react}`;
        window?.location?.replace(`/${url + window?.location?.search}`);
      }
    },
  });

  $stateProvider.state('ConfirmEmail', {
    url: `/${ROUTES.confirm.angular}?email`,
    parent: 'Registration',
    resolve: {
      redirectIfNoForm: redirectIfNoForm,
    },
    views: {
      'regContent@Registration': {
        component: confirmEmail,
      },
    },
    onEnter(isReactRegistrationOn) {
      if (isReactRegistrationOn) {
        const url = ROUTES.confirm.react;
        window?.location?.replace(`/${url + window?.location?.search}`);
      }
    },
  });
});
