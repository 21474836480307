import app from 'app';
import mxConfirmationModal from 'components/modals/mxConfirmationModal/mxConfirmationModal';
import mxRegisteredModal from 'components/modals/mxRegisteredModal/mxRegisteredModal';
import { setUserIdentifier } from 'helpers/userIdentifier';
import { deleteCookie } from '@collectivehealth/cookie';

app.service('RegistrationForm', ($state, chModal, Session, Redirect) => {
  function openRegisteredConfirmationModal() {
    Redirect.redirectTo();
    chModal.open({
      component: mxRegisteredModal,
    });
  }

  function openCheckEmailModal() {
    chModal.open({
      component: mxConfirmationModal,
      locals: {
        body: `You should receive an email from us shortly containing a link to verify your email.
         If you don't see it in the next few minutes (and it's not in your Spam folder) send us
         a message or give us a ring at 844-803-0210`,
        confirm: 'Close',
        header: 'Almost there...',
      },
    });
  }

  function handleRegistrationSuccess(response) {
    if (response.userIdentifier) {
      setUserIdentifier(response.userIdentifier);
    }

    if (response.loginToken) {
      Session.beginSession(response.loginToken);
      const session = Session.getSession();

      if (session.isVerified) {
        openRegisteredConfirmationModal();
      } else {
        const domain = window?.location?.hostname || null;
        const paths = location?.pathname?.split('/') || [];
        /*
         * Clearing all the cookies is needed since the user isn't verified.
         * The Session.logout() function was removed since it is causing redirect to /login as default
         * and this is not the expected behavior for unverified users.
         */
        deleteCookie('chUserIdentifier', { path: '/' });
        deleteCookie('sponsorId', { path: '/' });

        /*
         * On member-portal, the following code below is used on removeSessionCookieFromSubPaths function
         * inside libraries/mx-machines/src/AuthMachine/helpers/cookieHelper.js.
         */
        if (paths && paths.length > 0 && domain) {
          paths.forEach((path) => {
            // Only delete the supplied cookie for the subpaths
            if (path) {
              // Domain
              deleteCookie(`chLoginToken-${domain}`, {
                domain,
                path: `/${path}`,
              });

              // All subdomains
              deleteCookie(`chLoginToken-${domain}`, {
                domain: `.${domain}`,
                path: `/${path}`,
              });
            } else {
              deleteCookie(`chLoginToken-${domain}`, {
                domain,
                path: '/',
              });
              deleteCookie(`chLoginToken-${domain}`, {
                domain: `.${domain}`,
                path: '/',
              });
            }
          });
        }

        $state.go('ConfirmEmail', { email: session.email });
      }
    } else {
      $state.go('Login').then(openCheckEmailModal);
    }
  }

  return {
    handleRegistrationSuccess,
  };
});
