import app from 'app';
import templateUrl from './planIcon.html';
import './planIcon.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-dental-small';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-dental';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-medical-small';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-medical';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-pharmacy';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-pharmacy-small';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-vision-small';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-vision';

app.component('planIcon', {
  bindings: {
    planType: '<',
    small: '<?',
  },
  controllerAs: 'PlanIcon',
  templateUrl,
});

export default 'planIcon';
