import app from 'app';
import templateUrl from './mxBackButton.html';
import './mxBackButton.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-arrow-left';

app.component('mxBackButton', {
  bindings: {
    click: '&',
  },
  controllerAs: 'MxBackButton',
  transclude: true,
  templateUrl,
});

export default 'mxBackButton';
