import app from 'app';

import MemberFunds from 'models/healthFunds/MemberFunds';

app.factory('SpendingAccountsService', (Api, Raven, SSOService) => {
  const memberFundsPromise = {};

  function getMemberFunds(personId, sponsorId, subscriberId) {
    const key = `${personId}${sponsorId}${subscriberId}`;

    if (memberFundsPromise[key]) {
      return memberFundsPromise[key];
    }

    const promise = Api.req({
      apiPrefix: '/api/v3',
      endPoint: `/person/${personId}/spending_account`,
      params: {
        sponsorId,
        subscriberId,
        platform: 'WEB',
      },
    })
      .catch(() => {
        Raven.captureMessage('Failed to fetch health funds for member');
        return {};
      })
      .then((resp) => new MemberFunds(resp));

    memberFundsPromise[key] = promise;
    return memberFundsPromise[key];
  }

  // This function will sso into the ProductPartner account specified by the spendingAccountId param.
  // If no spendingAccountId is passed, it will attempt to log in to the loggedInUser's Alegeus account.
  // if that user has no spending accounts of their own, it will login as the first person who authorized you
  // as an authorized dependent for their account.
  function ssoIntoProductPartner(sponsorId, spendingAccountId, productPartner) {
    return Api.req({
      endPoint: `/partner/${productPartner}/sso`,
      cache: false,
      params: {
        spendingAccountId: spendingAccountId,
        sponsorId: sponsorId,
      },
    }).then((ssoData) => SSOService.openSsoLink(ssoData, { target: '_self' }));
  }

  return {
    getMemberFunds,
    ssoIntoProductPartner,
  };
});
