import app from 'app';
import _ from 'lodash';
import { logEvent, eventCategories } from 'helpers/analytics';

app.factory('BenefitService', () => {
  /**
   * Provided a plan and a benefit key, searches each benefit group in the
   * plan's details object and returns the benefit object, if found.
   * If no matching benefit was found, returns false.
   *
   * @param  {object} plan - Plan object to search.
   * @param  {string} benefitKey - Value of the benefit's 'key' field to search for.
   * @return {object|boolean} - Benefit, or false.
   */
  function getBenefitInPlan(plan, benefitKey) {
    const benefit =
      plan.details.benefitGroups.reduce((prev, cur) => {
        return prev || cur.benefits.find(benefit => benefit.key === benefitKey);
      }, false) || false;

    if (!benefit) {
      logEvent('missingBenefit', {
        category: eventCategories.PLANS,
        benefitKey
      });
    }

    return benefit;
  }

  /**
   * Returns a boolean expressing the presence of a target benefit in a list of benefits
   * @param  {Array} benefitList - Array of benefits to look through
   * @param  {object} targetBenefitKey - benefit in question to be searched for in the list of benefits
   * @return {boolean} - true if the benefit is in the list, else false.
   */
  function isBenefitAvailable(benefitList, targetBenefitKey) {
    return (
      _.findIndex(benefitList, benefit => benefit.key === targetBenefitKey) !==
      -1
    );
  }

  return {
    getBenefitInPlan,
    isBenefitAvailable
  };
});
