import app from 'app';
import templateUrl from './supportMessageLink.html';
import './supportMessageLink.scss';
import supportMessage from 'components/supportMessage/supportMessage';

import { logEvent, eventCategories } from 'helpers/analytics';

// TODO refactor once ZenDesk is fully live
function SupportMessageLink($state, Sponsor, chModal) {
  const SupportMessageLink = this;

  SupportMessageLink.contact = () => {
    Sponsor.getSponsorZendeskSettings()
      .then(shouldShowInbox => {
        if (shouldShowInbox) {
          // TODO revert after testing
          // goToInboxCompose()
          openSupportEmail();
        } else {
          openSupportEmail();
        }
        openSupportEmail();
      })
      .catch(openSupportEmail);
  };

  function goToInboxCompose() {
    $state.go('InboxCompose');
    logEvent('messagesHomeViewed', {
      category: eventCategories.INBOX,
      messageStartType: 'deflection'
    });
  }

  function openSupportEmail() {
    chModal.open({
      component: supportMessage
    });
  }
}

app.component('supportMessageLink', {
  bindings: {
    linkText: '@',
    clickHandler: '&?'
  },
  controller: SupportMessageLink,
  controllerAs: 'SupportMessageLink',
  templateUrl
});
