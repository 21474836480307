import app from 'app';
import templateUrl from './docs.html';
import './docs.scss';

app.component('docs', {
  bindings: {
    sponsorDocuments: '<',
  },
  controllerAs: 'Docs',
  templateUrl,
});

export default 'docs';
