import app from 'app';

app.factory('AccumulatorsService', (ApiMCM) => {
  const accumulatorsMap = {};

  function getAccumulators(sponsorshipId) {
    if (accumulatorsMap[sponsorshipId]) {
      return accumulatorsMap[sponsorshipId];
    }

    const promise = ApiMCM.get('flat_accumulators', {
      sponsorshipId,
    }).then((response) => {
      return response;
    });

    accumulatorsMap[sponsorshipId] = promise;
    return accumulatorsMap[sponsorshipId];
  }

  return {
    getAccumulators,
  };
});
