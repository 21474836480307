import '@collectivehealth/ch-user-agent';
import svg4everybody from 'svg4everybody';
import 'angular-jwt';

// Load amplitude
import './amplitude.js';

// Load Qualtrics
import './qualtrics';

// Run SVG4Everybody polyfill.
svg4everybody();

// Load global styles (once).
import './index.scss';

// Load services.
import './services';

// Load state tree.
// TODO: Consider splitting these files and defining states in each pages/<page>
// folder.
import './states';
import './states.registration';
import './states.loginPortal';
import './states.auth';
import './states.coverage';
import './states.verifyEmail';
import './states.sso';

// Global components that are not routed-to or otherwise imported by pages.
import './components';

import '@collectivehealth/dls-panel';

// Misc.
import './config';
import './appCtrl';
import './polyfills';
import './directives';
import './filters';

// Re-export module name for testing.
import app from 'app';
export default app.name;
