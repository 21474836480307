import app from 'app';
import _ from 'lodash';
import templateUrl from './resetCredentials.html';
import lookupPasswordFormTemplateUrl from './lookupPasswordForm.html';
import lookupPasswordConfirmationTemplateUrl from './lookupPasswordConfirmation.html';
import './resetCredentials.scss';

function ResetCredentials($timeout, Api, ServerErrorCodes) {
  const Creds = this;

  const templates = {
    passwordForm: lookupPasswordFormTemplateUrl,
    passwordSent: lookupPasswordConfirmationTemplateUrl,
  };

  let template = 'passwordForm';

  // Identifies if a property on an ng object is part of angular's API or
  // something added by Collective Health.
  const isOurs = (key) => {
    return _.indexOf(key, '$') === -1;
  };

  /**
   * setFormValidity is used to set all the individual fields in a form
   * to valid or invalid. This is done so if someone is trying to force
   * their way into an account they cannot trial and error their way into
   * knowing if a person has an account and where their info it.
   *
   * @param {oject} form element
   * @param {boolean} validity set all the fields in the form to valid/invalid
   */
  const setFormValidity = (form, validity) => {
    _.forEach(form, (value, key) => {
      if (isOurs(key)) {
        // lookup refers to whether the form field value was valid or not after
        // looking it up on the server.
        form[key].$setValidity('lookup', validity);
      }
    });
  };

  const handleResetPasswordRejectAlerts = (error) => {
    // var options;
    Creds.passwordError = true;

    if (error.status === 400 && _.has(error, 'data.errorNumber')) {
      Creds.serverError = ServerErrorCodes.getMessageForCode(
        error.data.errorNumber
      );
    } else {
      Creds.serverError = ServerErrorCodes.getMessageForCode(500);
    }
  };

  let disableResendTimeout;

  /**
   * Temporarily disables the resend button. This is so the user has feedback that their
   * click has registered, and also prevents accidental multi clicks which cause issues on
   * our backend.
   * @param  {number} disableDuration Duration of time in ms before the button should be reenabled
   */
  function disableResend(disableDuration) {
    Creds.resendDisabled = true;

    $timeout.cancel(disableResendTimeout);

    disableResendTimeout = $timeout(() => {
      Creds.resendDisabled = false;
    }, disableDuration);
  }

  /* Scope Functions */

  Creds.resetTemplate = (templateName) => {
    // hide any alerts that may be present
    Creds.serverError = '';
    Creds.passwordError = false;
    template = templateName;
  };

  Creds.resetForm = (form) => {
    if (!!form && form.$submitted) {
      setFormValidity(form, true);
    }
  };

  Creds.getResetTemplate = () => {
    return templates[template];
  };

  // Sends the reset email, given user's email address
  Creds.sendResetPassword = (form) => {
    // hide any alerts that may be present
    Creds.serverError = '';
    Creds.loading.reset = true;

    Api.post('/reset_password', {
      email: Creds.credentials.email.toLowerCase(),
    })
      .then(() => {
        template = 'passwordSent';
      })
      .catch((error) => {
        handleResetPasswordRejectAlerts(error);
        setFormValidity(form, false);
      })
      .finally(function () {
        Creds.loading.reset = false;
      });
  };

  Creds.resendResetPassword = (form) => {
    disableResend(30000);

    return Creds.sendResetPassword(form);
  };

  Creds.$onInit = () => {
    Creds.credentials = {
      email: Creds.prefilledEmail,
    };

    Creds.loading = {
      reset: false,
      lookup: false,
    };
  };
}

app.component('resetCredentials', {
  bindings: {
    $close: '<',
    prefilledEmail: '<',
  },
  controller: ResetCredentials,
  controllerAs: 'Creds',
  templateUrl,
});

export default 'resetCredentials';
