// Filters the current active alerts for those relevant to current page
export function filterAlertsForPage(alerts, pageName) {
  return alerts.filter((alert) => alert.isOnPage(pageName));
}

// Sorts alerts by priority, such that the highest priority relevant alert will display on a page
export function pickHighestPriorityAlert(alerts) {
  const sortedAlerts = alerts.sort((a, b) => {
    // If the priority is -1, the feature is not yet assigned a priority, so send it to back of sorted list
    if (a.priority < 0) {
      return 1;
    } else if (b.priority < 0) {
      return -1;
    }
    // Otherwise, sort by the priority as usual
    return a.priority - b.priority;
  });

  return sortedAlerts[0];
}

export default {
  filterAlertsForPage,
  pickHighestPriorityAlert,
};
