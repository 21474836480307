import app from 'app';
import moment from 'moment';

app.factory('TimeService', (Api, Logger) => {
  const TIME_ENDPOINT = '/membercoremiddleware/time';

  function isChOpen() {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: `/membercoremiddleware/time/is_ch_open`,
    }).then((response) => {
      return response.isChOpen;
    });
  }

  function getBusinessHours() {
    const endPoint = `${TIME_ENDPOINT}/business_hours`;
    return Api.req({
      apiPrefix: '/api/v1',
      cache: true,
      endPoint,
    })
      .then((response) => {
        return {
          today: transformHourResponse(response.today),
          nextBusinessDay: transformHourResponse(response.nextBusinessDay),
          hours: response.hours.map(transformHourResponse),
          openStatus: transformOpenStatus(response),
        };
      })
      .catch((error) => {
        Logger.captureApiError(endPoint, {}, error);
        return [];
      });
  }

  function transformHourResponse(hour) {
    const result = Object.assign({}, hour);
    result.start.timeString = formatTime(hour.start);
    result.end.timeString = formatTime(hour.end);
    result.dayStringTLA = result.dayString.substring(0, 3);
    return result;
  }

  function transformOpenStatus(data) {
    if (data.today) {
      const { today, nextBusinessDay } = data;
      switch (today.status) {
        case 'OPEN':
          return `${today.start.timeString}-${today.end.timeString} PT`;
        case 'OPENING_SOON':
          return `Opens ${today.start.timeString} PT`;
        default:
          return `Opens ${nextBusinessDay.dayString.substring(0, 3)}`;
      }
    }
    return '';
  }

  function formatTime(time) {
    const localTime = moment().hour(time.hour).minute(time.minute);
    return localTime.format('h:mm A');
  }

  return {
    getBusinessHours,
    isChOpen,
  };
});
