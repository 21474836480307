import app from 'app';
import { className } from './chEllipsis.scss';

/**
 * An ellipsis with looping animated dots. Good for loading messages.
 *
 * Example usage:
 *
 * <span>Connecting<ch-ellipsis></ch-ellipsis></span>
 */
app.component('chEllipsis', {
  template: `
    <span class="${className}">
      <span class="dot">.</span>
      <span class="dot">.</span>
      <span class="dot">.</span>
    </span>
  `,
});
