import app from 'app';
import { getUserIdentifier } from 'helpers/userIdentifier';

app.factory('Logger', (Raven) => {
  /**
   * @param {string} apiName - api name
   * @param {object} queryParameters - api relevance query params or data
   * @param {object} apiStatus - axios error.response.data
   * @param {object.code} code
   * @param {object.message} message
   */
  function captureApiError(apiName, queryParameters, apiStatus) {
    const extra = {
      userIdentifier: getUserIdentifier(),
      queryParameters,
      statusCode: apiStatus && apiStatus.code,
      message: apiStatus && apiStatus.message,
      tag: 'APIError',
    };
    Raven.captureMessage(apiName, { extra });
  }

  return {
    captureApiError,
  };
});
