import { logEvent, eventCategories } from 'helpers/analytics';
import app from 'app';
import _ from 'lodash';

import MemberCards from 'models/cards/MemberCards';
import CARDS_PERIOD from 'constants/cardsPeriod';

import cards from 'components/cards/cards';
import proofOfCoverage from 'components/proofOfCoverage/proofOfCoverage';

app.factory('Cards', (Api, ApiMCM, chModal, $q, Logger, PlanMembership) => {
  const sponsorshipCardsMap = {};

  function getCards(sponsorshipId) {
    if (sponsorshipCardsMap[sponsorshipId]) {
      return sponsorshipCardsMap[sponsorshipId];
    }

    const params = {
      sponsorshipId,
      includeFutureCards: true
    };

    const promise = ApiMCM.get('cards', params)
      .then(response => response.data[0])
      .catch(error => {
        Logger.captureApiError('cards', params, error.response.data);
        return $q.reject(new Error('Error fetching cards'));
      });

    sponsorshipCardsMap[sponsorshipId] = promise;
    return sponsorshipCardsMap[sponsorshipId];
  }

  function getCardsByMember(sponsorshipId, memberId) {
    return getCards(sponsorshipId).then(data => {
      const cards = {
        [CARDS_PERIOD.CURRENT]: filterCardsByMember(
          data.currentCardsMembers,
          memberId
        ),
        [CARDS_PERIOD.FUTURE]: filterCardsByMember(
          data.futureCardsMembers,
          memberId
        )
      };
      return new MemberCards(cards);
    });
  }

  function filterCardsByMember(members, memberId) {
    if (!Array.isArray(members)) return [];
    const member = _.find(members, member => member.id === memberId);
    return member ? member.cards : [];
  }

  function getDependents(currentUser, currentSponsorship, planMemberships) {
    //if user is a subscriber
    if (currentUser.user.id === currentSponsorship.subscriber.id) {
      return planMemberships.currentCoveredMembers.filter(
        member => member.id !== currentUser.user.id
      );
    }
    return [];
  }

  function openModal({ currentUser, currentSponsorship }) {
    return PlanMembership.getPlanMemberships(currentSponsorship.id).then(
      planMemberships => {
        return chModal.open({
          component: cards,
          closeOnEsc: true,
          locals: {
            currentUser,
            currentSponsorship,
            planMemberships
          }
        });
      }
    );
  }

  function openProofOfCoverage({ currentUser, currentSponsorship }) {
    return PlanMembership.getPlanMemberships(currentSponsorship.id, {
      successCallback: () =>
        logEvent('pocPageViewed', {
          category: eventCategories.PROOF_OF_COVERAGE,
          pocPageLoadSuccess: true
        }),
      errorCallback: () =>
        logEvent('pocPageViewed', {
          category: eventCategories.PROOF_OF_COVERAGE,
          pocPageLoadSuccess: false
        })
    }).then(planMemberships => {
      return chModal.open({
        component: proofOfCoverage,
        closeOnEsc: true,
        locals: {
          currentUser,
          currentSponsorship,
          planMemberships
        }
      });
    });
  }

  function requestCard({
    memberId,
    sponsorId,
    subscriberId,
    planMembershipIds
  }) {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/print_service/print/card',
      method: 'POST',
      data: {
        memberId,
        sponsorId,
        subscriberId,
        planMembershipIds,
        parcelType: 'replacementCard', // always be "replacementCard" for these requests
        isOpenEnrollment: false // always false
      }
    });
  }

  function getPlansAndMembersForPoCov(sponsorshipId, userIdentifier) {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: `/membercoremiddleware/proof-of-coverage/eligible-members?sponsorshipId=${sponsorshipId}`,
      method: 'GET',
      headers: {
        'ch-user-identifier': userIdentifier
      }
    });
  }

  function requestProofOfCoverage({ userIdentifier, planMembershipIds, type }) {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/membercoremiddleware/proof-of-coverage',
      method: 'POST',
      headers: {
        'ch-user-identifier': userIdentifier
      },
      data: {
        planMembershipIds,
        type
      }
    });
  }

  return {
    getCards,
    getCardsByMember,
    getDependents,
    getPlansAndMembersForPoCov,
    openModal,
    requestCard,
    openProofOfCoverage,
    requestProofOfCoverage
  };
});
