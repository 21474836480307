// -----------------------------------------------------------------------------
// ----- Member Portal Configuration -------------------------------------------
// -----------------------------------------------------------------------------

import _ from 'lodash';
import moment from 'moment';
import app from 'app';

import { unsetAmplitudeUser } from 'helpers/analytics';
import { configure as configureFormation } from '@darkobits/formation';

import sessionTimeout from 'components/sessionTimeout/sessionTimeout';
import { deleteCookie } from '@collectivehealth/cookie';
import { checkReactLoginPortalFlag } from 'helpers/loginPortal';
import getRoute from 'helpers/getRoute';
import ROUTES from 'routes.json';

// ----- State Change Errors ---------------------------------------------------

app.run(
  (
    $log,
    $q,
    $timeout,
    $transitions,
    $rootScope,
    $state,
    $window,
    FeatureFlagService,
  ) => {
    $rootScope.getCurrentStateName = () => $state.current.name;

    $state.defaultErrorHandler((error) => {
      /**
       * This will log state transition "errors" (which are not always errors and
       * in some cases triggered intentionally by us) in development environments
       * only. Having this handler registered but doing a NOOP will prevent what
       * may look like an error from being logged to the console in production.
       */
      if (webpack.ENV === 'local') {
        $log.error('[State Transition "Error"]', error);
      }
    });

    $transitions.onError({}, (transition) => {
      if (transition.error() && !transition.error().redirected) {
        const options = {
          redirectTo: transition.to().name,
          loginEmail: _.get(transition.params(), 'loginEmail'),
        };

        // Remove key/value pairs with falsey values and 'loginEmail' key
        const compactParams = _.omit(
          _.pickBy(transition.params(), _.identity),
          ['loginEmail'],
        );

        switch (transition.error().message) {
          case 'noUser':
            // avoid adding redirectToParams for empty param arrays
            if (_.values(compactParams).length > 0) {
              options.redirectToParams = encodeURIComponent(
                JSON.stringify(compactParams),
              );
            }

            // If we are going back to the login page, hide the login box
            options.isChatOpen = false;
            $window.sessionStorage.removeItem('chatInstance');

            return $state.go('Login', options);
          case 'registrationFormRequired':
            return $state.go('Register');
          case 'alreadyAuthenticated':
            return $state.go('Landing');
          case 'invalidRegistrationToken':
            return $state.go('FindAccount');
          case 'expiredRegistrationToken':
            return $state.go('FindAccount', { errorCode: 103 });
          case 'passwordResetError':
            $window.sessionStorage.removeItem('chatInstance');
            return $state.go('Login', {
              errorCode: transition.error().errorNumber,
              isChatOpen: false,
            });
          case 'alreadyRegistered':
            $window.sessionStorage.removeItem('chatInstance');
            return $state.go('Login', {
              errorCode: 904,
              isChatOpen: false,
            });
          case 'claimNotFound':
          case 'BENEFIT_NOT_FOUND':
            return $state.go('404', {}, { location: false });
          default:
            /**
             * EXTREMELY IMPORTANT:
             *
             * This will catch ui-router's "transition superseded" errors which,
             * if unhandled, can result in an infinite redirect loop. By returning
             * a resolved promise here, we can short-circuit that behavior.
             */
            return $q.resolve();
        }
      }
    });
  },
);

// ----- Session Timeout -------------------------------------------------------

app.config((IdleProvider) => {
  // Users become idle after 9 minutes.
  IdleProvider.idle(moment.duration(9, 'minutes').asSeconds());
  // Session will expire 1 minute after users become idle.
  IdleProvider.timeout(moment.duration(1, 'minute').asSeconds());
});

app.run(
  (
    $rootScope,
    $state,
    $stateParams,
    chModal,
    Idle,
    Session,
    $window,
    FeatureFlagService,
  ) => {
    // Show the warning dialog when users become idle.
    $rootScope.$on('IdleStart', () => {
      chModal.open({
        component: sessionTimeout,
      });
    });

    $rootScope.$on('IdleTimeout', () => {
      Session.logout();
      unsetAmplitudeUser();
      $window.sessionStorage.removeItem('affiliatesSponsorId');
    });

    // Send users back to Login when their sessions expire.
    $rootScope.$on(Session.events.SESSION_END, () => {
      // Stop watching for Idle timer
      Idle.unwatch();

      // Avoid an infinite redirectTo-loop from Login to Login by removing this
      // parameter if we are trying to redirect to Login.
      const redirectTo =
        $stateParams.redirectTo === 'Login' ? null : $stateParams.redirectTo;

      /**
       * Currently, we don't have any modals that get opened during a logout step, so to prevent
       * modals from being present on the login page after a session timeout, we close all modals.
       *
       * If however this changes and we do need modals to remain open after session timeout, we'll
       * need to revisit this approach.
       */
      chModal.closeAll();
      /* $window.sessionStorage.removeItem('chatInstance');
    $window.sessionStorage.removeItem('lastChatStatus');
    $window.sessionStorage.removeItem('affiliatesSponsorId'); */
      $window.sessionStorage.clear();
      $window.localStorage.clear();

      /*eslint-disable */
      if (Five9SocialWidget.widgetAdded) {
        Five9SocialWidget.removeWidget();
      }
      /*eslint-enable */
      deleteCookie('chUserIdentifier', { path: '/' });
      deleteCookie('sponsorId', { path: '/' });
      deleteCookie(`chLoginToken-${$window.location?.hostname}`, { path: '/' });

      checkReactLoginPortalFlag(FeatureFlagService).then((ffEnabled) => {
        if (!ffEnabled) {
          $state.go(
            'Login',
            {
              redirectTo,
              isChatOpen: false,
            },
            {
              // Forces the refresh, EVERYTIME
              reload: true,
            },
          );
        } else {
          window?.location?.replace(`/${getRoute(ROUTES.landing.react)}`);
        }
      });
    });
  },
);

// ----- Scroll to Top on State Change -----------------------------------------

/**
 * Ensures the viewport is always scrolled to the top after a state change. This
 * will default to the main ui-view (see index.html). To scroll to a different
 * ID, pass it as the 'scrollTo' custom transition option. To prevent scrolling,
 * set scrollTo to null.
 *
 * ui-sref-opts="{custom: scrollTo: null}}"
 *
 * or
 *
 * $state.go('SomeState', {some: params}, {custom: {scrollTo: 'someElementId'}});
 *
 * Also updates a data-appstate attribute on the body with the name of the current
 * route.
 */
app.run(($anchorScroll, $document, $transitions) => {
  $transitions.onSuccess({ from: '*', to: '*' }, (transition) => {
    const customOptions = transition.options().custom;

    if (customOptions.scrollTo !== null) {
      $anchorScroll(customOptions.scrollTo || 'main');
    }
  });
});

// ----- Misc ------------------------------------------------------------------

// This should be set to the same height as the header.
app.value('duScrollOffset', 72);

app.config((matchmediaProvider) => {
  matchmediaProvider.rules.phone = '(max-width: 767px)';
  matchmediaProvider.rules.tablet = '(min-width: 768px)';
  matchmediaProvider.rules.desktop = '(min-width: 1024px)';
});

// Don't log errors to the console on (potentially) un-handled rejections.
app.config(($qProvider) => {
  $qProvider.errorOnUnhandledRejections(false);
});

// TODO: Fix translations.
app.config(($compileProvider) /* , $translateProvider , i18n ) */ => {
  // Disable Angular debugging classes in production.
  $compileProvider.debugInfoEnabled(webpack.ENV === 'local');

  // $translateProvider
  //   .translations('en-US', i18n['en-US'])
  //   .preferredLanguage('en-US')
  //   .useSanitizeValueStrategy('sanitize');
});

// Formation configuration
configureFormation({
  showErrorsOn: 'touched, submitted',
});
