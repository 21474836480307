import app from 'app';

/**
 * No matvch directive
 *
 * Checks to see if 2 values are different from each other
 *
 * Other Notes:
 *   Modified from: http://stackoverflow.com/questions/14012239/password-check-
 *   directive-in-angularjs/19559681#19559681
 */

app.directive('chNotMatch', () => {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    link: (scope, elem, attrs, ngModel) => {
      if (!ngModel) {
        return; // do nothing if no ng-model
      }

      const validate = () => {
        // values
        const thisVal = ngModel.$viewValue;
        const toNotMatch = attrs['chNotMatch'];

        // set validity
        ngModel.$setValidity(
          'ch-not-match',
          !thisVal || !toNotMatch || thisVal !== toNotMatch
        );
      };

      // watch own value and re-validate on change
      scope.$watch(attrs.ngModel, () => {
        validate();
      });

      // observe the other value and re-validate on change
      attrs.$observe('chNotMatch', () => {
        validate();
      });
    },
  };
});
