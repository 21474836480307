import app from 'app';
import templateUrl from './memberCard.html';
import './memberCard.scss';

const DATA_ITEMS = [
  {
    key: 'memberName',
    label: 'Member Name',
  },
  {
    key: 'subscriberId',
    label: 'Subscriber ID',
  },
  {
    key: 'networkName',
    label: 'Network',
  },
  {
    key: 'planName',
    label: 'Plan',
  },
  {
    key: 'groupNumber',
    label: 'Group ID',
  },
  {
    key: 'rxBinValue',
    label: 'RxBin',
  },
  {
    key: 'rxPcnValue',
    label: 'RxPCN',
  },
  {
    key: 'rxGroupValue',
    label: 'RxGroup',
  },
];

function MemberCardCtrl() {
  const MemberCard = this;

  MemberCard.$onChanges = () => {
    MemberCard.dataItems = DATA_ITEMS.map((item) => {
      const { label, key } = item;

      return { label, value: MemberCard.card.cardData[key] };
    }).filter((item) => !!item.value);
    MemberCard.showPhone =
      MemberCard.card.cardData.networkPhoneNumbers &&
      MemberCard.card.cardData.networkPhoneNumbers.length;
  };
}

app.component('memberCard', {
  bindings: {
    card: '<',
  },
  controller: MemberCardCtrl,
  controllerAs: 'MemberCard',
  templateUrl,
});

export default 'memberCard';
