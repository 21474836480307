import app from 'app';
import templateUrl from './sponsorSelector.html';
import './sponsorSelector.scss';

import { logEvent, eventCategories } from 'helpers/analytics';
import getPreferredName from 'helpers/getPreferredName';
import ROUTES from 'routes.json';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

function SponsorSelectorCtrl(FeatureFlagService) {
  const SponsorSelector = this;

  const client = FeatureFlagService.getLDClient();

  client.waitUntilReady().then(() => {
    const isPreferredNamesEnabled = getFFVariation(
      client,
      'isPreferredNamesEnabled',
    );

    if (isPreferredNamesEnabled) {
      SponsorSelector.isPreferredNamesEnabled = isPreferredNamesEnabled;
    }
  });

  SponsorSelector.changeSponsorship = function (sponsorship) {
    SponsorSelector.currentSponsorship = sponsorship;

    window?.location?.replace(
      `/${ROUTES.landing.react}?sponsorshipId=${sponsorship.id}`,
    );

    logEvent('sponsorshipChanged', {
      category: eventCategories.SETTINGS,
    });
    SponsorSelector.$close();
  };

  SponsorSelector.getSubscriberName = (sponsorship) => {
    return getPreferredName({
      person: sponsorship.subscriber,
      shouldGetPreferredName: SponsorSelector.isPreferredNamesEnabled,
    });
  };
}

app.component('sponsorSelector', {
  bindings: {
    $close: '<',
    allSponsorships: '<',
    currentSponsorship: '<',
  },
  controller: SponsorSelectorCtrl,
  controllerAs: 'SponsorSelector',
  templateUrl,
});

export default 'sponsorSelector';
