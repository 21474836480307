import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';
import ROUTES from '../routes.json';

export function checkInboxFeatureFlag(FeatureFlagService) {
  return new Promise((resolve, reject) => {
    const client = FeatureFlagService.getLDClient();

    client
      .waitUntilReady()
      .then(() => {
        const isFeatureFlagEnabled = getFFVariation(
          client,
          'isInboxMigrationEnabled',
        );

        resolve(isFeatureFlagEnabled);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function redirectReactInboxRoute() {
  const redirectRoutes = {
    [ROUTES.inbox.angular]: ROUTES.inbox.react,
    [ROUTES.suggestions.angular]: ROUTES.suggestions.react,
  };
  const queryParams = new URLSearchParams(window.location.search);
  const currentPath = window.location.pathname;
  // This route is very specific and we need to capture the ID only, therefore
  // we use a regex to capture the ID
  const singleRecommendationRegex = new RegExp(
    `${ROUTES.suggestions.angular}\\/([^/?]+)`,
  );

  // This route is very specific, since it uses url segments and not query params
  // which are not supported in Next
  if (
    currentPath.includes(ROUTES.suggestions.angular) &&
    singleRecommendationRegex.test(currentPath)
  ) {
    const match = currentPath.match(singleRecommendationRegex);

    // If the current path has a recommendationId, we need to redirect to the
    // react route with the recommendationId as a query param
    if (match && match[1]) {
      const recommendationId = match[1];

      window.location.replace(
        `${
          ROUTES.suggestions.react
        }?recommendationId=${recommendationId}&${queryParams.toString()}`,
      );
    } else {
      window.location.replace(
        `${ROUTES.suggestions.react}?${queryParams.toString()}`,
      );
    }

    return;
  }

  // Check if the current path has a part of the redirectRoutes in it
  const redirectPath = Object.keys(redirectRoutes).find((path) =>
    currentPath.includes(path),
  );

  if (redirectPath) {
    const newRoute = redirectRoutes[redirectPath];
    window.location.replace(`${newRoute}?${queryParams.toString()}`);
  }
}
