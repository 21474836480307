import app from 'app';

app.controller('AppCtrl', function (App, Posing, $window) {
  const AppCtrl = this;

  AppCtrl.getPageTitle = App.getPageTitle;
  AppCtrl.getPageId = App.getPageId;
  AppCtrl.isPosing = Posing.isPosing;

  AppCtrl.$onInit = function () {
    $window.addEventListener(
      'dragenter',
      function (e) {
        if (e.target.tagName !== 'INPUT') {
          e.preventDefault();
          e.dataTransfer.effectAllowed = 'none';
          e.dataTransfer.dropEffect = 'none';
        }
      },
      false
    );

    $window.addEventListener('dragover', function (e) {
      if (e.target.tagName !== 'INPUT') {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
      }
    });

    $window.addEventListener('drop', function (e) {
      if (e.target.tagName !== 'INPUT') {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
      }
    });
  };
});
