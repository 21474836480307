import app from 'app';
import _ from 'lodash';

// Pages.
import login from 'pages/loginPortal/login/login';
import resetPassword from 'pages/loginPortal/resetCredentials/resetPassword/resetPassword';
import legalAcceptance from 'pages/loginPortal/legalAcceptance/legalAcceptance';

app.config(($stateProvider) => {
  // Redirect to Member Portal "authed" pages if login credentials already set
  // Called during login state's resolve
  const redirectIfAuthed = [
    '$q',
    'Session',
    ($q, Session) => {
      if (Session.hasSession()) {
        return $q.reject(new Error('alreadyAuthenticated'));
      }
    },
  ];

  $stateProvider.state('Login', {
    url: 'login?loginEmail',
    parent: 'Base',
    params: {
      errorCode: '',
      resendVerificationType: '',
      resendEmail: '',
      loginEmail: '',
      passwordResetSuccess: false,
    },
    resolve: {
      redirectIfAuthed: redirectIfAuthed,
      errorCode: ($stateParams) => $stateParams.errorCode,
      resendVerificationType: ($stateParams) =>
        $stateParams.resendVerificationType,
      resendEmail: ($stateParams) => $stateParams.resendEmail,
      passwordResetSuccess: ($stateParams) => $stateParams.passwordResetSuccess,
    },
    views: {
      'content@Base': {
        component: login,
      },
    },
  });

  // Set new password
  $stateProvider.state('PasswordReset', {
    url: 'passwordReset?sptoken',
    parent: 'Base',
    views: {
      'content@Base': {
        component: resetPassword,
      },
    },
    resolve: {
      sptoken: function ($q, $stateParams, Api) {
        return Api.get(`/reset_password/${$stateParams.sptoken}`)
          .then(function () {
            return $stateParams.sptoken;
          })
          .catch((error) => {
            const e = new Error('passwordResetError');
            e.errorNumber = _.get(error, 'data.errorNumber');
            return $q.reject(e);
          });
      },
    },
  });

  /**
   * After login, if the user has not accepted legal contracts (Terms of service or privacy policy), they will be
   * directed here.
   */
  $stateProvider.state('LegalAcceptance', {
    url: 'accept?token',
    parent: 'Base',
    resolve: {
      token: ($stateParams) => $stateParams.token,
      legalCopies(FlLegalService, token) {
        return FlLegalService.getAccountLegalCopy(token);
      },
      legalTypes(legalCopies) {
        const hasTerms = !!legalCopies.terms;
        const hasPrivacy = !!legalCopies.privacy;
        const terms = 'Terms of Service';
        const privacy = 'Privacy Policy';

        if (hasTerms && hasPrivacy) {
          return `${terms} and ${privacy}`;
        }

        if (hasPrivacy) {
          return privacy;
        }

        return terms;
      },
      copyHtml(legalCopies) {
        return legalCopies.all;
      },
    },
    onEnter($state, token) {
      if (!token) {
        return $state.target('Login', { errorCode: 500 });
      }
    },
    views: {
      'content@Base': {
        component: legalAcceptance,
      },
    },
  });
});
