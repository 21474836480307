import app from 'app';
import _ from 'lodash';
import templateUrl from './sponsorLegal.html';
import './sponsorLegal.scss';

import MEMBER_PORTAL from 'constants/memberPortal';

function SponsorLegal() {
  const SponsorLegal = this;

  function getSponsorDocs(sponsor, docs) {
    return docs
      .filter((doc) => {
        const url = _.get(sponsor, [doc.expandKey, 'url']);
        if (!url) {
          return false;
        }

        return true;
      })
      .map((doc) => {
        const url = _.get(sponsor, [doc.expandKey, 'url']);
        return _.merge({ url }, doc);
      });
  }

  function getSummarySBCTitle(planType) {
    if (planType === 'medical') {
      return `Summary of Benefits and Coverage: ${_.capitalize(planType)}`;
    }

    return `${_.capitalize(planType)} Plan Summary`;
  }

  function getSummarySBCDescription(planType) {
    if (planType === 'medical') {
      return `This Summary of Benefits and Coverage summarizes your ${planType} benefits.`;
    }

    return `This ${_.capitalize(
      planType
    )} Plan Summary summarizes your ${planType} benefits.`;
  }

  function getSummarySPDTitle(planType) {
    return `Summary Plan Description: ${_.capitalize(planType)}`;
  }

  function getSummarySPDDescription(planType) {
    return `This Summary Plan Description outlines the benefits and rights you have under your ${planType} plan.`;
  }

  function getPlanDocs(activePlans, urlKey, getTitleFn, getDescriptionFn) {
    return _.reject(activePlans, { type: 'pharmacy' })
      .filter((plan) => {
        const url = _.get(plan, urlKey);
        if (!url) {
          return false;
        }
        return !!url;
      })
      .map((plan) => {
        const url = _.get(plan, urlKey);
        return {
          url,
          title: getTitleFn(plan.type),
          description: getDescriptionFn(plan.type),
        };
      });
  }

  SponsorLegal.$onInit = () => {
    SponsorLegal.documents = _.flatten([
      getPlanDocs(
        SponsorLegal.activePlans,
        'summaryOfBenefitsAndCoverage.url',
        getSummarySBCTitle,
        getSummarySBCDescription
      ),
      getPlanDocs(
        SponsorLegal.activePlans,
        'planDescription.url',
        getSummarySPDTitle,
        getSummarySPDDescription
      ),
      getSponsorDocs(
        SponsorLegal.currentSponsor,
        MEMBER_PORTAL.requiredSponsorDocs
      ),
      getSponsorDocs(SponsorLegal.currentSponsor, MEMBER_PORTAL.spdSponsorDocs),
      SponsorLegal.legalDocuments,
    ]);
  };
}

app.component('sponsorLegal', {
  bindings: {
    activePlans: '<',
    currentSponsor: '<',
    legalDocuments: '<',
  },
  controller: SponsorLegal,
  controllerAs: 'SponsorLegal',
  templateUrl,
});

export default 'sponsorLegal';
