import app from 'app';
import _ from 'lodash';
import decodeJwt from 'jwt-decode';

app.config(($stateProvider) => {
  // URL parsed to verify email containing verification token
  // and show user corresponding success/error message
  // In both cases, go to Login state, which will auto-redirect accordingly
  $stateProvider.state('verifyEmail', {
    parent: 'Base',
    url: 'verifyEmail?sptoken',
    resolve: {
      sptoken: ($stateParams) => $stateParams.sptoken,
    },
    onEnter: ($state, Registration, sptoken, Session) => {
      // Must have sptoken to continue
      if (_.isEmpty(sptoken)) {
        return $state.target('404');
      }

      return Registration.verifyEmail(sptoken)
        .then(() => {
          if (Session.hasSession()) {
            return $state.target('Landing', { showVerificationSuccess: true });
          }

          return $state.target('Login', {
            loginEmail: decodeJwt(sptoken).email,
          });
        })
        .catch((response) => {
          if (response && response.preVerified) {
            // User already logged in.
            return $state.target('Landing');
          }

          return $state.target('Login', { errorCode: 18 });
        });
    },
  });

  $stateProvider.state('resendVerify', {
    parent: 'Base',
    url: 'resend_verification?resendEmail',
    onEnter: ($state, $stateParams) =>
      $state.target(
        'Login',
        {
          resendVerificationType: 'resend',
          resendEmail: $stateParams.resendEmail,
        },
        { location: false },
      ),
  });

  $stateProvider.state('promptVerify', {
    parent: 'Base',
    url: 'prompt_verify_email?resendEmail',
    onEnter: ($state, $stateParams) =>
      $state.target(
        'Login',
        {
          resendVerificationType: 'prompt',
          resendEmail: $stateParams.resendEmail,
        },
        { location: false },
      ),
  });
});
