import app from 'app';
import templateUrl from './inboxTicket.html';
import './inboxTicket.scss';
import './inboxAvatar/inboxAvatar';
import { WELCOME_TICKET } from 'constants/inboxTicket';
import { getLocalTime } from 'helpers/timeHelper';

function InboxTicket() {
  const InboxTicket = this;

  InboxTicket.isWelcomeTicket = () => {
    return InboxTicket.ticket.id === WELCOME_TICKET.id;
  };

  InboxTicket.getComment = () => {
    const latestComment = getCustomField('LAST_COMMENT_FIELD');
    const comment = latestComment || InboxTicket.ticket.description || '';
    return comment.replace(/[_*#=\-]/gi, '');
  };

  InboxTicket.isRead = () => {
    // always unread welcome ticket
    if (InboxTicket.isWelcomeTicket()) return false;
    return !!getCustomField('READ_UNREAD_FIELD');
  };

  InboxTicket.getLocalTime = () => {
    return getLocalTime(InboxTicket.ticket.updated_at);
  };

  InboxTicket.getIconType = () => {
    if (!InboxTicket.isAgent()) {
      return 'person';
    } else if (InboxTicket.isWelcomeTicket()) {
      return 'ch';
    } else {
      return '';
    }
  };

  InboxTicket.getFullName = () => {
    const firstName = getCustomField('LAST_UPDATER_FIRST_NAME_FIELD');
    const lastName = getCustomField('LAST_UPDATER_LAST_NAME_FIELD');

    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    } else if (firstName) {
      return firstName;
    } else {
      return '';
    }
  };

  InboxTicket.isAgent = () => {
    const role = getCustomField('LAST_UPDATER_ROLE_FIELD');
    if (role) {
      const lowerCaseRole = role.toLowerCase();
      return lowerCaseRole === 'agent' || lowerCaseRole === 'admin';
    }

    return false;
  };

  InboxTicket.getFirstName = () => {
    return getCustomField('LAST_UPDATER_FIRST_NAME_FIELD');
  };

  function getCustomField(fieldTag) {
    if (InboxTicket.ticket.custom_fields) {
      const field = InboxTicket.ticket.custom_fields.find(
        (item) => item.tag === fieldTag,
      );
      return field && field.value;
    }
    return undefined;
  }
}

app.component('inboxTicket', {
  bindings: {
    ticket: '<',
  },
  controller: InboxTicket,
  controllerAs: 'InboxTicket',
  templateUrl,
});
