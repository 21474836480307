import app from 'app';
import templateUrl from './header.html';
import './header.scss';

// Sub-components.
import phoneMenu from './phoneMenu/phoneMenu';
import settingsMenu from './settingsMenu/settingsMenu';
import inboxMenu from './inboxMenu/inboxMenu';
import getRoute from 'helpers/getRoute';
import ROUTES from 'routes.json';

import './posingBanner/posingBanner';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chat';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-down';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-menu';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-person-single';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-envelope';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-bell';

import { logEvent } from 'helpers/analytics';

function HeaderCtrl(
  $state,
  $timeout,
  $scope,
  chModal,
  matchmedia,
  Session,
  Posing,
  Inbox,
  Sponsor,
  Recommendations,
) {
  const Header = this;
  Header.inboxData = {
    tickets: [],
    inboxUnreadCount: 0,
    recommendationsUnreadCount: 0,
  };

  function hasCards() {
    return Header.planMemberships && Header.planMemberships.hasCards;
  }

  Header.sponsorSelectorOptions = {
    template: `
      <div class="SponsorMenu hidden-xs hidden-sm"
          tabindex="-1"
          data-hook="sponsor-selector-menu">
        <div class="arrow"></div>
        <div class="SponsorMenu__header">
          Showing your benefits through
        </div>
        <sponsor-selector $close="$close"
            all-sponsorships="Header.allSponsorships"
            current-sponsorship="Header.currentSponsorship">
        </sponsor-selector>
      </div>
    `,
    attachment: 'top left',
    targetAttachment: 'bottom center',
    trigger: 'click',
    offset: '-10px 100px',
  };

  Header.inboxMenuOptions = {
    component: inboxMenu,
    attachment: 'top right',
    targetAttachment: 'bottom right',
    offset: '0 -15px',
    trigger: 'click',
    locals: {},
  };

  function updateDarkBackgroundFlag() {
    // dark background images are used with these pages: https://github.com/collectivehealth/frontend-web-member/blob/feat/aff18/apps/frontend-member-v2/src/pages/registration/registration.scss#L16-L18
    Header.isDarkBackground =
      $state.router.locationService.$location.$$path === '/register' ||
      $state.router.locationService.$location.$$path === '/register/confirm';
  }
  // this call is needed when the page is loaded in the very first time
  // since "$locationChangeSuccess" is not emitted.
  // e.g: "/register?regtoken=eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MzcxOTE0LCJlbWFpbCI6InRlc3QrdHVyYmluZV80ODA4MjkyNTFAY29sbGVjdGl2ZWhlYWx0aC5jb20iLCJkb21haW4iOiJteS50ZXN0aW5nLmNvbGxlY3RpdmVoZWFsdGguY29tIiwiZmlyc3ROYW1lIjoiQnVjayIsImxhc3ROYW1lIjoiS2VtbWVyIiwiZXhwIjoxNjQxNjY2ODQwfQ.Df_-rnjQa26Ci4D-wHWH0mmQhPKkyZmIsjIFr2bkgVQ"
  updateDarkBackgroundFlag();

  $scope.$on('$locationChangeSuccess', updateDarkBackgroundFlag);

  Header.$onChanges = function () {
    if (Header.currentUser) {
      Header.memberName = Header.currentUser.user.firstName;
      Header.sponsorName = Header.currentSponsorship.sponsor.name;
      Header.signOut = Session.logout;
      Header.isLoggedIn = Session.hasSession;
      Header.isPosing = Posing.isPosing;
      Header.showInbox = false;
      Header.isRecommendationsEnabled = false;
      Header.currentSponsorId = Header.currentSponsorship.sponsor.id;

      Sponsor.getSponsorZendeskSettings(
        Header.currentSponsorship.sponsor.id,
      ).then((response) => {
        Header.showInbox = response;
      });

      if (Header.isPosing || Header.isLoggedIn) {
        if (Header.shouldShowInbox) {
          getInboxUnreadCount();
          Inbox.subscribe(getInboxUnreadCount);
        }

        if (!Header.isOnPhone) {
          getRecommendationsUnreadCount();
        }
      }

      Header.inboxMenuOptions = {
        component: inboxMenu,
        attachment: 'top right',
        targetAttachment: 'bottom right',
        offset: '0 -15px',
        trigger: 'click',
        locals: {},
      };

      Header.settingsMenuOptions = {
        component: settingsMenu,
        attachment: 'top right',
        targetAttachment: 'bottom right',
        offset: '0 -15px',
        trigger: 'click',
        locals: {
          hasCards,
          allSponsorships: Header.allSponsorships,
          currentUser: Header.currentUser,
          currentSponsorship: Header.currentSponsorship,
          familyUnit: Header.familyUnit,
          isSubscriber: Header.isSubscriber,
          planMemberships: Header.planMemberships,
        },
      };
    }

    matchmedia.on('(max-width: 1023px)', (mql) => {
      Header.isOnPhone = mql.matches;
    });

    Header.$onDestroy = function () {
      Inbox.unsubscribe(getInboxUnreadCount);
    };

    function getInboxUnreadCount(data) {
      if (data && data.unreadCount !== 0) {
        $timeout(() => {
          Header.inboxUnreadCount = data.unreadCount;
        });
        return;
      }

      Inbox.getInboxData().then((data) => {
        Header.inboxUnreadCount = data.unreadCount;
      });
    }

    function getCurrentUrlPath() {
      const name = $state.current.name.toLowerCase();
      let pagePath = null;

      switch (name) {
        case 'landing':
          pagePath = 'home';
          break;
        case 'activity':
          pagePath = 'activity';
          break;
        case 'plan':
          pagePath = 'plans';
          break;
        case 'getcare':
          pagePath = 'getcare';
          break;
        case 'recommendationsinbox':
          pagePath = 'caresuggestions';
          break;
        default:
          pagePath = name;
      }
      return pagePath;
    }

    function sendRecNotificationEvent(unreadCount) {
      if (!unreadCount) return;

      const currentPath = getCurrentUrlPath();

      logEvent('viewRecNotification', {
        page: currentPath,
        unreadCount: unreadCount,
      });
    }

    function getRecommendationsUnreadCount(data) {
      const memberId = Header.currentUser.user.id;

      Recommendations.isRecommendationsEnabled().then((getCareEnabled) => {
        Header.isRecommendationsEnabled = getCareEnabled;

        if (getCareEnabled && memberId) {
          Recommendations.getUnreadRecommendationsCount(memberId).then(
            (data) => {
              Header.recommendationsUnreadCount = data.unreadCount;
              sendRecNotificationEvent(Header.recommendationsUnreadCount);
            },
          );
        }
      });
    }
  };

  Header.goToRecommendations = function () {
    $state.go('RecommendationsInbox', {
      source: 'recNotification',
    });
  };

  Header.openPhoneMenu = function () {
    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: phoneMenu,
      locals: {
        navLinks: Header.navLinks,
        hasCards,
        allSponsorships: Header.allSponsorships,
        currentUser: Header.currentUser,
        currentSponsorship: Header.currentSponsorship,
        familyUnit: Header.familyUnit,
        isSubscriber: Header.isSubscriber,
        planMemberships: Header.planMemberships,
        shouldShowInbox: Header.shouldShowInbox,
      },
    });
  };

  Header.hasMultipleSponsorships = function () {
    return (
      Header.allSponsorships && Header.allSponsorships.sponsorships.length > 1
    );
  };

  Header.getCurrentSponsorshipIdentifier = function () {
    if (Header.currentSponsorship) {
      return 'Subscriber: ' + Header.currentSponsorship.subscriber.firstName;
    }
  };

  Header.homeClick = function () {
    if (Header.isLoggedIn) {
      window?.location?.replace(`/${getRoute(ROUTES.landing.react)}`);
    } else {
      window?.location?.replace(`/${getRoute(ROUTES.loginPage)}`);
    }
  };
}

app.component('headerComponent', {
  bindings: {
    allSponsorships: '<',
    planMemberships: '<',
    currentSponsorship: '<',
    currentUser: '<',
    familyUnit: '<',
    isSubscriber: '<',
    navLinks: '<',
    shouldShowInbox: '<',
  },
  controller: HeaderCtrl,
  controllerAs: 'Header',
  templateUrl,
});

export default 'headerComponent';
