import app from 'app';
import _ from 'lodash';
import moment from 'moment';
import templateUrl from './familyInviteRead.html';
import './familyInviteRead.scss';

function FamilyInviteRead($q, Sponsor, User) {
  const FamilyInviteRead = this;

  FamilyInviteRead.isAuthorized = function (dependent) {
    return dependent.isFinancialAuthorized;
  };

  FamilyInviteRead.$onInit = () => {
    FamilyInviteRead.isSubscriber =
      FamilyInviteRead.currentUser.user.id ===
      FamilyInviteRead.currentSponsorship.subscriber.id;
    FamilyInviteRead.isSponsorAllowInvite =
      checkIfSponsorAllowInviteRegistration();

    FamilyInviteRead.update = '';

    if (FamilyInviteRead.isSubscriber) {
      $q.all({
        depsStatus: User.getFamilyUnitRegStatus(
          FamilyInviteRead.currentUser.user.id,
        ),
        designeeStatus: User.getFamilyDesignees(
          FamilyInviteRead.currentSponsorship.sponsor,
          FamilyInviteRead.currentUser.user,
        ),
      })
        .then((promises) => {
          const designeeIds = _.map(
            promises.designeeStatus,
            'authorizedDependentId',
          );
          return _.chain(
            FamilyInviteRead.planMemberships.nonSubscriberCoveredMembers,
          )
            .map((dep) =>
              _.set(
                dep,
                'regStatus',
                _.find(promises.depsStatus, { personId: dep.id }),
              ),
            )
            .map((dep) =>
              _.set(
                dep,
                'isFinancialAuthorized',
                _.includes(designeeIds, dep.id),
              ),
            )
            .sortBy('firstName')
            .value();
        })
        .then((deps) => {
          return deps.map((dep) =>
            _.set(
              dep,
              'isOver12',
              moment().diff(dep.dateOfBirth, 'years') > 12,
            ),
          );
        })
        .then((deps) => _.set(FamilyInviteRead, 'dependents', deps));
    }
  };

  //CH-46869 sponsor may not allow registration invite
  function checkIfSponsorAllowInviteRegistration() {
    const plans = FamilyInviteRead.planMemberships.current.filter(
      (membership) => !!membership.plan.shouldSendRegistrationEmail,
    );

    return plans.length > 0 || false;
  }
}

app.component('familyInviteRead', {
  bindings: {
    currentSponsorship: '<',
    currentUser: '<',
    planMemberships: '<',
  },
  controller: FamilyInviteRead,
  controllerAs: 'FamilyInviteRead',
  templateUrl: templateUrl,
});

export default 'familyInviteRead';
