import app from 'app';
import templateUrl from './landingPartners.html';
import './landingPartners.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-arrow-right';

function LandingPartners($window, PartnersService) {
  const LandingPartners = this;
  LandingPartners.hasPartners = false;

  LandingPartners.convertPartner = partner => {
    return Object.assign({}, partner, {
      user: LandingPartners.currentUser,
      sponsor: LandingPartners.currentSponsorship.sponsor
    });
  };

  LandingPartners.$onInit = function() {
    PartnersService.getLandingPagePartners(
      LandingPartners.currentSponsorship.id
    ).then(partners => {
      LandingPartners.partners = partners;
      LandingPartners.hasPartners = LandingPartners.partners.length > 0;
    });
  };

  LandingPartners.trackPartnerClick = function(partner) {
    logEvent('partnerDetailPageViewed', {
      category: eventCategories.PARTNERS,
      sponsorCode: LandingPartners.currentSponsorship.sponsor.sponsorCode,
      partnerName: partner.summary.title,
      page: 'home'
    });
  };

  LandingPartners.getBenefitsRoute = () =>
    `/get-care${$window.location.search}#partners`;}

app.component('landingPartners', {
  bindings: {
    currentUser: '<',
    currentSponsorship: '<'
  },
  controller: LandingPartners,
  controllerAs: 'LandingPartners',
  templateUrl
});
