import app from 'app';
import templateUrl from './mxTooltip.html';
import './mxTooltip.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-tooltip';

app.component('mxTooltip', {
  transclude: true,
  bindings: {
    popover: '<',
    mouseleave: '<?',
  },
  controllerAs: 'MxTooltip',
  templateUrl,
});

export default 'mxTooltip';
