import app from 'app';
import { logEvent, eventCategories } from 'helpers/analytics';
import templateUrl from './businessHours.html';
import './businessHours.scss';

function BusinessHours(TimeService) {
  const BusinessHours = this;
  BusinessHours.isExpand = false;
  BusinessHours.data = {};

  BusinessHours.$onInit = () => {
    TimeService.getBusinessHours().then(data => (BusinessHours.data = data));
  };

  BusinessHours.toggleExpansion = () => {
    BusinessHours.isExpand = !BusinessHours.isExpand;
    if (BusinessHours.isExpand) {
      logEvent('contactButtonClicked', {
        category: eventCategories.HELP,
        location: 'footer'
      });
    }
  };
}

app.component('businessHours', {
  bindings: {},
  controller: BusinessHours,
  controllerAs: 'BusinessHours',
  templateUrl
});
