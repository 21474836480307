import app from 'app';

app.service('UnsubscribeService', (Request) => {
  // Returns {
  //   emailNewOffers: <boolean>,
  //   emailBenefitsUpdate: <boolean>,
  //   emailProgramPartnerUpdate: <boolean>,
  //   emailResearchRequest: <boolean>
  // }
  function getEmailSettings(token) {
    return Request.req({
      method: 'GET',
      url: '/api/v1/membercoremiddleware/settings/email',
      headers: {
        'CH-Unsubscribe-Token': token,
      },
    });
  }

  // Returns 204 if successful
  function updateEmailSettings(token, emailSettings) {
    return Request.req({
      method: 'PUT',
      url: '/api/v1/membercoremiddleware/settings/email',
      data: emailSettings,
      header: {
        'CH-Unsubscribe-Token': token,
      },
    });
  }

  return {
    getEmailSettings,
    updateEmailSettings,
  };
});
