import FundAccount from './FundAccount';

export default class FundsGroup {
  constructor(group) {
    this.group = group;

    const { spendingAccounts = [] } = group;
    this.accounts = spendingAccounts.map((account) => new FundAccount(account));
  }

  get header() {
    return this.group.header;
  }

  get subheader() {
    return this.group.subheader;
  }

  get hasAccountWithSso() {
    return this.accounts.filter((account) => account.hasSso).length > 0;
  }
}
