import app from 'app';
import templateUrl from './safariOutage.html';
import './safariOutage.scss';

function safariOutageCtrl($element) {
  const safariOutage = this;
  safariOutage.$onInit = () => {
    $element.addClass('PageNotFound__host');
  };
}

app.component('safariOutage', {
  controller: safariOutageCtrl,
  controllerAs: 'safariOutage',
  templateUrl,
});

export default 'safariOutage';
