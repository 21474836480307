import app from 'app';
import templateUrl from './dismissableToast.html';
import './dismissableToast.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-close';

function DismissableToast() {
  const DismissableToast = this;

  DismissableToast.dismiss = function () {
    DismissableToast.show = false;
  };
}

app.component('dismissableToast', {
  bindings: {
    level: '@',
    show: '=',
  },
  transclude: true,
  controllerAs: 'DismissableToast',
  controller: DismissableToast,
  templateUrl,
});
