import app from 'app';
import MemberCob from 'models/cob/MemberCob';

app.service('CobService', (Api) => {
  function getCob(person) {
    return Api.req({
      endPoint: `/cob_eligibility/memberStatus/${person.id}`,
      method: 'GET',
    })
      .catch(() => {
        return {}; // Catch error and treat as empty state
      })
      .then((cob) => new MemberCob(cob));
  }

  function updateCob(person, status) {
    return Api.req({
      endPoint: `/cob_eligibility/memberStatus/${person.id}`,
      method: 'POST',
      data: { status },
    }).catch(() => {
      return {};
    });
  }

  return {
    getCob,
    updateCob,
  };
});
