import { isOverAge } from 'helpers/age';

export default class User {
  constructor(user) {
    this.user = user;
    const dependents = this.user.dependents || [];

    this.dependentModels = dependents.map((dependent) => new User(dependent));
  }

  get id() {
    return this.user.id;
  }

  /*
    @deprecated
    CH-49198 - addresses sometime does not contain preferred address
  */
  get addresses() {
    return this.user.addresses;
  }

  get preferredAddress() {
    return this.user.preferredAddress || this.user.mailingAddress;
  }

  get userIdentifier() {
    return this.user.userIdentifier;
  }

  get firstName() {
    return this.user.firstName;
  }

  get dateOfBirth() {
    return this.user.dateOfBirth;
  }

  get isOver13() {
    return isOverAge(this.dateOfBirth, 13);
  }

  get dependents() {
    return this.dependentModels;
  }

  get dependentsOver13() {
    return this.dependents.filter((dependent) => dependent.isOver13);
  }

  get hasDependents() {
    return this.dependents.length > 0;
  }

  get hasDependentsOver13() {
    return this.dependentsOver13.length > 0;
  }
}
