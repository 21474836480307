import app from 'app';

import templateUrl from './fractionAccumulator.html';
import './fractionAccumulator.scss';

app.directive('fractionAccumulator', () => {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      accumulator: '=fractionAccumulator',
    },
    templateUrl,
  };
});

export default 'fractionAccumulator';
