import appendQueryParam from './appendQueryParam';
import ROUTES from '../routes.json';

/**
 * Base utility for routes
 */
export default function getRoute(url) {
  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const { sponsorshipId, redirectTo, patientId, claimId, sponsorId } =
    queryParams;

  if (claimId) {
    url = appendQueryParam(url, 'claimId', claimId);
  }

  if (redirectTo) {
    url = appendQueryParam(url, 'redirectTo', redirectTo);
  }

  if (url.includes(ROUTES.activity)) {
    const patientIdParam = patientId ? patientId : '0';
    url = appendQueryParam(url, 'patientId', patientIdParam);
  }

  if (sponsorshipId) {
    url = appendQueryParam(url, 'sponsorshipId', sponsorshipId);
  }

  if (sponsorId) {
    url = appendQueryParam(url, 'sponsorId', sponsorId);
  }

  return url;
}
