import app from 'app';
import templateUrl from './balanceBilling.html';
import './balanceBilling.scss';

app.component('balanceBilling', {
  bindings: {
    $close: '<',
  },
  templateUrl,
});

export default 'balanceBilling';
