import app from 'app';
import sounds from 'assets/sounds';

app.factory('Sounds', ($q, $window) => {
  function loadSound(name) {
    return $q((resolve, reject) => {
      if (!$window.Audio) {
        return reject(new Error('The Audio API is not supported.'));
      }

      const sound = new Audio();

      if (!sounds[name]) {
        reject(new Error(`Sound "${name}" does not exist.`));
      } else if (!sound) {
        reject(new Error('The Audio API is not supported.'));
      } else {
        sound.addEventListener('loadeddata', () => {
          resolve(sound);
        });

        sound.addEventListener('error', (error) => {
          reject(error);
        });

        sound.preload = 'auto';
        sound.src = sounds[name];
      }
    });
  }

  return {
    loadSound,
  };
});
