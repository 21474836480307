import Card from './Card';
import CardsForPeriod from './CardsForPeriod';

import CARDS_PERIOD from 'constants/cardsPeriod';

export default class MemberCards {
  constructor(cards) {
    this[CARDS_PERIOD.CURRENT] = new CardsForPeriod();
    this[CARDS_PERIOD.FUTURE] = new CardsForPeriod();

    Object.values(CARDS_PERIOD).forEach((period) => {
      this[period] = new CardsForPeriod();
      cards[period].forEach((card) => this[period].add(new Card(card)));
    });
  }

  get hasCurrent() {
    return this[CARDS_PERIOD.CURRENT].hasCards;
  }

  get hasFuture() {
    return this[CARDS_PERIOD.FUTURE].hasCards;
  }

  get hasCurrentAndFuture() {
    return this.hasCurrent && this.hasFuture;
  }
}
