import app from 'app';
import angular from 'angular';
import jQuery from 'jquery';

/**
 * chScrollOffset
 *
 * This directive works just like ngClass, but publishes $scrollOffsetY and
 * $scrollOffsetX variables onto it's scope for use in expressions.
 *
 * @example
 * <div ch-scroll-offset="{'some__awesome--class': $scrollOffsetY > 50 || vm.addSomeAwesomeClass}"></div>
 */
app.directive('chScrollOffset', ($parse, $window) => {
  return {
    restrict: 'A',
    link(scope, element, attributes) {
      const windowEl = angular.element($window);

      const onScroll = () => {
        // Update scope variables.
        scope.$scrollOffsetY = jQuery(window).scrollTop();
        scope.$scrollOffsetX = jQuery(window).scrollLeft();

        // Parse options.
        const options = $parse(attributes.chScrollOffset)(scope);

        // Apply or remove classes.
        angular.forEach(options, (shouldApply, className) => {
          if (shouldApply) {
            element.addClass(className);
          } else {
            element.removeClass(className);
          }
        });
      };

      // Add event listener.
      windowEl.on('scroll', onScroll);

      // Remvove event listener.
      scope.$on('$destroy', () => {
        windowEl.off('scroll', onScroll);
      });
    },
  };
});

export default 'chScrollOffset';
