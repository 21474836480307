import app from 'app';
import templateUrl from './mxPopover.html';
import './mxPopover.scss';

app.component('mxPopover', {
  bindings: {
    content: '<',
  },
  controllerAs: 'MxPopover',
  templateUrl,
});

export default 'mxPopover';
