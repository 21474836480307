// todo: refactor this to include the featureFlagService instead of passing it in
export default function getPreferredName({
  person,
  includeLastName = false,
  shouldGetPreferredName = false,
}) {
  let name = shouldGetPreferredName
    ? person?.preferredName || person?.firstName
    : person?.firstName;

  if (includeLastName && (!person?.preferredName || !shouldGetPreferredName)) {
    name = `${name} ${person?.lastName}`;
  }

  return name;
}
