import _ from 'lodash';
import app from 'app';
import moment from 'moment';
import R from 'ramda';

import CLAIM_TYPE_TO_PLAN_TYPE from 'constants/claimTypes';

import {
  capitalizeWords,
  startCase,
  parseAddress,
} from 'helpers/stringParsers';

app.factory('Claims', ($log, $q, Api, phiCacheFactory) => {
  const cache = phiCacheFactory('Claims');

  // List of expands for claim-related API calls.
  const claimExpands = [
    'claimDescription',
    'claimLines',
    'displayProvider',
    'parentClaim',
    'patient',
    'planMembership',
    'remarkCodes',
  ];

  // ----- Private Methods -----------------------------------------------------

  /**
   * Maps the provided claim's claimType field to a plan type (medical, dental,
   * vision, pharmacy).
   *
   * @param  {string} claim - Claim.
   * @return {string} - Plan type.
   */
  function getClaimsPlanType(claim) {
    return CLAIM_TYPE_TO_PLAN_TYPE[claim.claimType];
  }

  /**
   * I have no idea what this does. If you (yes, you!) do, please document this
   * function.
   *
   * @param  {object} claimLine
   * @return {object}
   */
  function filterRemarkCodes(claimLine) {
    return Object.assign(claimLine, {
      remarkCodes: _.reject(claimLine.remarkCodes, (remarkCode) => {
        return !!['2', '49', '300', '301'].includes(remarkCode.id);
      }),
    });
  }

  /**
   * Annotates the provided claim with various fields, and filters its claim
   * lines.
   *
   * @param  {object} claim
   * @return {object}
   */
  function parseClaim(claim) {
    let displaySummary = claim.claimDescription;

    /**
     * This encapsultes the "new" client-side claim description display logic
     * (also implemented by mobile).
     *
     * See: https://kb.collectivehealth.com/pages/viewpage.action?pageId=5927366#iOSActivityPRD(v1.2)-ClaimsAPIV2ClientLogic
     */
    if (R.path(['displayProvider', 'name'], claim)) {
      displaySummary += ` at ${capitalizeWords(claim.displayProvider.name)}`;
    } else if (
      R.path(['displayProvider', 'address', 'streetAddressLine1'], claim)
    ) {
      displaySummary += ` at ${startCase(
        claim.displayProvider.address.streetAddressLine1
      )}`;
    }

    return Object.assign(claim, {
      claimLines: R.map(filterRemarkCodes, R.pathOr([], ['claimLines'], claim)),
      date: moment(claim.dateOfServiceStart, 'YYYY-MM-DD'),
      displaySummary,
      planType: getClaimsPlanType(claim),
      providerAddress: parseAddress(
        R.pathOr({}, ['displayProvider', 'address'], claim)
      ),
    });
  }

  function cacheClaim(claim) {
    cache.put(claim.id, $q.when(claim));
    return claim;
  }

  // ----- Public Methods ------------------------------------------------------

  /**
   * Fetches a claim from the cache or API.
   *
   * @param  {string|number} claimId - ID of the claim to fetch.
   * @return {promise}               - Promise that resolves with claim data.
   */
  function getClaim(claimId) {
    if (!claimId) {
      return $q.reject(new Error('[getClaim] Expected at least 1 argument.'));
    }

    if (!cache.get(claimId)) {
      return Api.req({
        apiPrefix: '/api/v2',
        endPoint: `/claim/${claimId}`,
        cache: true,
        params: {
          expand: claimExpands,
        },
      })
        .then(parseClaim)
        .then(cacheClaim)
        .catch((error) => {
          switch (error.status) {
            case 403:
            case 404:
            case 500:
              return $q.reject(new Error('claimNotFound'));
            default:
              return $q.reject(error);
          }
        });
    }

    return cache.get(claimId);
  }

  /**
   * Fetches a range of claim data from the API for a specific member.
   *
   * @param  {string|number} personId - ID of the member to fetch claims for.
   * @param  {object} [params]        - Optional query string parameters.
   * @return {promise}                - Promise that resolves with the API
   * response.
   */
  function getClaims(personId, params) {
    if (!personId) {
      return $q.reject(new Error('[getClaims] Expected at least 1 argument.'));
    }

    params = Object.assign(
      {
        expand: claimExpands,
        order: 'desc',
        orderBy: 'dateOfServiceStart',
      },
      params
    );

    // http://apidocs.cchh.io/api/#!/Claim/get_person_personId_claim
    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: `/person/${personId}/claim`,
      cache: true,
      params: params,
    }).then((resp) => {
      return {
        count: resp.count,
        claims: resp.data.map(parseClaim).map(cacheClaim),
      };
    });
  }

  /**
   * Queries the API for the AWS URL for the EOB PDF corresponding to a claim.
   *
   * @param  {string|number} claimId - The ID of the claim.
   * @return {promise}               - Resolves with the response object.
   */
  function getEobLink(claimId) {
    return Api.req({
      endPoint: `/claim/${claimId}/download`,
      cache: true,
    });
  }

  function getPlanPaid({
    personId,
    sponsorship,
    sponsorshipPeriod,
    planType,
    patientId,
  }) {
    return Api.req({
      endPoint: `/person/${personId}/claims/plan_paid`,
      cache: true,
      params: {
        subscriberId: sponsorship.subscriber.id,
        sponsorId: sponsorship.sponsor.id,
        planType,
        patientId,
        dateOfServiceFrom: sponsorshipPeriod.startDate,
        dateOfServiceTo: sponsorshipPeriod.endDate,
      },
    });
  }

  return {
    getClaim,
    getClaims,
    getEobLink,
    getPlanPaid,
  };
});
