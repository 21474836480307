import app from 'app';
import templateUrl from './phoneMenu.html';
import './phoneMenu.scss';
import { unsetAmplitudeUser } from 'helpers/analytics';
import getPreferredName from 'helpers/getPreferredName';

import 'components/footer/footer';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-close';

import NAVIGATION from 'constants/navigation';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

function PhoneMenuCtrl(
  $state,
  App,
  Cards,
  matchmedia,
  Session,
  Recommendations,
  SubmitClaimService,
  FeatureFlagService,
) {
  const PhoneMenu = this;

  PhoneMenu.$onChanges = () => {
    PhoneMenu.isRecommendationsEnabled = false;

    Recommendations.isRecommendationsEnabled().then((getCareEnabled) => {
      PhoneMenu.isRecommendationsEnabled = getCareEnabled;
    });
  };

  PhoneMenu.$onInit = () => {
    if (PhoneMenu.currentUser) {
      // Close the menu when transitioning out of phone breakpoint.
      matchmedia.onTablet((mediaQueryList) => {
        if (mediaQueryList.matches) {
          PhoneMenu.$close();
        }
      });

      const client = FeatureFlagService.getLDClient();

      client.waitUntilReady().then(() => {
        const isPreferredNamesEnabled = getFFVariation(
          client,
          'isPreferredNamesEnabled',
        );

        PhoneMenu.memberName = getPreferredName({
          person: PhoneMenu.currentUser.user,
          includeLastName: true,
          shouldGetPreferredName: isPreferredNamesEnabled,
        });
      });
    }

    PhoneMenu.cardsUrl =
      '/landing?sponsorId=' +
      PhoneMenu.currentSponsorship.sponsor.id +
      '#cards';

    PhoneMenu.isSubscriber =
      PhoneMenu.currentUser.user.id ===
      PhoneMenu.currentSponsorship.subscriber.id;

    PhoneMenu.activeMedical =
      PhoneMenu.currentSponsorship.activePlanTypes.includes('MEDICAL');

    PhoneMenu.mostRecentPastPlan =
      SubmitClaimService.getMostRecentPlanOfEachType(
        PhoneMenu.planMemberships.past,
      );

    const client = FeatureFlagService.getLDClient();

    PhoneMenu.showPaymentMethodButton = false;

    client.waitUntilReady().then(() => {
      // initialization succeeded, flag values are now available

      PhoneMenu.showPaymentMethodButton =
        PhoneMenu.isSubscriber &&
        (PhoneMenu.activeMedical || PhoneMenu.mostRecentPastPlan?.MEDICAL);
    });
  };

  PhoneMenu.paymentClick = function () {
    PhoneMenu.$close();
    if (window !== undefined) {
      window.location = '/activity#tipalti';
    }
  };

  PhoneMenu.signOut = function () {
    PhoneMenu.$close();
    unsetAmplitudeUser();
    Session.logout();
  };

  PhoneMenu.goToRecommendations = function () {
    $state.go('RecommendationsInbox', {
      source: NAVIGATION.HOME.toLowerCase(),
    });
    PhoneMenu.$close();
  };

  PhoneMenu.isRecommendationsActive = function () {
    return $state.includes('RecommendationsInbox');
  };

  PhoneMenu.hasMultipleSponsorships = function () {
    return (
      PhoneMenu.allSponsorships &&
      PhoneMenu.allSponsorships.sponsorships.length > 1
    );
  };

  PhoneMenu.getCurrentSponsorshipIdentifier = function () {
    if (PhoneMenu.currentSponsorship) {
      return 'Subscriber: ' + PhoneMenu.currentSponsorship.subscriber.firstName;
    }
  };

  PhoneMenu.isSettingsActive = function () {
    return $state.includes('Settings');
  };

  PhoneMenu.isInboxActive = function () {
    return $state.includes('Inbox');
  };

  PhoneMenu.viewCards = () => {
    PhoneMenu.$close();

    Cards.openModal({
      currentUser: PhoneMenu.currentUser,
      currentSponsorship: PhoneMenu.currentSponsorship,
    });
  };
}

app.component('phoneMenu', {
  bindings: {
    navLinks: '<',
    hasCards: '<',
    currentUser: '<',
    allSponsorships: '<',
    currentSponsorship: '<',
    shouldShowInbox: '<',
    $close: '<',
    isSubscriber: '<',
    planMemberships: '<',
  },
  controller: PhoneMenuCtrl,
  controllerAs: 'PhoneMenu',
  templateUrl,
});

export default 'phoneMenu';
