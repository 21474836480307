import app from 'app';
import getCommaDelimitedList from 'helpers/commaDelimitedList';

function CommaDelimitedList() {
  const CommaDelimitedList = this;

  CommaDelimitedList.$onInit = function () {
    CommaDelimitedList.parsedList = getCommaDelimitedList(
      CommaDelimitedList.list
    );
  };
}

app.component('commaDelimitedList', {
  bindings: {
    list: '<',
  },
  controller: CommaDelimitedList,
  controllerAs: 'CommaDelimitedList',
  template:
    '<span ng-repeat="item in CommaDelimitedList.parsedList track by $index">{{ item }}</span>',
});
