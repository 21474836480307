import app from 'app';
import moment from 'moment';

/**
 * Date Validator
 *
 * Adds a parser to ngModel that accepts an optional input format. The parser
 * validates the input using moment, then returns an ISO date string if the
 * moment is valid. If not, it sets the "date" validation key to false.
 *
 * @example
 *
 * Require the user to enter dates like "2015-07-16":
 *
 * <input type="text"
 *   ng-model="vm.date"
 *   date-validator>
 *
 * @example
 *
 * Require the user to enter dates like "07/16/2015":
 *
 * <input type="text"
 *   ng-model="vm.date"
 *   date-validator="MM/DD/YYYY">
 */
app.directive('dateValidator', () => {
  const ISO_DATE_FORMAT = 'YYYY-MM-DD';

  return {
    restrict: 'A',
    scope: false,
    require: 'ngModel',
    link: function (scope, element, attributes, ngModelCtrl) {
      const INPUT_FORMAT = attributes.dateValidator || ISO_DATE_FORMAT;

      ngModelCtrl.$parsers.push((viewValue) => {
        // Create a new moment in strict mode.
        const parsedViewValue = moment(viewValue, INPUT_FORMAT, true);

        // Set the validity of the model controller based on the validity of the moment.
        ngModelCtrl.$setValidity('date', parsedViewValue.isValid());

        // If the moment is valid, return an ISO-8601 date string.
        return parsedViewValue.isValid()
          ? parsedViewValue.format(ISO_DATE_FORMAT)
          : null;
      });
    },
  };
});
