import app from 'app';
import templateUrl from './expandButton.html';
import './expandButton.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-down';

function ExpandButton() {
  const ExpandButton = this;

  ExpandButton.$onInit = function () {
    ExpandButton.expandedText =
      ExpandButton.expandedText || ExpandButton.collapsedText;
  };

  ExpandButton.toggle = function () {
    ExpandButton.expanded = !ExpandButton.expanded;
    ExpandButton.click();
  };
}

app.component('expandButton', {
  bindings: {
    collapsedText: '@',
    expandedText: '@',
    expanded: '<',
    click: '&',
  },
  controller: ExpandButton,
  controllerAs: 'ExpandButton',
  templateUrl,
});
