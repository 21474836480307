import app from 'app';
import { getEnv, isProd } from 'helpers/checkEnv';
import * as LDClient from 'launchdarkly-js-client-sdk';
import {
  LAUNCHDARKLY_SDK_CLIENT_SIDE_IDS,
  LAUNCHDARKLY_CONTEXT_KEYS,
} from '@collectivehealth/mx-feature-flags/src/utils';

app.factory('FeatureFlagService', ($q, $state, Api, Raven) => {
  // dictionary of keys used in HTML to acutal functions that figure out the state
  // of the flag. Im doing this so as this file grows and there are portential function
  // name collisions we can change them easily without needing to change the keys spread
  // around the code. The key in the map is random since all that matters is its global
  // uniqueness so making it a random hash is just one less name to need to come up with.
  // My thinking behind this complexity is that it this file could quickly grow and all the
  // methods for determining flag state will not be contained in one place. This is also useful
  // so in the future when this map is build serverside and sent as JSON the keys aren't
  // connected to JS method names.
  const flagDefinitions = {
    affiliatesPartnerDisclaimers: true,
    loginForgotEmail: false,
  };

  let env = getEnv();
  if (env === 'local') {
    env = 'testing';
  }
  const client = LDClient.initialize(LAUNCHDARKLY_SDK_CLIENT_SIDE_IDS[env], {
    kind: 'user',
    key: LAUNCHDARKLY_CONTEXT_KEYS.memberPortal,
  });

  /**
   * Looks up flag method and calls it.
   * @param  {string} key A string matching a key in the keyToFunctionMap object
   * @return {promise}    Returns a promise containing a boolean
   */

  function getFeatureFlagStatus(key) {
    if (typeof flagDefinitions[key] === 'function') {
      return $q.when(Reflect.apply(flagDefinitions[key], undefined, []));
    } else {
      return $q.when(flagDefinitions[key]);
    }
  }

  function disabledOnProd() {
    if (isProd()) {
      $state.go('404', {});
    }
  }

  function isFeatureToggleEnabled(
    toggle,
    errorMsg = `Error checking ${toggle} availability'`,
  ) {
    const isAnonymous = toggle.includes('anonymous') ? '/anonymous' : '';

    return Api.req({
      apiPrefix: 'api/membercoremiddleware/v2',
      endPoint: `/app_config${isAnonymous}?key=${toggle}`,
      method: 'GET',
      cache: true,
    })
      .then(({ configurations }) => {
        const value = configurations[toggle];
        return value && value.toLowerCase() === 'true';
      })
      .catch(() => {
        Raven.captureMessage(errorMsg, {
          extra: error,
        });
        return false;
      });
  }

  function getLDClient() {
    return client;
  }

  function getOtherEnvVariables() {
    // Keep in mind to set-up this during the compile process, so that webpack
    // knows how/what to change during compile.
    //
    // Check the `./webpack.config.babel.js` file to understand how this
    // is injected into the code source
    return {
      MIGRATION_LEGAL_PAGES: process.env.MIGRATION_LEGAL_PAGES,
    };
  }

  return {
    getFeatureFlagStatus,
    disabledOnProd,
    isFeatureToggleEnabled,
    getLDClient,
    getOtherEnvVariables,
  };
});
