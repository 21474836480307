import CobPlan from './CobPlan';

export default class MemberCob {
  constructor(cob) {
    this.cob = cob;
    if (this.cob.planMemberships && this.cob.planMemberships.length) {
      this.cob.planMemberships = this.cob.planMemberships.map(
        (p) => new CobPlan(p)
      );
    } else {
      this.cob.planMemberships = [];
    }
  }

  get status() {
    return this.cob.status;
  }

  set status(status) {
    if (status) {
      this.cob.status = status;
    }
  }

  get hasCob() {
    return this.status === 'YES';
  }

  get doesNotHaveCob() {
    return this.status === 'NO';
  }

  get hasUnknownCobStatus() {
    return this.status === 'UNKNOWN';
  }

  get isPending() {
    return this.cob.isPending;
  }

  set isPending(isPending) {
    this.cob.isPending = isPending;
  }

  get showCobQuestion() {
    return this.hasUnknownCobStatus && this.cob.isAtleast18Years;
  }

  get showToMember() {
    return this.cob.isAtleast18Years || this.hasCob;
  }

  get hasCobEditRights() {
    return this.cob.isAtleast18Years;
  }

  get docusignUrl() {
    return this.cob.docusignUrl;
  }

  set docusignUrl(docusignUrl) {
    if (docusignUrl) {
      this.cob.docusignUrl = docusignUrl;
    }
  }

  get planMemberships() {
    return this.cob.planMemberships;
  }

  updateMemberCob(newCob) {
    const { isPending, status, docusignUrl } = newCob;
    Object.assign(this, { isPending, status, docusignUrl });
  }
}
