import app from 'app';
import partnerModal from 'components/modals/partnerModal/partnerModal';

/**
 * chAction
 *
 * Accepts as its parameter an action object and applies the appropriate
 * attriutes to its element to enable it to perform that action. An action
 * object should have a 'type', and a 'value'. Supported types are:
 *
 * url: Expects "value" to be a valid URL, applies an "href" attribute to the
 * element. Will open in a new tab/window.
 *
 * state: Expects "value" to be a an object with "name" and "params" keys.
 *
 * modal: Expects "value" to be an object with a "name" corresponding to a valid
 * injectable that returns a function that will be invoked with "params".
 * This should usually be a wrapper around Alerts that configures one of its
 * methods using "params".
 *
 * sso: Expects "value" to be a valid partner slug -- "pharmacy-cvs", for
 * example. This action type requires additional context; use the "data-user-id"
 * and "data-sponsor-id" attributes on the same element as chAction to ensure
 * SSO links are generated correctly.
 *
 * @example (Action objects)
 * {
 *   title: "Lean More",
 *   type: "url",
 *   value: "http://google.com"
 * }
 *
 * {
 *   title": "Learn More",
 *   type: "state",
 *   value: {
 *     name: "drfinder",
 *     params: {
 *       profession: "pediatrics",
 *       network: "blueshield",
 *       zip: "94118"
 *     }
 *   }
 * }
 *
 * {
 *   title": "Learn More",
 *   type: "modal",
 *   value: {
 *     name: "PartnerModal",
 *     params: {
 *       partnerId: "healthcarebluebook"
 *     }
 *   }
 * }
 *
 * {
 *   title": "Sign In",
 *   type: "sso",
 *   value: "pharmacy-cvs"
 * }
 *
 * @example (Usage with SSO)
 *
 * <a href="#"
 *   ch-action="some.action"
 *   data-person-id="vm.user.id"
 *   data-sponsor-id="vm.sponsor.id">
 *   {{::some.action.title}}
 * </a>
 */
app.directive(
  'chAction',
  ($log, $state, chModal, Raven, SSOService, GetCare, Logger) => {
    return {
      restrict: 'A',
      replace: false,
      bindToController: {
        currentUser: '<',
        currentSponsorship: '<',
        action: '<chAction',
      },
      controller($attrs, $scope, $element) {
        const ChAction = this;

        ChAction.performSsoAction = function () {
          const params = {
            personId: ChAction.currentUser.user.id,
            sponsorId: ChAction.currentSponsorship.sponsor.id,
            partnerSlug: ChAction.action.value,
          };

          SSOService.getPartnerSsoData(params)
            .then((ssoData) => SSOService.openSsoLink(ssoData, {}))
            .catch((error) => {
              $log.error('prepare sso error', error);
              Logger.captureApiError(
                'partner sso data',
                params,
                error.response.data
              );
            });
        };

        /**
         * @private
         *
         * Performs a state transition, optionally passing state params.
         */
        // function doStateAction(action) {
        //   $state.go(action.type, action.params, action.options);
        // }

        /**
         * @private
         *
         * Opens a modal. TODO: Consider creating a modal registry for commonly
         * used modals and their locals, and refactoring this to interface with
         * the registry.
         */
        function doModalAction() {
          chModal.open({
            closeOnClick: true,
            closeOnEsc: true,
            component: partnerModal,
            locals: {
              partner: ChAction.action,
              currentUser: ChAction.currentUser,
              currentSponsorship: ChAction.currentSponsorship,
            },
          });
        }

        /**
         * NOTE: THIS IS LEGACY NEEDS TO BE REMOVED
         * In order to run the correct search based on the information passed by the partners json,
         * we have to override or manipulate some of the values. Also, we need to make sure we clear
         * out query parameters that aren't set by the JSON. If we don't unset these query parameters,
         * we end up in states where the wrong search is being run.
         *
         * @param {object} params
         * @return {object} mapped parameters
         */
        function getCareParams({
          lat,
          lng,
          specialtyIds,
          providerName,
          providerId,
          locationQuery,
        }) {
          const mappedParams = {
            specialtyId: specialtyIds,
            providerName,
            providerId,
          };

          // Use location query if provided, or clear it out if the search center is changed
          if (locationQuery || lat || lng) {
            mappedParams.locationQuery = locationQuery;
          }

          if (lat) {
            mappedParams.lat = lat;
          }

          if (lng) {
            mappedParams.lng = lng;
          }

          return mappedParams;
        }

        /**
         * @private
         *
         * Performs a state transition, optionally passing state params.
         * It should not be possible currently to have more than one action for this type
         * If this should change, this will need to be reworked
         */
        function doStateAction(action) {
          $state.go(
            GetCare.mapResults.stateName,
            getCareParams(action.params),
            {
              reload: 'Auth',
            }
          );
        }

        /**
         * Sets up appropriate attributes or click handlers based on action type.
         */
        ChAction.$onInit = () => {
          $element.css('visibility', 'hidden');
          // This is to bind the correct click action to the element the directive is applied to
          if (ChAction.action && ChAction.action.display) {
            $element.css('cursor', 'pointer');

            const display = ChAction.action.display;

            const getCareActions = display.actions.filter((action) => {
              return action.type === 'getCare';
            });

            if (getCareActions.length > 0) {
              $element.bind(
                'click',
                doStateAction.bind(null, getCareActions[0])
              );
            } else {
              $element.bind('click', doModalAction.bind(null));
            }
          } else {
            if (ChAction.action.type === 'url') {
              $attrs.$set('href', ChAction.action.value);
              $attrs.$set('target', '_blank');
            } else if (ChAction.action.type === 'sso') {
              $element.bind('click', ChAction.performSsoAction);
            } else if (ChAction.action.type === 'state') {
              $element.bind('click', doStateAction.bind(null, ChAction.action));
            }
          }
          $element.css('visibility', 'visible');
        };

        ChAction.$onDestroy = () => {
          $element.unbind('click', ChAction.performSsoAction);
        };
      },
    };
  }
);
