export default {
  contributionAmounts: [
    {
      canonicalName: 'individual',
      key: 'member',
      label: 'Just You',
      planKey: 'contributionMember',
    },
    {
      canonicalName: 'spouse',
      key: 'spouse',
      label: 'You + Partner',
      planKey: 'contributionSpouse',
    },
    {
      canonicalName: 'children',
      key: 'children',
      label: 'You + Kids',
      planKey: 'contributionChildren',
    },
    {
      canonicalName: 'spouse_children',
      key: 'family',
      label: 'You + Partner + Kids',
      planKey: 'contributionFamily',
    },
  ],
  contributionPeriod: {
    BIWEEKLY: 'pay period',
    'Bi-weekly': 'pay period',
    'Per Pay Period': 'pay period',
    MONTHLY: 'month',
    Monthly: 'month',
  },
  OUT_OF_NETWORK: 'Out-of-network',
};
