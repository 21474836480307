import app from 'app';
import _ from 'lodash';

// Pages
import landing from 'pages/landing/landing';
import printCards from 'pages/printCards/printCards';
import sponsorLegal from 'pages/sponsorLegal/sponsorLegal';
import posing from 'components/header/posingBanner/posingBanner';
import zendeskInboxComponent from 'pages/zendeskInbox/zendeskInbox';
import headerComponent from 'components/header/header';
import CARDS_PERIOD from 'constants/cardsPeriod';
import ROUTES from './routes.json';
import getRoute from 'helpers/getRoute';

import { initializeAmplitude } from './helpers/analytics';
import { setAmplitudeUser } from 'helpers/analytics';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';
import {
  checkInboxFeatureFlag,
  redirectReactInboxRoute,
} from 'helpers/inboxMigration';
import appendQueryParam from 'helpers/appendQueryParam';

// page components
import 'pages/settings/familyAccessRead/familyAccessRead';
import familyInviteRead from 'pages/settings/familyInviteRead/familyInviteRead';
import familyInviteEdit from 'pages/settings/familyInviteEdit/familyInviteEdit';

import mxConfirmationModal from 'components/modals/mxConfirmationModal/mxConfirmationModal';
// import MemberCob from 'models/cob/MemberCob';

app.config(($stateProvider) => {
  // Abstract base state that ensures that child states are protected
  // as well as provides named states for header and footer
  $stateProvider.state('Auth', {
    abstract: true,
    parent: 'Base',
    url: '?sponsorshipId&loginEmail&sponsorId',
    params: {
      showVerificationSuccess: false,
    },
    resolve: {
      sponsorId: (Sponsor) => {
        return Sponsor.getCurrentSponsorship()?.sponsor?.id || undefined;
      },
      currentUser: (User) => User.getUser(),
      // unused currentUser to prevent this from calling unless user is logged in.
      // fix the linking issue
      // eslint-disable-next-line no-unused-vars
      allSponsorships: (Sponsor, currentUser) => {
        return Sponsor.getSponsorships();
      },
      sponsorshipId: (allSponsorships, $stateParams) => {
        return (
          parseInt($stateParams.sponsorshipId, 10) ||
          allSponsorships.sponsorships[0].id
        );
      },
      currentSponsorship: (Sponsor, allSponsorships, sponsorshipId) => {
        Sponsor.setCurrentSponsorship(allSponsorships, sponsorshipId);
        return Sponsor.getCurrentSponsorship();
      },
      planMemberships: (PlanMembership, sponsorshipId) => {
        return PlanMembership.getPlanMemberships(sponsorshipId);
      },
      currentSponsor: (currentSponsorship) => {
        return currentSponsorship.sponsor;
      },
      isSubscriber: (currentUser, currentSponsorship) => {
        return currentUser.user.id === currentSponsorship.subscriber.id;
      },
      shouldShowInbox: (Sponsor, currentSponsor, allSponsorships) => {
        const sponsorIds = allSponsorships.sponsorships.map(
          (sponsorship) => sponsorship.sponsor.id,
        );
        return Sponsor.getSponsorZendeskSettings(sponsorIds);
      },
      isSettingPageEnabled: (FeatureFlagService) => {
        const client = FeatureFlagService.getLDClient();
        return client.waitUntilReady().then(() => {
          const showBothSettingsPages = getFFVariation(
            client,
            'showBothSettingsPages',
          );

          if (showBothSettingsPages) {
            return false;
          }
          return getFFVariation(client, 'isSettingPageRevampEnabled');
        });
      },
      navLinks: (planMemberships, NavLinks) => {
        return NavLinks.getNavLinks(planMemberships);
      },
      familyUnit: (planMemberships) => {
        return planMemberships.coveredMembers;
      },
      showVerificationSuccess: ($stateParams) =>
        $stateParams.showVerificationSuccess,
    },
    onEnter: (
      currentUser,
      $state,
      chModal,
      Idle,
      showVerificationSuccess,
      currentSponsorship,
      Posing,
      Chat,
    ) => {
      // If Idle timer is not running when Auth state is entered, start it
      if (!Idle.running()) {
        Idle.watch();
      }

      Chat.initiateChat();

      if (showVerificationSuccess) {
        chModal
          .open({
            component: mxConfirmationModal,
            locals: {
              header: 'Success!',
              body: 'Your email has been changed',
              confirm: 'close',
            },
          })
          .on('close', () => {
            $state.go($state.current, { showVerificationSuccess: false });
          });
      }

      if (Posing.isPosing()) {
        (async () => {
          await initializeAmplitude();
        })();
      }

      setAmplitudeUser(currentUser.user.userIdentifier, {
        activePlanTypes: currentSponsorship.activePlanTypes,
        selectedSponsorName: currentSponsorship.sponsor.name,
        selectedSponsorCode: currentSponsorship.sponsor.sponsorCode,
      });
    },
    views: {
      'header@Base': {
        component: headerComponent,
      },
      'posing@Base': {
        component: posing,
      },
    },
  });

  $stateProvider.state('Landing', {
    url: '',
    parent: 'Auth',
    resolve: {
      /*
      cob(CobService, currentUser, planMemberships) {
        // TODO: future iterations of COB backend API will handle this requirement for us
        // The “Yes”/No question will only appear to members 18 and up
        // with a COB status of undefined who have an active CH administered Medical plan
        if (!planMemberships.hasCurrentOrFutureMedicalPlanMembership) {
          return new MemberCob({
            status: 'NO',
            isPending: false
          });
        }

        // Otherwise, proceed as usual
        return CobService.getCob(currentUser.user);
      }
      */
    },
    onEnter(FeatureFlagService) {
      {
        /*

        Commenting out this feature toggle check and redirect to the react
        landing page until we get the e2e tests passing and merged into main
        https://collectivehealth.atlassian.net/browse/MX-3240

      */
      }
      //TODO: replace the /landing route with just / when working on this ticket to remove the angular landing page
      // https://collectivehealth.atlassian.net/browse/YODA-824
      window.location.replace(getRoute(`/${ROUTES.landing.react}`));
    },
    views: {
      'content@Base': {
        component: landing,
      },
    },
  });

  $stateProvider.state('SponsorLegal', {
    url: 'legal',
    parent: 'Auth',
    resolve: {
      activePlans(PlanDetails, currentSponsorship) {
        return PlanDetails.getPlanDetails(currentSponsorship.id);
      },
      legalDocuments(User, currentSponsorship) {
        return User.getUserLegalInfo(currentSponsorship.id);
      },
    },
    views: {
      'content@Base': {
        component: sponsorLegal,
      },
    },
  });

  $stateProvider.state('PrintCards', {
    url: 'printcards?selectedUserId&cardPeriod',
    parent: 'Auth',
    resolve: {
      selectedUserId: ($stateParams) =>
        parseInt($stateParams.selectedUserId, 10),
      selectedUser: (planMemberships, selectedUserId) =>
        planMemberships.getCoveredMember(selectedUserId),
      cardPeriod: ($stateParams) => $stateParams.cardPeriod,
      cardsForPeriod(Cards, selectedUserId, currentSponsorship, cardPeriod) {
        return Cards.getCardsByMember(
          currentSponsorship.id,
          selectedUserId,
        ).then((memberCards) => {
          if (memberCards.hasCurrentAndFuture) {
            return memberCards[cardPeriod];
          }

          if (memberCards.hasFuture) {
            return memberCards[CARDS_PERIOD.FUTURE];
          }

          return memberCards[CARDS_PERIOD.CURRENT];
        });
      },
    },
    views: {
      '@': {
        component: printCards,
      },
    },
  });

  $stateProvider.state('FundsSso', {
    url: `${ROUTES.fundsSso.angular}?spendingAccountId&partnerId`,
    parent: 'Auth',
    resolve: {
      spendingAccountId: ($stateParams) => $stateParams.spendingAccountId,
      partnerId: ($stateParams) => $stateParams.partnerId,
      isFundsSsoMigrationEnabled: (FeatureFlagService) => {
        const client = FeatureFlagService.getLDClient();
        return client.waitUntilReady().then(() => {
          return getFFVariation(client, 'isFundsSsoMigrationEnabled');
        });
      },
    },
    onEnter(
      currentSponsorship,
      partnerId,
      spendingAccountId,
      SpendingAccountsService,
      isFundsSsoMigrationEnabled,
    ) {
      if (isFundsSsoMigrationEnabled) {
        let reactUrl = `/${ROUTES.fundsSso.react}`;
        reactUrl = appendQueryParam(reactUrl, 'partnerId', partnerId);
        reactUrl = appendQueryParam(
          reactUrl,
          'spendingAccountId',
          spendingAccountId,
        );
        window.location.replace(reactUrl);
      } else {
        SpendingAccountsService.ssoIntoProductPartner(
          currentSponsorship.sponsor.id,
          spendingAccountId,
          partnerId,
        );
      }
    },
    views: {
      '@': {
        template: '', // We show a blank screen because once we redirect it goes to a blank screen anyways
      },
    },
  });

  $stateProvider.state('Inbox', {
    url: 'inbox-v1?ticketId&messageUuid&categoryCompose&sponsorshipId&sponsorId',
    parent: 'Auth',
    resolve: {
      sponsorshipId: (allSponsorships, $stateParams) => {
        return (
          parseInt($stateParams.sponsorshipId, 10) ||
          allSponsorships.sponsorships[0].id
        );
      },
      sponsorId: (Sponsor, $stateParams) => {
        return (
          Sponsor.getCurrentSponsorship().sponsor.id || $stateParams.sponsorId
        );
      },
      userIdentifier(User) {
        return User.getUser().then((data) => {
          return data.user.userIdentifier || '';
        });
      },
      ticketId($stateParams) {
        return $stateParams.ticketId || null;
      },
      categoryCompose($stateParams, userIdentifier) {
        return (
          !!$stateParams.categoryCompose &&
          $stateParams.messageUuid === userIdentifier
        );
      },
      isRecommendationsEnabled: (Recommendations) => {
        return Recommendations.isRecommendationsEnabled();
      },
      goToCompose() {
        return false;
      },
    },
    onEnter($state, shouldShowInbox, Sponsor, FeatureFlagService) {
      if (!shouldShowInbox) {
        $state.go('Landing');
      }

      checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
        if (ffEnabled) {
          redirectReactInboxRoute();
        } else {
          let queryParams = new URLSearchParams(window.location.search);

          if (!queryParams.get('sponsorId')) {
            queryParams.set(
              'sponsorId',
              Sponsor.getCurrentSponsorship().sponsor.id,
            );
            window.location.replace(`/inbox?${queryParams.toString()}`);
          }
        }
      });
    },
    views: {
      'content@Base': {
        component: zendeskInboxComponent,
      },
    },
  });

  $stateProvider.state('InboxCompose', {
    url: 'inbox-v1/compose',
    parent: 'Auth',
    resolve: {
      goToCompose() {
        return true;
      },
      isRecommendationsEnabled: (Recommendations) => {
        return Recommendations.isRecommendationsEnabled();
      },
    },
    onEnter($state, shouldShowInbox) {
      if (!shouldShowInbox) {
        $state.go('Landing');
      }

      checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
        if (ffEnabled) {
          redirectReactInboxRoute();
        }
      });
    },
    views: {
      'content@Base': {
        component: zendeskInboxComponent,
      },
    },
  });

  $stateProvider.state('RecommendationsInbox', {
    url: 'suggestions-v1?source',
    parent: 'Auth',
    resolve: {
      userIdentifier(User) {
        return User.getUser().then((data) => {
          return data.user.userIdentifier || '';
        });
      },
      isRecommendationsEnabled: (Recommendations) => {
        return Recommendations.isRecommendationsEnabled();
      },
      recommendationId() {
        return null;
      },
      recommendationsInbox($stateParams) {
        return true;
      },
      source($stateParams) {
        return $stateParams.source || null;
      },
    },
    onEnter(Sponsor, FeatureFlagService) {
      checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
        if (ffEnabled) {
          redirectReactInboxRoute();
        } else {
          let queryParams = new URLSearchParams(window.location.search);
          if (!queryParams.get('sponsorId')) {
            queryParams.set(
              'sponsorId',
              Sponsor.getCurrentSponsorship().sponsor.id,
            );
            window.location.replace(`/suggestions?${queryParams.toString()}`);
          }
        }
      });
    },
    views: {
      'content@Base': {
        component: zendeskInboxComponent,
      },
    },
  });

  $stateProvider.state('RecommendationsSingle', {
    url: 'suggestions-v1/:recommendationId?source',
    parent: 'Auth',
    resolve: {
      userIdentifier(User) {
        return User.getUser().then((data) => {
          return data.user.userIdentifier || '';
        });
      },
      isRecommendationsEnabled: (Recommendations) => {
        return Recommendations.isRecommendationsEnabled();
      },
      recommendationId($stateParams) {
        return $stateParams.recommendationId || null;
      },
      recommendationsInbox($stateParams) {
        return true;
      },
      source($stateParams) {
        return $stateParams.source || null;
      },
    },
    onEnter($state, isRecommendationsEnabled, Sponsor, FeatureFlagService) {
      if (!isRecommendationsEnabled) {
        $state.go('404', {}, { location: false });
      }

      checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
        if (ffEnabled) {
          redirectReactInboxRoute();
        } else {
          let queryParams = new URLSearchParams(window.location.search);
          if (!queryParams.get('sponsorId')) {
            let urlPath = window.location.pathname;
            queryParams.set(
              'sponsorId',
              Sponsor.getCurrentSponsorship().sponsor.id,
            );
            window.location.replace(`${urlPath}?${queryParams.toString()}`);
          }
        }
      });
    },
    views: {
      'content@Base': {
        component: zendeskInboxComponent,
      },
    },
  });

  // email link
  // redirects:
  // - /message
  // - /messages
  // - /link/messages
  // to:
  // /inbox

  $stateProvider.state('MessagesRedirect', {
    url: 'messages-v1',
    parent: 'Auth',
    onEnter($state) {
      $state.go('Inbox');
    },
  });
  $stateProvider.state('MessageRedirect', {
    url: 'message-v1',
    parent: 'Auth',
    onEnter($state) {
      $state.go('Inbox');
    },
  });
  $stateProvider.state('LinkMessagesRedirect', {
    url: 'link-v1/messages',
    parent: 'Auth',
    onEnter($state) {
      $state.go('Inbox');
    },
  });

  //inbox alias with email ticket linking
  $stateProvider.state('MessagesCompose', {
    url: 'message-v1/compose/:ticketId/:uuid',
    parent: 'Auth',
    onEnter($state, $stateParams) {
      $state.go('Inbox', {
        ticketId: $stateParams.ticketId,
        messageUuid: $stateParams.uuid,
        categoryCompose: true,
      });
    },
  });
});
