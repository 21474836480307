import moment from 'moment';

export function isOverAge(dateOfBirth, ageInYears) {
  return moment(dateOfBirth).isBefore(moment().subtract(ageInYears, 'years'));
}

export function isUnderAge(dateOfBirth, ageInYears) {
  return moment(dateOfBirth).isAfter(moment().subtract(ageInYears, 'years'));
}

export function isBetweenAges(
  dateOfBirth,
  lowerBoundAgeInYears,
  upperBoundAgeInYears
) {
  return (
    isOverAge(dateOfBirth, lowerBoundAgeInYears) &&
    isUnderAge(dateOfBirth, upperBoundAgeInYears)
  );
}

export default {
  isOverAge,
  isUnderAge,
  isBetweenAges,
};
