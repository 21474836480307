import app from 'app';
import { getUserIdentifier } from 'helpers/userIdentifier';

app.factory('ApiMCM', (Request, Posing, User) => {
  const baseURL = '/api/v1/membercoremiddleware';
  const service = {};

  service.req = (options, headers) => {
    const userIdentifier = getUserIdentifier();

    if (Posing.isPosing() || !userIdentifier) {
      return User.getUser().then((user) => {
        return makeRequest(
          options,
          Object.assign(
            {
              'ch-user-identifier': user.userIdentifier,
            },
            headers
          )
        );
      });
    } else {
      return makeRequest(
        options,
        Object.assign(
          {
            'ch-user-identifier': userIdentifier,
          },
          headers
        )
      );
    }
  };

  service.get = (url, params) => {
    return service.req({ method: 'GET', url, params });
  };

  function makeRequest(options, headers) {
    const opts = Object.assign(
      {
        baseURL,
        headers,
      },
      options
    );
    return Request.req(opts);
  }

  return service;
});
