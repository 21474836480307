import app from 'app';
import templateUrl from './inboxTicketSkeleton.html';
import './inboxTicketSkeleton.scss';

function InboxTicketSkeleton() {}

app.component('inboxTicketSkeleton', {
  bindings: {},
  controller: InboxTicketSkeleton,
  controllerAs: 'InboxTicketSkeleton',
  templateUrl,
});
