import app from 'app';

/**
 * Prevents scroll events on this element from proliferating to other elements.
 *
 * Example usage:
 *
 * <ul style="overflow-y: auto;" ch-prevent-window-scroll>
 *   <li ng-repeat="thing in lotsOfThings"></li>
 * </ul>
 */
app.directive('chPreventWindowScroll', () => ({
  restrict: 'A',
  link(scope, element) {
    element.bind('DOMMouseScroll mousewheel onmousewheel', function (event) {
      const delta = event.originalEvent.deltaY;

      if (delta < 0 && this.scrollTop === 0) {
        // Hit the top
        event.preventDefault();
      } else if (
        delta > 0 &&
        this.scrollTop === element.get(0).scrollHeight - element.height()
      ) {
        // Hit the bottom
        event.preventDefault();
      }
    });
  },
}));
