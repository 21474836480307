import SUBDOMAINS from './subdomains.json';

const envs = [
  'prod',
  'staging',
  'testing',
  'demo',
  'dev',
  'crm-training',
  'eligibility-pit',
  'eligibility-dev-pit',
  'perf',
];

export function getEnv(subdomains = SUBDOMAINS) {
  let env;
  for (let i = 0; i < envs.length; i++) {
    env = envs[i];
    if (isHost(env, subdomains)) {
      return env;
    }
  }
  return isLocal(subdomains) ? 'local' : null;
}

function getHostUrl(subdomain, env) {
  return `${subdomain}.${env !== 'prod' ? env + '.' : ''}collectivehealth.com`;
}

function isHost(env, subdomains = SUBDOMAINS) {
  if (typeof window === 'undefined') {
    return null;
  }

  return subdomains.some((subdomain) =>
    window?.location?.host?.includes(getHostUrl(subdomain, env)),
  );
}

export function isProd(subdomains = SUBDOMAINS) {
  return isHost('prod', subdomains);
}

export function isLocal(subdomains = SUBDOMAINS) {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    isHost('local', subdomains) ||
    window?.location?.host?.includes('local.local.collectivehealth.com')
  );
}

export function isTesting(subdomains = SUBDOMAINS) {
  return isHost('testing', subdomains);
}
