import app from 'app';
import templateUrl from './newFeatureBanner.html';
import './newFeatureBanner.scss';

function NewFeatureBannerCtrl() {
  const NewFeatureBanner = this;
  NewFeatureBanner.shouldShow = false;

  NewFeatureBanner.$onInit = () => {
    if (NewFeatureBanner.endDate) {
      const today = new Date();
      const endDate = new Date(NewFeatureBanner.endDate);
      NewFeatureBanner.shouldShow = today < endDate;
    }
  };
}

app.component('newFeatureBanner', {
  bindings: {
    endDate: '@',
  },
  transclude: true,
  controller: NewFeatureBannerCtrl,
  controllerAs: 'NewFeatureBanner',
  templateUrl,
});

export default 'newFeatureBanner';
