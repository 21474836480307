import app from 'app';

/**
 * Adds the maxlength attribute to input fields created using
 * Formation.
 */
app.directive('mxFmMaxlength', () => {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      element.find('input').attr('maxlength', attrs.mxFmMaxlength);
    },
  };
});
