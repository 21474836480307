import app from 'app';
import templateUrl from './mxModal.html';
import './mxModal.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-close';

/**
 * Standard component for creating modals in Member Portal.
 *
 * This component uses multiple transclusion to allow for including content
 * in the modal. An example of using this would be
 *
 * <mx-modal>
 *
 *   <mx-modal-header>
 *     INSERT HEADER CONTENT HERE
 *     <img src="some/icon/you/want/in/the/header.png">
 *   </mx-modal-header>
 *
 *  <mx-modal-toast>
 *    <mx-toast level="error">
 *      This optional toast can be toggled in the consumer.
 *      It should never be styled by this component at all.
 *    </mx-toast>
 *  </mx-modal-toast>
 *
 *   <mx-modal-body>
 *     INSERT BODY CONTENT HERE
 *     <button>Button that does things and appears in the body</button>
 *   </mx-modal-body>
 *
 * </mx-modal>
 *
 * Any content can be used in the transcluded content.
 *
 * A good article on multiple transclusion:
 * http://www.codelord.net/2016/03/04/using-angular-1-dot-5-s-multiple-transclusion-slots/
 *
 * It also allows for easy addition of a close button (x in the top right corner) by binding a
 * close function. Adding analytics tracking to the close button is also supported but is totally optional.
 *
 * <mx-modal close="Ctrl.close()">
 *  ...
 * </mx-modal>
 *
 * or with analytics:
 *
 * <mx-modal close="Ctrl.close()"
 *    close-analytics-on="click"
 *    close-analytics-category="Example"
 *    close-analytics-event="Expample-Event">
 *  ...
 * </mx-modal>
 */
app.component('mxModal', {
  // All bindings optional so that they are undefined if not provided
  bindings: {
    close: '&?',
    closeAnalyticsOn: '@?',
    closeAnalyticsCategory: '@?',
    closeAnalyticsEvent: '@?',
  },
  transclude: {
    body: '?mxModalBody',
    header: '?mxModalHeader',
    toast: '?mxModalToast',
  },
  controllerAs: 'MxModal',
  templateUrl,
});

export default 'mxModal';
