import app from 'app';
import templateUrl from './landingPlans.html';
import './landingPlans.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-arrow-right';

function LandingPlans() {
  const LandingPlans = this;

  LandingPlans.$onInit = function() {
    LandingPlans.plans = getPlans();
  };

  LandingPlans.trackViewPlan = function(type) {
    logEvent('planClicked', {
      category: eventCategories.HOME,
      planType: type
    });
  };

  function getPlans() {
    const plans =
      LandingPlans.planMemberships.currentNonPharmacyPlans.length > 0
        ? LandingPlans.planMemberships.currentNonPharmacyPlans
        : LandingPlans.planMemberships.futureNonPharmacyPlans;

    return plans.map(plan =>
      Object.assign(plan, { planType: plan.planType.toLowerCase() })
    );
  }
}

app.component('landingPlans', {
  bindings: {
    planMemberships: '<'
  },
  controller: LandingPlans,
  controllerAs: 'LandingPlans',
  templateUrl
});
