import app from 'app';
import templateUrl from './dateField.html';
import moment from 'moment';

const PARSED_FORMAT = 'YYYY-MM-DD';

function DateField() {
  const DateField = this;

  DateField.$onInit = function () {
    DateField.format = DateField.format || 'MM/DD/YYYY';
    DateField.placeholder = DateField.placeholder || DateField.format;

    DateField.config = {
      validators: {
        format: (val) => val,
      },
      errors: [['format', `Please enter as ${DateField.format}.`]],
      parsers: [
        (val) => {
          const parsed = moment(val, DateField.format, true);
          return parsed.isValid() && parsed.format(PARSED_FORMAT);
        },
      ],
      formatters: [
        (val) =>
          val && moment(val, PARSED_FORMAT, true).format(DateField.format),
      ],
    };
  };
}

/**
 * format is optional and defaults to 'MM/DD/YYYY'
 * placeholder is optional and defaults to the format
 */
app.component('dateField', {
  bindings: {
    format: '@',
    name: '@',
    placeholder: '@',
  },
  controller: DateField,
  controllerAs: 'DateField',
  transclude: true,
  templateUrl,
});

export default 'dateField';
