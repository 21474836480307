import FundsGroup from './FundsGroup';

export default class MemberFunds {
  constructor(funds) {
    this.funds = funds;

    const { groups = [] } = funds;
    this.groups = groups.map((group) => new FundsGroup(group));
  }

  get hasFundsEducation() {
    return this.funds.hasFundsEducation || false;
  }

  get hasFunds() {
    return this.groups.length > 0;
  }

  get hasGroupWithSso() {
    return this.groups.filter((group) => group.hasAccountWithSso).length > 0;
  }

  hasGroupFromYear(year) {
    for (let i = 0; i < this.groups.length; i++) {
      for (let j = 0; j < this.groups[i].accounts.length; j++) {
        if (this.groups[i].accounts[j].groupYear === year) {
          return true;
        }
      }
    }

    return false;
  }
}
