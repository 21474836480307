import app from 'app';
import _ from 'lodash';

function PhiAuthorization($q, Api) {
  function getAuthorizations(params) {
    if (!params) {
      return $q.reject({
        data: {
          errorMessage: 'getAuthorizations requires params.',
        },
      });
    } else if (!params.grantorId && !params.granteeId) {
      return $q.reject({
        data: {
          errorMessage:
            'getAuthorizations requires a grantorId and/or a granteeId.',
        },
      });
    }

    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: '/phi_authorization',
      method: 'GET',
      params: params,
    });
  }

  /**
   * Gets phi authorization level granted by a dependent to the primary.
   * Currently users can only edit the authorizations they have granted to the primary.
   * Therefore, we only show authorizations that dependents have granted to the primary,
   * and don't show anything to the primary since.
   *
   * This function is not used to get all the authorizations given to the primary when the
   * primary is logged in. That should use the basic getAuthorizations function.
   *
   * @return {promise} resolves to granted auth or rejects is logged in user is the primary
   */
  function getAuthorizationsGrantedByLoggedInDependentToPrimary(
    currentSponsorship,
    currentUser
  ) {
    return getAuthorizations({
      granteeId: currentSponsorship.subscriber.id,
      grantorId: currentUser.user.id,
    }).then((auths) => _.sortBy(auths.phiAuthorizations, 'grantee.firstName'));
  }

  function putAuthorizations(data) {
    if (!data || !data.phiLevel || !data.phiAuthorizationId) {
      return $q.reject({
        data: {
          errorMessage:
            'putAuthorizations requires phiAuthorizationId and phiLevel.',
        },
      });
    }
    const reqestPayload = _.pick(data, ['phiLevel']);

    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: `/phi_authorization/${data.phiAuthorizationId}`,
      method: 'PUT',
      data: reqestPayload,
    });
  }

  function postAuthorizations(data) {
    if (!data || !data.phiLevel || !data.grantorId || !data.granteeId) {
      return $q.reject({
        data: {
          errorMessage:
            'postAuthorizations requires data.phiLevel, data.grantorId, data.granteeId.',
        },
      });
    }

    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: '/phi_authorization',
      method: 'POST',
      data: data,
    });
  }

  return {
    getAuthorizations,
    getAuthorizationsGrantedByLoggedInDependentToPrimary,
    postAuthorizations,
    putAuthorizations,
  };
}

app.service('PhiAuthorization', PhiAuthorization);
