import app from 'app';
import templateUrl from './mxChevronButton.html';
import './mxChevronButton.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-left';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-right';

app.component('mxChevronButton', {
  transclude: true,
  bindings: {
    back: '<',
    click: '&',
  },
  controllerAs: 'MxChevronButton',
  templateUrl,
});

export default 'mxChevronButton';
