import app from 'app';
import './subNav.scss';
import templateUrl from './subNav.html';

/**
 * Sub Navigation component.
 *
 * Accepts an array of objects representing links. Each object may have the
 * following properties:
 *
 * @param {string} name - Text to use for the link.
 * @param {string} sref - Text to use for the ui-sref attribute.
 * @param {string} [srefOpts] - Optional ui-sref-opts attribute.
 * @param {string} [classString] - Optional additional class(es) to apply to
 *   each link.
 */

app.component('subNav', {
  bindings: {
    links: '<',
    clickLink: '<?',
  },
  controllerAs: 'SubNav',
  templateUrl,
});

export default 'subNav';
