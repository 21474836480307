import app from 'app';
import templateUrl from './costAndCoverage.html';
import './costAndCoverage.scss';

import COST_AND_COVERAGE from 'constants/costAndCoverage';

function CostAndCoverageCtrl() {
  const CostAndCoverage = this;

  CostAndCoverage.$onInit = () => {
    CostAndCoverage.members = CostAndCoverage.coveredMembers.map(
      (member) => member.firstName
    );
    CostAndCoverage.oonLabel = COST_AND_COVERAGE.OUT_OF_NETWORK;
  };
}

app.component('costAndCoverage', {
  bindings: {
    coveredMembers: '<',
    contributions: '<',
    supportsOon: '<',
    sponsorshipId: '<',
    plan: '<',
    viewCards: '&',
  },
  controller: CostAndCoverageCtrl,
  controllerAs: 'CostAndCoverage',
  templateUrl,
});

export default 'costAndCoverage';
