import app from 'app';
import _ from 'lodash';
import templateUrl from './benefitOverviewDental.html';
import './benefitOverviewDental.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

function DentalBenefitsCtrl(chModal) {
  const DentalBenefits = this;

  DentalBenefits.$onInit = () => {
    logEvent('planViewed', {
      category: eventCategories.PLANS,
      planType: 'dental'
    });
    // Determine if any of the benefit detail examples in the benefit groups
    // provided to this component have a supportsOon field set to true.
    DentalBenefits.hasOonBenefitDetailExamples = _.matches([
      { benefits: [{ details: { examples: [{ supportsOon: true }] } }] }
    ])(DentalBenefits.planBenefitGroups);
  };

  DentalBenefits.showBalanceBillingModal = () => {
    chModal.open({
      closeOnEsc: true,
      closeOnClick: true,
      component: 'balanceBilling',
      locals: {
        pageSource: 'planPage'
      }
    });
  };
}

app.component('benefitOverviewDental', {
  bindings: {
    planBenefitGroups: '<'
  },
  controller: DentalBenefitsCtrl,
  controllerAs: 'DentalBenefits',
  templateUrl
});

export default 'benefitOverviewDental';
