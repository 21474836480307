import app from 'app';
import _ from 'lodash';
import moment from 'moment';
import templateUrl from './familyInviteEdit.html';
import './familyInviteEdit.scss';

import { logEvent, eventCategories } from 'helpers/analytics';
import { COLLECTIVE_TITLE } from '../../../collectiveTitle';

function FamilyInviteEdit(
  $q,
  $state,
  $stateParams,
  animatedScrollTop,
  Sponsor,
  User
) {
  const FamilyInviteEdit = this;
  FamilyInviteEdit.COLLECTIVE_TITLE = COLLECTIVE_TITLE;
  function analyticsTrackHelper(event, success) {
    let label;

    if (success) {
      switch (event) {
        case 'Plan-Designee':
          label = FamilyInviteEdit.isFinancialAuthorized ? 'Setup' : 'Removal';
          break;
        case 'Dependent-Invite':
        default:
          label = 'Successs';
          break;
      }
    } else {
      label = 'Error';
    }

    logEvent('familyInviteEdited', {
      category: eventCategories.SETTINGS,
      financialLevelChange: label.toLowerCase()
    });
  }

  // registration & financial auth have their own success/catches, in addition to the general $q.all() then/catch
  // b/c they have their own distinct success/error handling behaviors
  function handleRegistrationResponse(promise) {
    return promise
      .then(res => {
        analyticsTrackHelper('Dependent-Invite', true);
        return res;
      })
      .catch(err => {
        analyticsTrackHelper('Dependent-Invite');
        return $q.reject(err);
      });
  }

  function handleFinancialAuthResponse(promise) {
    return promise
      .then(resp => {
        // update dependent status with newly saved data, b/c we want correct behavior on any subsequent saves
        FamilyInviteEdit.dependent.financialAuthorization = resp;
        analyticsTrackHelper('Plan-Designee', true);

        return resp;
      })
      .catch(err => {
        analyticsTrackHelper('Plan-Designee');

        return $q.reject(err);
      });
  }

  FamilyInviteEdit.toast = {};

  FamilyInviteEdit.submitForm = function() {
    const promises = {};
    let invitePromise;
    let financialAuthPromise;

    if (!FamilyInviteEdit.isRegistered) {
      invitePromise = User.inviteDependent({
        id: FamilyInviteEdit.personId,
        email: FamilyInviteEdit.inviteEmail.toLowerCase()
      });

      promises.invite = handleRegistrationResponse(invitePromise);
    }

    if (
      FamilyInviteEdit.dependent.financialAuthorization &&
      !FamilyInviteEdit.isFinancialAuthorized
    ) {
      // Only revoke if we already have an authorization and the member selected 'no access'
      // Both checks are important on the off chance that someone loads the page for a member
      // with no access, and then toggles the radio buttons and submits the form in the initial state.
      financialAuthPromise = User.revokeDesigneeStatus(
        FamilyInviteEdit.dependent.financialAuthorization.id
      );
    } else if (
      !FamilyInviteEdit.dependent.financialAuthorization &&
      FamilyInviteEdit.isFinancialAuthorized
    ) {
      // Only invite if we don't already have authorization and the member selected 'give access'
      financialAuthPromise = User.grantDesigneeStatus(
        FamilyInviteEdit.currentSponsorship.subscriber,
        FamilyInviteEdit.currentSponsorship.sponsor,
        FamilyInviteEdit.dependent.id
      );
    }

    if (financialAuthPromise) {
      promises.financialAuth = handleFinancialAuthResponse(
        financialAuthPromise
      );
    }

    $q.all(promises)
      .then(() => {
        FamilyInviteEdit.toast.type = 'success';
        FamilyInviteEdit.toast.errorText = FamilyInviteEdit.isRegistered
          ? 'Successfully updated!'
          : 'Successfully updated and invited!';
        FamilyInviteEdit.toast.show = true;

        FamilyInviteEdit.accessLevel.$setPristine();
      })
      .catch(error => {
        FamilyInviteEdit.toast.type = 'error';
        FamilyInviteEdit.toast.show = true;

        switch (error.data.errorNumber) {
          case 912:
            FamilyInviteEdit.toast.errorText =
              'This email is already in use. Please try another.';
            break;
          case 1200:
            FamilyInviteEdit.toast.errorText =
              'Invalid email address. Please try another.';
            break;
          case 2008:
            FamilyInviteEdit.toast.errorText =
              'Only dependents can be invited.';
            break;
          case 2009:
            FamilyInviteEdit.toast.errorText =
              'Only dependents can be invited.';
            break;
          default:
            FamilyInviteEdit.toast.errorText = error.data.errorMessage;
        }
      })
      .finally(animatedScrollTop);
  };

  FamilyInviteEdit.backToOverview = function() {
    $state.go('SettingsOverview');
  };

  FamilyInviteEdit.isInvitePending = function() {
    return !FamilyInviteEdit.isRegistered && FamilyInviteEdit.isInvited;
  };

  FamilyInviteEdit.$onInit = function() {
    FamilyInviteEdit.dependent = _.find(FamilyInviteEdit.familyUnit, {
      id: $stateParams.dependentId
    });
    FamilyInviteEdit.dependent.isOver17 =
      moment().diff(FamilyInviteEdit.dependent.dateOfBirth, 'years') > 17;
    FamilyInviteEdit.personId = _.get(FamilyInviteEdit, 'dependent.id');
    FamilyInviteEdit.inviteEmail = _.get(FamilyInviteEdit, 'dependent.email');
    FamilyInviteEdit.levelOptions = [
      {
        label: 'Only access their own health information',
        subLabel: `Allows access to only their own health information and ${COLLECTIVE_TITLE} account.`,
        value: false
      },
      {
        label:
          'Access their own health information and discuss family’s health information',
        subLabel: `Allows ${FamilyInviteEdit.dependent.firstName} 
        to speak with Member Advocate about the entire family’s health information for 
        the purpose of payment.`,
        value: true
      }
    ];

    User.getFamilyDesignees(
      FamilyInviteEdit.currentSponsorship.sponsor,
      FamilyInviteEdit.currentSponsorship.subscriber
    ).then(designees => {
      const authorizedDesignee = _.find(designees, {
        authorizedDependentId: FamilyInviteEdit.personId
      });

      FamilyInviteEdit.isFinancialAuthorized = !!authorizedDesignee;

      if (FamilyInviteEdit.isFinancialAuthorized) {
        FamilyInviteEdit.dependent.financialAuthorization = authorizedDesignee;
      }
    });
  };
}

app.component('familyInviteEdit', {
  bindings: {
    currentSponsorship: '<',
    familyUnit: '<',
    isRegistered: '<',
    isInvited: '<'
  },
  controller: FamilyInviteEdit,
  controllerAs: 'FamilyInviteEdit',
  templateUrl: templateUrl
});

export default 'familyInviteEdit';
