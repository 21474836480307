import app from 'app';
import templateUrl from './mxConfirmationModal.html';
import './mxConfirmationModal.scss';

app.component('mxConfirmationModal', {
  bindings: {
    $close: '<',
    body: '<',
    cancel: '<',
    confirm: '<',
    header: '<',
  },
  controllerAs: 'MxConfirmationModal',
  templateUrl,
});

export default 'mxConfirmationModal';
