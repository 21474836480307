import app from 'app';
import templateUrl from './posingBanner.html';
import './posingBanner.scss';

function PosingBannerCtrl() {
  const PosingBanner = this;

  PosingBanner.$onInit = () => {
    if (PosingBanner.currentUser) {
      const { firstName, lastName, email } = PosingBanner.currentUser.user;
      PosingBanner.name = [firstName, lastName].join(' ');
      PosingBanner.email = email;
      PosingBanner.sponsorName = PosingBanner.currentSponsorship.sponsor.name;
    }
  };
}

app.component('posingBanner', {
  bindings: {
    currentSponsorship: '<',
    currentUser: '<',
  },
  controller: PosingBannerCtrl,
  controllerAs: 'PosingBanner',
  templateUrl,
});

export default 'posingBanner';
