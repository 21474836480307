export default class CardsForPeriod {
  constructor() {
    this.cardsByType = {};
  }

  get medical() {
    return this.cardsByType.medical;
  }

  get hasMedical() {
    return !!this.medical;
  }

  get medicalCardsTitle() {
    return 'Medical & Pharmacy';
  }

  get dental() {
    return this.cardsByType.dental;
  }

  get hasDental() {
    return !!this.dental;
  }

  get vision() {
    return this.cardsByType.vision;
  }

  get hasVision() {
    return !!this.vision;
  }

  get hasCards() {
    return this.hasMedical || this.hasDental || this.hasVision;
  }

  get hasDentalOrVision() {
    return this.hasDental || this.hasVision;
  }

  get dentalVisionCardsTitle() {
    if (this.hasDental && this.hasVision) {
      return 'Dental & Vision';
    }

    if (this.hasVision) {
      return 'Vision';
    }

    return 'Dental';
  }

  add(card) {
    this.cardsByType[card.type] = card;
  }
}
