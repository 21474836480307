import app from 'app';
import templateUrl from './familyAccessRead.html';
import './familyAccessRead.scss';

function FamilyAccessRead($q, PhiAuthorization) {
  const FamilyAccessRead = this;

  FamilyAccessRead.$onInit = () => {
    if (!FamilyAccessRead.isSubscriber) {
      PhiAuthorization.getAuthorizationsGrantedByLoggedInDependentToPrimary(
        FamilyAccessRead.currentSponsorship,
        FamilyAccessRead.currentUser
      ).then((auths) => {
        FamilyAccessRead.familyAuthorizations = auths;
      });
    }
  };
}

app.component('familyAccessRead', {
  bindings: {
    isSubscriber: '<',
    currentUser: '<',
    currentSponsorship: '<',
  },
  controller: FamilyAccessRead,
  controllerAs: 'FamilyAccessRead',
  templateUrl: templateUrl,
});
