import app from 'app';

import { getAssetParams } from 'helpers/assetParamsHelper';
import { ASSET_TYPE } from 'constants/asset';

function CustomAssetCtrl(
  $element,
  $state,
  $scope,
  Session,
  Sponsor,
  AssetService
) {
  const CustomAsset = this;
  CustomAsset.isLoggedIn = Session.hasSession;
  CustomAsset.currentSponsorship = Sponsor.getCurrentSponsorship();
  CustomAsset.isTermsOrPrivacy =
    $state.router.locationService.$location.$$path === '/terms' ||
    $state.router.locationService.$location.$$path === '/privacy';

  function updateDlsImage(name, sponsorId) {
    const assetParams = getAssetParams(
      CustomAsset.isLoggedIn(),
      AssetService.getSubdomainName(),
      sponsorId ||
        window.sessionStorage.getItem('affiliatesSponsorId') ||
        undefined
    );
    AssetService.getAssetByAssetKey(name, assetParams).then((response) => {
      if (!response || !response.asset) {
        return;
      }
      const { asset } = response;
      switch (asset.type.toLowerCase()) {
        case ASSET_TYPE.IMAGE:
          asset.value.url &&
            $element.html(
              `<img src='${asset.value.url}'
              alt='${asset.key}' />`
            );
          break;
        case ASSET_TYPE.CUSTOM_ELEMENT:
          asset.value.tagName &&
            $element.html(`<${asset.value.tagName}></${asset.value.tagName}>`);
          break;
        case ASSET_TYPE.TEXT:
          asset.value.text && $element.html(`${asset.value.text}`);
          break;
      }
    });
  }

  CustomAsset.$onChanges = (changes) => {
    const {
      name: { currentValue: name, previousValue: previousName },
    } = changes;
    if (name !== previousName) {
      updateDlsImage(name);
    }
  };

  $scope.$on('reloadFooterLogo', (event, { name, sponsorId }) => {
    if (name !== CustomAsset.name) {
      return;
    }
    updateDlsImage(name, sponsorId);
  });
}

app.component('customAsset', {
  bindings: {
    name: '@',
  },
  controller: CustomAssetCtrl,
  controllerAs: 'customAsset',
});
