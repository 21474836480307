/**
 * This function will be used for redirecting a React controlled route (NextJS)
 * from the Login flow, controlled by Angular, based on the parameters received
 * from the URL.
 * It is important to know that this will be called only when the `redirectTo`
 * is set to `MigrationWrapper`, and it will use two different parameters from
 * the URL:
 * - path: which is the `pathname` from the entry point.
 * - params: which is the `search` from the entry point.
 *
 * If the entry point provides those two parameters, it will construct an URL
 * based on this and it will redirect the browser by using these two params.
 *
 * @returns {string} URL
 */
export default function getReactMigrationPageRoutes() {
  const route = [];

  const queryParams = new Proxy(
    new URLSearchParams(globalThis.location.search),
    {
      get: (searchParams, prop) => searchParams.get(prop),
    },
  );
  const { path, params } = queryParams;

  if (path) {
    route.push(decodeURIComponent(path));
  }

  if (params) {
    route.push(decodeURIComponent(params));
  }

  return route.join('');
}
