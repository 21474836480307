import app from 'app';
import templateUrl from './flippableCard.html';
import './flippableCard.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-refresh';
import '../memberCardImg/memberCardImg';

app.component('flippableCard', {
  bindings: {
    cardTitle: '<',
    frontImg: '<',
    backImg: '<',
    imgError: '&',
  },
  controllerAs: 'FlippableCard',
  templateUrl,
});

export default 'flippableCard';
