/* eslint-disable angular/document-service */
import app from 'app';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/toast';

app.factory('Toaster', () => {
  let toasterEl = null;
  initToaster();

  function initToaster() {
    if (!toasterEl) {
      toasterEl = document.createElement('div');
      toasterEl.className = 'toastList chToaster';
      document.body.appendChild(toasterEl);
    }
  }

  function addToast(toast) {
    const toastEl = createToastEl(toast);
    if (toasterEl) {
      toasterEl.appendChild(toastEl);
    }
  }

  function createToastEl({
    status = TOAST_STATUS.success,
    title = '',
    description = '',
  }) {
    const newToast = document.createElement('dls-toast-alert');
    newToast.setAttribute('shown', 'shown');
    newToast.setAttribute('status', status);
    newToast.setAttribute('duration', TOAST_DURATION);

    const toastHead = document.createTextNode(title);
    const toastBody = document.createElement('span');
    toastBody.appendChild(document.createTextNode(description));
    toastBody.setAttribute('slot', 'toast-alert-body');

    newToast.appendChild(toastHead);
    newToast.appendChild(toastBody);
    return newToast;
  }

  return {
    addToast,
  };
});
