import app from 'app';
import _ from 'lodash';
import decodeJwt from 'jwt-decode';

import mxConfirmationModal from 'components/modals/mxConfirmationModal/mxConfirmationModal';

app.factory('Registration', ($q, Api, chModal, Session) => {
  const DEFAULT_REQUIRED_PARAMS = [
    'email',
    'password',
    'passwordConfirm',
    'primaryPhone',
    'tosAgreement',
    'token',
  ];

  function abstractRegister(requiredParams, member, url, headers) {
    // Check that all required keys are present, 901 = invalid form error
    if (_.difference(requiredParams, _.keys(member)).length) {
      return $q.reject({
        data: {
          errorNumber: 901,
          errorMessage: 'Missing required params',
        },
      });
    }

    member.legalClientPlatform = member.legalClientPlatform || 'web';
    return Api.req({
      apiPrefix: '/api/v2',
      headers: headers,
      endPoint: url,
      method: 'POST',
      data: member,
    });
  }

  function register(member) {
    var required = DEFAULT_REQUIRED_PARAMS.slice(0);
    return abstractRegister(required, member, '/register_member');
  }

  // registers a new identity for a member.
  // currently the only use case is for SSOed member to set email and password
  function registerIdentity(member, headers) {
    return abstractRegister(
      DEFAULT_REQUIRED_PARAMS,
      member,
      '/identity',
      headers
    );
  }

  // Consumes the reset password token
  // If valid, changes the user's password
  function resetPassword(token, newPass, newPassConfirm) {
    if (!token || !newPass || !newPassConfirm) {
      return $q.reject({
        missingParams: true,
        message: 'Missing required params',
      });
    }
    if (newPass !== newPassConfirm) {
      return $q.reject({
        mismatch: true,
        message: 'Confirmation must match password',
      });
    }

    return Api.put('/reset_password', {
      token: token,
      password: newPass,
      passwordConfirm: newPassConfirm,
    });
  }

  function showSuccessModal() {
    chModal.open({
      component: mxConfirmationModal,
      locals: {
        header: 'Almost there...',
        body: `You should receive an email from us shortly with a link to verify your email.
          If you don't see it in the next few minutes (and it's not in your spam folder)
          send us a message`,
        confirm: 'Close',
      },
    });
  }

  // Creates / resends verification email given provided email address
  function createVerificationEmail(email) {
    const params = {
      email: email,
    };

    if (Session.hasSession()) {
      return $q.reject({ preVerified: true, message: 'Already verified' });
    }
    if (!email) {
      return $q.reject({ message: 'Must provide email address' });
    }

    // Moved Modal logic here for consistency across different UI screens
    return Api.post('/verify_email', params)
      .then(function () {
        // Verification email sent!
        showSuccessModal();
      })
      .catch(function (error) {
        if (error && error.data && error.data.errorNumber === 906) {
          // fake success: security through obscurity
          showSuccessModal();
        } else {
          // Verification went wrong
          return $q.reject(new Error('requestFailed'));
        }
      });
  }

  // Sends email verification token parsed from the url
  function decodeRegToken(token) {
    return decodeJwt(token);
  }

  // Sends email verification token parsed from the url
  function verifyEmail(token) {
    if (Session.hasSession()) {
      // TODO: Refactor to reject with an Error().
      return $q.reject({
        preVerified: true,
        message: 'Already verified',
      });
    }

    if (!token) {
      // TODO: Refactor to reject with an Error().
      return $q.reject({
        message: 'Must provide verification token',
      });
    }

    return Api.put('/verify_email', {
      token: token,
    });
  }

  function getMemberByToken(token) {
    if (!token) {
      return $q.reject({
        missingParams: true,
        message: 'Missing required params',
      });
    }

    const params = {
      token: token,
      expand: [
        'id',
        'email',
        'domain',
        'firstName',
        'lastName',
        'exp',
        'hasUnactivatedHsa',
        'hasChMedical',
        'address',
      ],
    };

    return Api.get('/registration/verify_token', params);
  }

  return {
    register,
    registerIdentity,
    resetPassword,
    decodeRegToken,
    createVerificationEmail,
    verifyEmail,
    getMemberByToken,
  };
});
