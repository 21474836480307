/* eslint-disable angular/document-service */
import app from 'app';
import templateUrl from './zendeskInbox.html';
import InboxApp from '@collectivehealth/web-member-inbox/dist';

// TODO - @ch-demostenesgarcia
// We need to use the imported version from the web-member-inbox repository once
// we update it.
const NAVIGATION_SECTIONS = {
  VIEW: 'VIEW',
  TICKETS_INBOX: 'TICKETS_INBOX',
  COMPOSE: 'COMPOSE',
  CATEGORY_COMPOSE: 'CATEGORY_COMPOSE',
  COMPOSE_CANCEL_PENDING: 'COMPOSE_CANCEL_PENDING',
  NAVIGATE_AWAY_PENDING: 'NAVIGATE_AWAY_PENDING',
  NAVIGATE_AWAY: 'NAVIGATE_AWAY',
  RECOMMENDATIONS_INBOX: 'RECOMMENDATIONS_INBOX',
};

const getCurrentSection = (inbox) => {
  if (inbox.goToCompose) return NAVIGATION_SECTIONS.COMPOSE;
  if (inbox.categoryCompose) return NAVIGATION_SECTIONS.CATEGORY_COMPOSE;
  if (inbox.recommendationsInbox)
    return NAVIGATION_SECTIONS.RECOMMENDATIONS_INBOX;

  return NAVIGATION_SECTIONS.TICKETS_INBOX;
};

function ZendeskInbox($scope, $state, $anchorScroll, User, Inbox, Sponsor) {
  const ZendeskInbox = this;
  let inboxEl;
  ZendeskInbox.inboxApp = null;

  ZendeskInbox.$onInit = async () => {
    const { user } = await User.getUser();

    inboxEl = document.querySelector('.ZendeskInbox');

    ZendeskInbox.inboxApp = new InboxApp({
      target: inboxEl,
      props: {
        parentService: {
          onStateChangeStart: (cb) => {
            $scope.$on('$stateChangeStart', cb);
          },
          navigate: (state, params, options) => {
            $state.go(state, params, options);
          },
          anchorScroll: $anchorScroll,
          inboxMenuSync: Inbox.notify,
          suggestionMenuSync: () => {},
        },
        memberId: parseInt(user.id, 10),
        ticketId: parseInt(ZendeskInbox.ticketId, 10),
        currentSection: getCurrentSection(ZendeskInbox),
        recommendationsEnabled: Boolean(ZendeskInbox.isRecommendationsEnabled),
        goToCompose: ZendeskInbox.goToCompose,
        recommendationId: parseInt(ZendeskInbox.recommendationId, 10),
        goToCategoryCompose: ZendeskInbox.categoryCompose,
        recommendationsInbox: Boolean(ZendeskInbox.recommendationsInbox),
        source: ZendeskInbox.source,
        sponsorshipId: parseInt(ZendeskInbox.sponsorshipId, 10),
        sponsorId: parseInt(Sponsor.getCurrentSponsorship().sponsor.id),
      },
    });
  };

  ZendeskInbox.$onDestroy = () => {
    // there is bug on Svelte Component's onDestroy does not called
    // when using back button
    document.body.style.overflow = '';
    ZendeskInbox.inboxApp.$destroy();
    ZendeskInbox.inboxApp = null;
    inboxEl.innerHTML = '';
  };
}

app.component('zendeskInbox', {
  controller: ZendeskInbox,
  bindings: {
    ticketId: '<?',
    recommendationId: '<?',
    categoryCompose: '<?',
    goToCompose: '<?',
    recommendationsInbox: '<?',
    isRecommendationsEnabled: '<?',
    source: '<?',
    sponsorshipId: '<?',
    sponsorId: '<?',
  },
  controllerAs: 'ZendeskInbox',
  templateUrl,
});

export default 'zendeskInbox';
