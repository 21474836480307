import app from 'app';
import './chat.scss';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

const defaultOptions = {
  rootUrl: 'https://app.five9.com/consoles/',
  type: 'chat',
  showProfiles: false,
  autostart: false,
  theme: '',
  surveyOptions: {
    showComment: true,
    requireComment: false,
  },
  fields: {
    name: {
      value: '',
      show: false,
      label: 'Name',
      readonly: true,
    },
    email: {
      value: '',
      show: false,
      label: 'Email',
    },
    'Custom.contact_jwt': {
      value: '',
      show: false,
      label: 'contact_jwt',
    },
    question: {
      value: '',
      show: true,
      label: 'How can we help?',
      required: true,
    },
  },
  playSoundOnMessage: true,
  allowCustomerToControlSoundPlay: true,
  showEmailButton: false,
  hideDuringAfterHours: false,
  useBusinessHours: true,
  showPrintButton: false,
  allowUsabilityMenu: false,
  enableCallback: false,
  callbackList: '',
  allowRequestLiveAgent: false,
};

function Five9Ctrl(
  $stateParams,
  $interval,
  $window,
  $scope,
  Api,
  App,
  Chat,
  FeatureFlagService,
  Session,
  User,
) {
  const Five9 = this;

  const setUpChat = (data, user) => {
    const options = Object.assign(
      {
        profiles: data.profile,
        tenant: data.tenant,
        title: data.title,
      },
      defaultOptions,
    );

    options.fields.email.value = user.email;
    options.fields.name.value = user.preferredName
      ? user.preferredName
      : user.firstName;
    options.theme = `${$window.location.origin}/styles/chat/chat.css`;
    options.fields['Custom.contact_jwt'].value = data.token;

    const client = FeatureFlagService.getLDClient();
    client.waitUntilReady().then(() => {
      const isChatQuestionEnabled = getFFVariation(
        client,
        'isChatQuestionEnabled',
      );

      if (!isChatQuestionEnabled) {
        options.autostart = true;
        options.fields.question.show = false;
      }
    });

    Five9SocialWidget.addWidget(options); // eslint-disable-line no-undef

    const targetNode = document.getElementById('five9-frame-full');
    const config = { attributes: true, childList: true, subtree: true };
    const iframeCallback = () => {
      const el = document.getElementById('embedded-frame');
      if (!el) {
        Five9SocialWidget.removeWidget(); // eslint-disable-line no-undef
        return Chat.closeChat();
      }
    };
    const observer = new MutationObserver(iframeCallback);

    observer.observe(targetNode, config);

    // Maximize the chat on load
    Five9.t = $interval(() => {
      const el = document.getElementById('five9-maximize-button');
      if (el) {
        $interval.cancel(Five9.t);
        el.click();
      }
    }, 100);
  };

  Five9.$onInit = () => {
    if (Session.hasSession) {
      User.getUser().then(({ user }) => {
        const chatInstance = $window.sessionStorage.getItem('chatInstance');

        if (chatInstance) {
          setUpChat(JSON.parse(chatInstance), user);
        }
      });
    }
  };

  Chat.$onDestroy = () => {
    Five9SocialWidget.removeWidget(); // eslint-disable-line no-undef
  };
}

app.component('five9', {
  bindings: {
    currentUser: '<',
  },
  controller: Five9Ctrl,
  controllerAs: 'Five9',
});

export default 'five9';
