// This constant maps all claims types to corresponding plan types
// which allows for quick plan type lookup without needing a reference to an actual plan
export default {
  medicalClaim: 'medical',
  institutionalMedicalClaim: 'medical',
  professionalMedicalClaim: 'medical',
  visionClaim: 'vision',
  pharmacyClaim: 'pharmacy',
  dentalClaim: 'dental',
};
