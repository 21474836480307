import app from 'app';
import { WELCOME_TICKET } from 'constants/inboxTicket';
import { getCookie, putCookie } from '@collectivehealth/cookie';

const FIRST_TIME_INBOX = 'chFirstTimeInbox';
const COUNT = 2;

/**
 * only get called the first time page load, after that data will get synced
 * from the inbox via observers
 */
app.factory('Inbox', (Api, Raven) => {
  let observers = [];

  function subscribe(fn) {
    observers.push(fn);
  }

  function unsubscribe(fn) {
    observers = observers.filter((observer) => observer !== fn);
  }

  function notify(data) {
    observers.forEach((observer) => observer(data));
  }

  function isFirstSeenInbox() {
    return !getCookie(FIRST_TIME_INBOX);
  }

  function setSeenInbox() {
    putCookie(FIRST_TIME_INBOX, true);
  }

  function getInboxData() {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: `/crmclient/inbox/requests`,
      cache: true,
      params: {
        sort_by: 'updated_at',
        sort_order: 'desc',
        page: '1',
      },
    })
      .then((response) => {
        const visibleTickets = response.requests.filter(
          (request) => !isTicketHidden(request),
        );
        const tickets = visibleTickets.concat([WELCOME_TICKET]);
        return {
          tickets: tickets.slice(0, COUNT),
          unreadCount: determineUnreadCount(response.requests),
        };
      })
      .catch((error) => {
        Raven.captureMessage('Error retrieving tickets.', { extra: error });
        return {
          tickets: [],
          unreadCount: 0,
        };
      });
  }

  function isTicketHidden(ticket) {
    const hiddenField = getCustomFieldObject(
      ticket,
      'IS_HIDDEN_FROM_PORTAL_FIELD',
    );
    if (hiddenField) {
      return !!hiddenField.value;
    }
    return false;
  }

  function determineUnreadCount(tickets) {
    const unreadTickets = tickets.filter((t) => {
      // for the READ_UNREAD_FIELD custom field
      // READ_UNREAD_FIELD.value = false, means the messsage is unread
      // READ_UNREAD_FIELD.value = true, means the messsage is read
      const readField = getCustomFieldObject(t, 'READ_UNREAD_FIELD');
      if (readField) {
        return readField.value ? false : true;
      }
      return false;
    });
    return unreadTickets.length;
  }

  function getCustomFieldObject(ticket, fieldTag) {
    if (ticket.custom_fields) {
      return ticket.custom_fields.find((item) => item.tag === fieldTag);
    }
    return undefined;
  }

  return {
    getInboxData,
    isFirstSeenInbox,
    setSeenInbox,
    subscribe,
    unsubscribe,
    notify,
  };
});
