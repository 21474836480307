export default class FundAccount {
  constructor(account) {
    this.account = account;
  }

  get description() {
    return this.account.description;
  }

  get id() {
    return this.account.id;
  }

  get type() {
    return this.account.type;
  }

  get name() {
    return this.account.name;
  }

  get hasBalance() {
    return !!this.balance || this.balance === 0;
  }

  get balance() {
    return this.account.balance;
  }

  get noBalanceMessage() {
    // Separated because balance uses currency filter in view
    return this.account.noBalanceMessage;
  }

  get hasSso() {
    return this.account.sso;
  }

  get groupYear() {
    return this.account.groupYear;
  }

  get partnerId() {
    return this.account.partnerId;
  }

  get sponsorId() {
    return this.account.sponsorId;
  }

  get partnerTrackingName() {
    if (this.account.isAlegeus) {
      return 'Alegeus';
    }

    return 'HQY';
  }
}
