export const CONFIG_NAME = 'memberLegal';

export const TERMS = {
  DATA_HOOK: 'footer-terms-link',
};

export const PRIVACY = {
  DATA_HOOK: 'footer-privacy-link',
};

export const ACCESSIBILITY = {
  STATE_NAME: 'Accessibility',
  NAV_NAME: 'Accessibility',
  DATA_HOOK: 'footer-accessibility-link',
};

export const HEALTHCARE_RIGHTS = {
  STATE_NAME: 'SponsorDocs',
  NAV_NAME: 'Healthcare Rights',
  SHORT_NAV_NAME: 'Rights',
  DATA_HOOK: 'legal-Rights',
};

export const PARTNER_DISCLAIMERS = {
  STATE_NAME: 'PartnerDisclaimers',
  NAV_NAME: 'Partner Disclaimers',
  SHORT_NAV_NAME: 'Partners',
  DATA_HOOK: 'legal-partner-disclaimers',
};

export default {
  CONFIG_NAME,
  TERMS,
  PRIVACY,
  ACCESSIBILITY,
  HEALTHCARE_RIGHTS,
  PARTNER_DISCLAIMERS,
};
