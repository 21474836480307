const URL_TYPE = {
  FRONT: 'FRONT',
  BACK: 'BACK',
};

export default class Card {
  constructor(card) {
    this.card = card;

    const urls = card.urls || [];
    this.cardUrlsByType = urls.reduce((byType, url) => {
      byType[url.type] = url.url;
      return byType;
    }, {});
  }

  get type() {
    return this.card.type.toLowerCase();
  }

  get cardData() {
    return this.card.cardData;
  }

  get planMembershipId() {
    return this.card.planMembershipId;
  }

  get frontUrl() {
    return this.cardUrlsByType[URL_TYPE.FRONT];
  }

  get backUrl() {
    return this.cardUrlsByType[URL_TYPE.BACK];
  }

  get hasImgs() {
    return !!this.frontUrl && !!this.backUrl;
  }
}
