import app from 'app';

// only display first 3
const REC_COUNT = 3;

app.factory('Recommendations', (Api, Raven, User, $q) => {
  function isRecommendationsEnabled() {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint:
        '/membercoremiddleware/app_config/member.recommendations.careSuggestions.Enabled',
      method: 'GET',
      cache: true,
    })
      .then((data) => data.value && data.value.toLowerCase() === 'true')
      .catch(() => {
        Raven.captureMessage('Error checking Care Suggestions availability', {
          extra: error,
        });
      });
  }

  function getUnreadRecommendationsCount(memberId) {
    if (!memberId) {
      return $q.reject(
        new Error('[getUnreadRecommendationsCount] Expected [memberId].')
      );
    }

    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: '/memberrec/member_recommendation/unread',
      method: 'GET',
      params: {
        memberId,
      },
    }).catch((error) => {
      Raven.captureMessage('Error fetching unread recommendations count', {
        extra: error,
      });
      return [];
    });
  }

  function fetchRecommendationSuggestions(memberId) {
    if (!memberId) {
      return $q.reject(
        new Error('[fetchRecommendationSuggestions] Expected [memberId].')
      );
    }

    return Api.req({
      apiPrefix: '/api/v2',
      endPoint: '/memberrec/member_recommendation/newest_unread',
      method: 'GET',
      params: {
        limit: REC_COUNT,
        memberId,
      },
    })
      .then((data) => {
        return data.recommendations;
      })
      .catch((error) => {
        Raven.captureMessage('Error fetching recommendations', {
          extra: error,
        });
        return [];
      });
  }

  function dismissRecommendation(channelIncludeId) {
    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/memberrec/dismiss_recommendation/member',
      method: 'POST',
      data: {
        channelIncludeId,
      },
    })
      .then((data) => data)
      .catch((error) => {
        Raven.captureMessage('Error dismiss recommendation', { extra: error });
        throw error;
      });
  }

  return {
    isRecommendationsEnabled,
    getUnreadRecommendationsCount,
    fetchRecommendationSuggestions,
    dismissRecommendation,
  };
});
