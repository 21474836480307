import app from 'app';

app.factory('PartnersService', (Logger, ApiMCM, $sce) => {
  const partnerMap = {};
  // Returns all of users sponsors
  // these sponsors are sorted in order of sponsors importance to the user
  function getPartners(sponsorshipId) {
    if (partnerMap[sponsorshipId]) {
      return partnerMap[sponsorshipId];
    }

    const params = {
      sponsorshipId,
      platform: 'web',
    };

    const promise = ApiMCM.get('partners', params)
      .then((response) => {
        const partners = response.partners.map((partner) => {
          partner.iconTemplate = $sce.trustAsHtml(
            `<${partner.iconKey} class="LandingPartners__icon">
            </${partner.iconKey}>`
          );

          return partner;
        });
        return partners;
      })
      .catch((error) => {
        Logger.captureApiError('partners', params, error.response.data);
        return [];
      });

    partnerMap[sponsorshipId] = promise;
    return partnerMap[sponsorshipId];
  }

  function getLandingPagePartners(sponsorshipId) {
    return getPartners(sponsorshipId).then((partners) => {
      return partners
        .filter((partner) => {
          return partner.display.type !== 'state';
        })
        .slice(0, 3);
    });
  }

  // CH-45118 account for Cobra member, since Cobra user might not have benefit partners
  // ideally we should update backend for plan detail api to have the new partner schema
  // or transform the payload at the service
  function getValidBenefitPartners(benefitPartners, allPartners) {
    if (!Array.isArray(benefitPartners) || !Array.isArray(allPartners)) {
      return [];
    }

    return allPartners.filter((a) => {
      return !!benefitPartners.find((o) => o.id === a.partnerId);
    });
  }

  function hasValidBenefitPartners(benefitPartners, allPartners) {
    if (!Array.isArray(benefitPartners) || !Array.isArray(allPartners)) {
      return false;
    }

    const partners = getValidBenefitPartners(benefitPartners, allPartners);
    return partners.length > 0;
  }

  return {
    getPartners,
    getLandingPagePartners,
    getValidBenefitPartners,
    hasValidBenefitPartners,
  };
});
