import app from 'app';
import possessiveNoun from './possessiveNoun';
import relationshipType from './relationshipType';
import selectiveCapitalize from './selectiveCapitalize';

// Filters that need angular DI will attach to the app on their own, and therefore only need to be imported
import './chCurrency';

app.filter('possessiveNoun', () => possessiveNoun);
app.filter('relationshipType', () => relationshipType);
app.filter('selectiveCapitalize', () => selectiveCapitalize);
