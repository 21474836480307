import app from 'app';
import templateUrl from './inboxMenu.html';
import './inboxMenu.scss';
import './inboxTicket/inboxTicket';
import './inboxTicketSkeleton/inboxTicketSkeleton';
import { WELCOME_TICKET } from 'constants/inboxTicket';
import { checkInboxFeatureFlag } from 'helpers/inboxMigration';
import { logEvent, eventCategories } from 'helpers/analytics';
import ROUTES from 'routes.json';

function InboxMenu($state, $q, $timeout, Inbox, Sponsor, FeatureFlagService) {
  const InboxMenu = this;
  InboxMenu.isFetching = false;
  InboxMenu.tickets = [];

  InboxMenu.$onInit = () => {
    getInboxData().then(() => {
      if (
        InboxMenu.tickets.length === 1 &&
        InboxMenu.tickets[0].id === WELCOME_TICKET.id &&
        Inbox.isFirstSeenInbox()
      ) {
        Inbox.setSeenInbox();
        $timeout(() => {
          InboxMenu.inboxMenuClick();
        }, 0);
      }
    });
    Inbox.subscribe(getInboxData);
  };

  InboxMenu.$onDestroy = () => {
    Inbox.unsubscribe(getInboxData);
  };

  InboxMenu.seeAll = () => {
    const sponsorId = Sponsor.getCurrentSponsorship().sponsor.id;
    const sponsorshipId = Sponsor.getCurrentSponsorship().id;
    InboxMenu.$close();
    trackOpenInbox();

    checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
      if (ffEnabled) {
        window.location.replace(
          `${ROUTES.inbox.react}?sponsorId=${sponsorId}&sponsorshipId=${sponsorshipId}`,
        );
      } else {
        $state.go('Inbox', {
          sponsorshipId: sponsorshipId,
          sponsorId: sponsorId,
        });
      }
    });
  };

  InboxMenu.selectTicket = (ticket) => {
    InboxMenu.$close();
    trackOpenInbox();

    const sponsorId = Sponsor.getCurrentSponsorship().sponsor.id;
    const sponsorshipId = Sponsor.getCurrentSponsorship().id;

    checkInboxFeatureFlag(FeatureFlagService).then((ffEnabled) => {
      if (ffEnabled) {
        window.location.replace(
          `${ROUTES.inbox.react}?ticketId=${ticket.id}&sponsorId=${sponsorId}&sponsorshipId=${sponsorshipId}`,
        );
      } else {
        $state.go('Inbox', { ticketId: ticket.id });
      }
    });
  };

  function trackOpenInbox() {
    logEvent('messagesHomeViewed', {
      category: eventCategories.INBOX,
      messageStartType: 'inbox',
    });
  }

  function getInboxData(data) {
    if (data) {
      InboxMenu.tickets = data.tickets;
      return $q.when();
    }

    InboxMenu.isFetching = true;
    return Inbox.getInboxData()
      .then((data) => {
        InboxMenu.tickets = data.tickets;
        return data.tickets;
      })
      .finally(() => (InboxMenu.isFetching = false));
  }

  // hack to open inbox menu for first time experience
  InboxMenu.inboxMenuClick = function () {
    // eslint-disable-next-line no-undef
    angular.element('.Header__inboxIcon').trigger('click');
  };
}

app.component('inboxMenu', {
  bindings: {
    $close: '<',
  },
  controller: InboxMenu,
  controllerAs: 'InboxMenu',
  templateUrl,
});

export default 'inboxMenu';
