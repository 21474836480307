import app from 'app';
import templateUrl from './footer.html';
import './footer.scss';

import 'components/supportMessageLink/supportMessageLink';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-phone';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-clock';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chat-dots';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-envelope';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-book';

import 'components/businessHours/businessHours';
import MEMBER_PORTAL from 'constants/memberPortal';
import LEGAL from 'constants/legal';
import COPY from '@collectivehealth/mx-pre-dls/src/MXFooter/MXFooterCopy.json';
import ROUTES from '@collectivehealth/login-portal/src/helpers/routes';

import {
  eventCategories,
  eventNames,
  getPage,
  logEvent,
} from 'helpers/analytics';
import { getAssetParams } from 'helpers/assetParamsHelper';
import getHelpCenterRoute from 'helpers/helpCenterRoutes';

function FooterCtrl(
  $stateParams,
  $scope,
  $element,
  App,
  AssetService,
  Chat,
  FeatureFlagService,
  FrontendLegal,
  Session,
  Sponsor,
) {
  const Footer = this;
  Footer.text = '';
  Footer.showPartnerDisclaimers = false;
  Footer.getHelpCenterRoute = getHelpCenterRoute;

  function updateAssets() {
    const assetParams = getAssetParams(
      Footer.isLoggedIn(),
      AssetService.getSubdomainName(),
      window.sessionStorage.getItem('affiliatesSponsorId') || undefined,
    );
    AssetService.getAssetByAssetKey('mp-footer-text', assetParams).then(
      (response) => {
        if (
          !response.asset &&
          !response.asset.value &&
          !response.asset.value.text
        ) {
          return;
        }
        Footer.text = response.asset.value.text;
      },
    );

    AssetService.getAssetByAssetKey('mp-footer-hours', assetParams).then(
      (response) => {
        if (
          !response.asset &&
          !response.asset.value &&
          !response.asset.value.text
        ) {
          return;
        }
        Footer.hours = response.asset.value.text;
      },
    );

    // Note: subdomain is passed as the param since partner-disclaimer-content is independent of sponsor / authentication
    FeatureFlagService.getFeatureFlagStatus(
      'affiliatesPartnerDisclaimers',
    ).then((enabled) => {
      if (enabled) {
        AssetService.getAssetByAssetKey(
          'mp-partner-disclaimer-content',
          assetParams,
        ).then((response) => {
          if (!response.asset) {
            return;
          }
          Footer.showPartnerDisclaimers = Boolean(response.asset.value?.body);
        });
      }
    });
  }

  Footer.$onInit = () => {
    Footer.isLoggedIn = Session.hasSession;

    Footer.currentYear = App.currentYear;
    Footer.serviceDays = MEMBER_PORTAL.serviceDays;
    Footer.serviceHours = MEMBER_PORTAL.serviceHours;
    Footer.sponsorshipId = $stateParams.sponsorshipId;
    Footer.sponsorId = Sponsor?.getCurrentSponsorship()?.sponsor?.id;

    updateAssets();

    const legalConfig = FrontendLegal.configurations[LEGAL.CONFIG_NAME];
    Footer.termsStateName = legalConfig.terms.stateName;
    Footer.privacyStateName = legalConfig.privacy.stateName;
    Footer.accessibilityStateName = LEGAL.ACCESSIBILITY.STATE_NAME;
    Footer.partnerDisclaimersStateName = LEGAL.PARTNER_DISCLAIMERS.STATE_NAME;

    Footer.analyticsTrack = () => {
      logEvent(eventNames.CONTACT_BUTTON_CLICKED, {
        category: eventCategories.HELP,
        location: 'footer',
      });
    };

    Footer.trackChatLaunched = () => {
      logEvent(eventNames.CHAT_LAUNCHED, {
        source: getPage(),
        link_text: COPY.chatLinkLabel,
        link_location_code: eventCategories.FOOTER,
      });
    };

    Footer.legalPagePath = (path) => {
      const queryParams = [
        Footer.sponsorshipId ? `sponsorshipId=${Footer.sponsorshipId}` : '',
        Footer.sponsorId ? `sponsorId=${Footer.sponsorId}` : '',
      ];
      const queryString = queryParams.filter(Boolean).join('&');
      const finalPath = `${path}${queryString ? '?' : ''}${queryString}`;

      return finalPath;
    };

    Footer.partnerDisclaimersHref = Footer.legalPagePath(
      `/${ROUTES.PARTNER_DISCLAIMERS.react}`,
    );

    Footer.contactUs = () => {
      if (Footer.closeParent) {
        Footer.closeParent();
      }
      Footer.analyticsTrack();
    };

    Footer.openChat = () => {
      Footer.trackChatLaunched();
      Chat.openChat();
    };

    setCurrentSponsorship();
  };

  //CH-47137 footer requires sponsorship data to display correct phone number when logged in
  $scope.$on('$locationChangeSuccess', setCurrentSponsorship);

  function setCurrentSponsorship() {
    // Once successfully logged in, actively render assets based on relationship
    // between sponsor and affiliate. Or once logged out, render assets based on
    // subdomain value.
    if (Footer.isLoggedIn()) {
      $scope.$broadcast('reloadFooterLogo', {
        name: 'mp-logo-footer',
        sponsorId: Footer.isLoggedIn
          ? window.sessionStorage.getItem('affiliatesSponsorId') || undefined
          : undefined,
      });
      updateAssets();
    }
  }
}

app.component('footerComponent', {
  bindings: {
    closeParent: '<?',
  },
  controller: FooterCtrl,
  controllerAs: 'Footer',
  templateUrl,
});

export default 'footerComponent';
