import app from 'app';
import templateUrl from './login.html';
import './login.scss';

import { logEvent, eventCategories } from 'helpers/analytics';
import getHelpCenterRoute from 'helpers/helpCenterRoutes';
import getReactMigrationPageRoutes from 'helpers/reactMigrationPageRoutes';
import { COLLECTIVE_TITLE } from '../../../collectiveTitle';

import resetCredentials from 'pages/loginPortal/resetCredentials/resetCredentials';
import resendVerificationEmail from './resendVerificationEmail/resendVerificationEmail';
import { getFFVariation } from '@collectivehealth/mx-feature-flags/src/utils';

function LoginCtrl(
  $state,
  $stateParams,
  chModal,
  ServerErrorCodes,
  LoginService,
  FeatureFlagService,
) {
  const Login = this;
  Login.COLLECTIVE_TITLE = COLLECTIVE_TITLE;

  Login.isWonderful = window.location.host.startsWith('wonderful');

  const client = FeatureFlagService.getLDClient();
  client.waitUntilReady().then(() => {
    Login.isWonderfulPreLive = getFFVariation(client, 'isWonderfulPreLive');
    Login.isWonderfulLive = getFFVariation(client, 'isWonderfulLive');
  });

  Login.login = () => {
    // Show alert on invalid form
    if (Login.form.$invalid) {
      Login.form.email.$setTouched(true);
      Login.form.password.$setTouched(true);
      return;
    }

    Login.loading = { login: true };

    return LoginService.login({
      email: Login.email.toLowerCase(),
      password: Login.password,
    })
      .then((response) => {
        // Redirect to next state, or default.
        let redirectParams;

        // If there are serialized redirectTo state params
        if ($state.params.redirectTo && $state.params.redirectToParams) {
          // deserialize them
          redirectParams = JSON.parse(
            decodeURIComponent($state.params.redirectToParams),
          );
        }

        // question: won't we lose redirect params here because its not declared in the state?
        if (response.isTosRequired) {
          // todo fix redirect params
          redirectParams = {
            token: response.accessToken,
          };
          $state.go('LegalAcceptance', redirectParams);
        } else {
          logEvent('loginSuccess', {
            category: eventCategories.LOGIN,
            loginType: 'password',
          });

          if ($state.params.redirectTo === 'MemberEdu') {
            window.location.href = getHelpCenterRoute();
          } else if ($state.params.redirectTo === 'MigrationWrapper') {
            window.location.href = getReactMigrationPageRoutes();
          } else if (
            $state.params.redirectTo === 'Landing' ||
            !$state.params.redirectTo
          ) {
            let url = '/landing';
            if (Boolean(redirectParams)) {
              Object.keys(redirectParams)?.forEach(
                (key) =>
                  (url = appendQueryParam(url, key, redirectParams[key])),
              );
            }
            window.location.href = url;
          } else {
            $state.go($state.params.redirectTo, redirectParams);
          }
        }
      })
      .catch((response) => {
        // new error handling
        if (response && response.data && response.data.code) {
          switch (response.data.code) {
            case 15015:
              Login.form.password.$error.badpassword = true;
              break;
            case 15301:
              Login.lockPassword = true;
              Login.errorCode = response.data.code;
              break;
            default:
              Login.errorCode = response.data.code;
          }
        }

        // old error handling left for backwards compatibility until new login portal is live
        if (response && response.data) {
          // Handled 107 errorNumber for Unverified account
          if (response.data.errorNumber === 107) {
            Login.errorCode = response.data.errorNumber;
          } else if (response.status !== 500) {
            // Handled 500 in the auth intercepter; don't show another alert
            Login.form.password.$error.badpassword = true;
            // For updating the view and see the error, the $state.go is needed
            $state.go('Login');
          }
        }

        Login.loading.login = false;
      });
  };

  Login.clearBadPasswordError = () => {
    if (Login.form.password.$error.badpassword) {
      Login.form.password.$error.badpassword = false;
    }
  };

  Login.loginSubmit = () => {
    logEvent('loginSubmit', {
      category: eventCategories.LOGIN,
      loginType: 'password',
    });
  };

  Login.showPasswordResetModal = () => {
    logEvent('passwordForgot', {
      category: eventCategories.LOGIN,
    });
    chModal.open({
      component: resetCredentials,
      locals: {
        prefilledEmail: String(Login.email || ''),
      },
    });
  };

  Login.registerLinkClicked = () => {
    logEvent('registerButtonClicked', {
      category: eventCategories.LOGIN,
    });
  };

  Login.getLoginErrorText = function () {
    return ServerErrorCodes.getMessageForCode(Login.errorCode);
  };

  Login.dismissError = function () {
    Login.errorCode = '';
  };

  Login.emailChange = function () {
    Login.lockPassword = false;
  };

  Login.$onInit = function () {
    // allows email addres to be prepopulated when members are linked from emails
    Login.email = $stateParams.loginEmail;

    if (Login.resendVerificationType) {
      let title = 'Resend Verification Email';
      let content = `We'll be happy to resend the verification email for you!
        Just enter your email address below.`;
      if (Login.resendVerificationType === 'prompt') {
        title = 'Please Verify Your Email';
        content = `Please confirm your identity by clicking on the
          link we sent to your email. <br>
          We'll be happy to resend the verification email for you!
          Just enter your email address below.`;
      }

      chModal.open({
        component: resendVerificationEmail,
        locals: {
          title,
          content,
          email: Login.resendEmail,
        },
      });
    }
  };
}

app.component('login', {
  bindings: {
    activeAlerts: '<',
    errorCode: '<',
    resendEmail: '<',
    resendVerificationType: '<',
    passwordResetSuccess: '<',
  },
  controller: LoginCtrl,
  controllerAs: 'Login',
  templateUrl,
});

export default 'login';
