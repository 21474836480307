import app from 'app';
import templateUrl from './legalCopy.html';
import './legalCopy.scss';

function LegalCopy() {
  const LegalCopy = this;

  LegalCopy.acceptCopy = function () {
    LegalCopy.disableAccept = true;
    LegalCopy.accept();
  };

  LegalCopy.declineCopy = function () {
    LegalCopy.decline();
  };
}

app.component('legalCopy', {
  bindings: {
    copyHtml: '<',
    legalTypes: '<',
    accept: '&',
    decline: '&',
  },
  controller: LegalCopy,
  controllerAs: 'LegalCopy',
  templateUrl,
});
