import app from 'app';
import templateUrl from './base.html';
import './base.scss';

function baseCtrl(
  $state,
  $location,
  $rootScope,
  Chat,
  Posing,
  User,
  TimeService,
) {
  const Base = this;

  Base.isPosing = Posing.isPosing;

  Base.is404 = () => $state.is('404') || $state.is('404Angular');
  Base.isSafariOutage = () => $state.is('safariOutage');

  Base.showFive9Chat = () => $state.params.isChatOpen;

  Base.isChOpen = TimeService.isChOpen();
}

app.component('baseComponent', {
  controller: baseCtrl,
  controllerAs: 'Base',
  templateUrl,
});

export default 'baseComponent';
