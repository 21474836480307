import app from 'app';
import templateUrl from './coverage.html';

app.component('coverage', {
  bindings: {
    subNavLinks: '<',
  },
  controllerAs: 'Coverage',
  templateUrl,
});

export default 'coverage';
