import _ from 'lodash';
import app from 'app';
import angular from 'angular';
import decodeJwt from 'jwt-decode';
import { logEvent, eventCategories } from 'helpers/analytics';
import { setAmplitudeUser } from 'helpers/analytics';

app.factory('SSOService', ($document, $q, Api, Session, $state) => {
  function showError(errObj) {
    // internal server error or frontend error (no error number)
    // show a modal
    if (errObj.errorNumber === 113) {
      return $state.go('SSOError', {
        err: btoa(angular.toJson(errObj))
      });
    } else if (errObj.errorNumber) {
      return $state.go('Login').finally(function() {
        // TODO: Needs errorModal
        // return Alerts.errorModal(errObj.errorNumber);
      });
    } else {
      return $state.go('Login').finally(function() {
        // TODO: Needs errorModal
        // return Alerts.errorModal(500);
      });
    }
  }

  function loginViaSSO() {
    // go to main page
    return $state.go('Landing');
  }

  function goToAcceptancePage(token) {
    $state.go('LegalAcceptance', { token });
  }

  function routeSSOEntrance(error, tokenString) {
    if (error) {
      showError(angular.fromJson(atob(error)));
    } else if (tokenString) {
      const decodedToken = decodeJwt(tokenString);
      const isTosRequired = !decodedToken.role;

      switch (decodedToken.regStatus) {
        case 'VERIFIED':
          setAmplitudeUser(decodedToken.sub);

          logEvent('loginSuccess', {
            category: eventCategories.LOGIN,
            loginType: 'sso'
          });

          if (isTosRequired) {
            goToAcceptancePage(tokenString);
          } else {
            Session.beginSession(tokenString);
            loginViaSSO().catch(function(error) {
              showError(error);
            });
          }
          break;
        case 'UNVERIFIED':
          $state.go('Login').then(function() {
            return $state.go('promptVerify', {
              resendEmail: decodedToken.email
            });
          });
          break;
        case 'UNREGISTERED':
          $state
            .go('Register', {
              regtoken: tokenString
            })
            .catch(function(errorResponse) {
              showError(errorResponse.data);
            });
          break;
        default:
          showError({
            errorNumber: 113
          });
          break;
      }
    } else {
      showError({
        errorNumber: 113
      });
    }
  }

  /**
   * Retrieves SSO data necessary for a user to initiate a session with a
   * third-party website.
   *
   * @param  {object} opts - Should contain personId, sponsorId, and partnerSlug
   * keys.
   * @return {promise}     - Resolves with an SSO data object, if found.
   */
  function getPartnerSsoData(opts) {
    return Api.req({
      endPoint: '/partner',
      params: {
        personId: opts.personId,
        sponsorId: opts.sponsorId
      }
    })
      .then(partners => {
        return _.find(partners, {
          ssoEnabled: true,
          slug: opts.partnerSlug
        });
      })
      .then(partner => {
        return partner
          ? Api.req({
              endPoint: `/partner/${partner.id}/sso`,
              params: {
                sponsorId: opts.sponsorId
              }
            })
          : $q.reject(
              new Error(
                `Partner "${opts.partnerSlug}" has no SSO data for this user.`
              )
            );
      });
  }

  /**
   * Build extra form inputs from extra paramters.
   *
   * @param {array} extraParameters
   * @return {string} Extra form inputs.
   */
  function getExtraInputs(extraParameters) {
    return extraParameters
      .map(
        extraParameter =>
          `<input type="hidden" 
            name="${extraParameter.name}" 
            value="${extraParameter.value}"
          >`
      )
      .join('');
  }

  /**
   * Generate the HTML POST form for SSO.
   *
   * @param {string} ssoEndpoint Location of partner auth service.
   * @param {string} samlPayload Credential assertions.
   * @param {string} target
   * @param {array} extraParameters Additional form inputs.
   * @return {string} SSO Form.
   */
  function getSsoForm(ssoEndpoint, samlPayload, target, extraParameters) {
    return `<form method="POST" action="${ssoEndpoint}" target="${target}" style="display: none;">
      <input type="hidden" name="SAMLResponse" value="${samlPayload}">
      ${getExtraInputs(extraParameters)}
    </form>`;
  }

  /**
   * Ported from Frontend-Core
   *
   * Accepts the response from a request to /partner/{partnerId}/sso and opens
   * the returned SSO resource in a new tab via a hidden form.
   *
   * @param {object} ssoData
   * @param {object} [opts] - Optional options object used to configure the
   *   invocation.
   */
  function openSsoLink(ssoData, opts = {}) {
    const { target = '_blank' } = opts;

    const { ssoEndpoint, samlPayload, extraParameters = [] } = ssoData;

    const formEl = angular.element(
      getSsoForm(ssoEndpoint, samlPayload, target, extraParameters)
    );

    $document.find('body').append(formEl);
    formEl[0].submit();
    formEl.remove();
  }

  return {
    loginViaSSO,
    routeSSOEntrance,
    getPartnerSsoData,
    getSsoForm,
    openSsoLink
  };
});
