import angular from 'angular';

import '@collectivehealth/dls-panel/dist/panel-theme-landing-summary.es.js';
import '@collectivehealth/dls-button/dist/button-theme-black.es.js';
import '@collectivehealth/dls-button/dist/button-size-tiny.es.js';

// Angular module dependencies.
import 'angular-ui-router/release/stateEvents';
import 'matchmedia-ng';
import 'angular-animate';
import 'angular-sanitize';
import angularScroll from 'angular-scroll';
import angularTranslate from 'angular-translate';
import chIcon from '@collectivehealth/ch-icon';
import chModal from '@collectivehealth/ch-modal';
import chPopover from '@collectivehealth/ch-popover';
import coreServices from '@collectivehealth/core-services';
import ngIdle from 'ng-idle';
import ngMessages from 'angular-messages';
import uiMask from 'angular-ui-mask';
import uiRouter from 'angular-ui-router';
import getCare from '@collectivehealth/get-care';
import Formation from '@darkobits/formation';
import dlsMx from '@collectivehealth/dls-mx';
import frontendLegal from '@collectivehealth/frontend-legal';

import '@collectivehealth/dls-button';
import '@collectivehealth/dls-button/dist/button-theme-coral.es.js';
import '@collectivehealth/dls-button/dist/button-size-small.es.js';
import '@collectivehealth/dls-alerts';
import '@collectivehealth/dls-link';

const app = angular.module('ch.memberApp', [
  'matchmedia-ng',
  'ngAnimate',
  'ngSanitize',
  'ui.router.state.events',
  'angular-jwt',
  angularScroll,
  angularTranslate,
  chIcon,
  chModal,
  chPopover,
  coreServices,
  ngIdle,
  ngMessages,
  uiMask,
  uiRouter,
  getCare,
  Formation,
  dlsMx,
  frontendLegal,
]);

/**
 * On state change redirect to NextJS if feature toggle is enabled
 */

// TODO move this check to after login
// https://collectivehealth.atlassian.net/browse/MX-2519
// app.run(function ($rootScope, FeatureFlagService) {
//   const activityToggle =
//     'public.anonymous.member.activitypagerevamp.web.enabled';
//   const activityErrorMessage =
//     'Error checking Activity Page revamp availability';

//   FeatureFlagService.isFeatureToggleEnabled(
//     activityToggle,
//     activityErrorMessage
//   ).then((isActivityPageRevampEnabled) => {
//     if (isActivityPageRevampEnabled) {
//       $rootScope.$on('$stateChangeStart', function (event, newState) {
//         if (newState.name === 'Activity') {
//           event.preventDefault();
//           window.location.href = getActivityPageRoute();
//         }
//       });
//       if (window.location.pathname === '/activity') {
//         window.location.href = getActivityPageRoute();
//       }
//     }
//   });
// });

export default app;
