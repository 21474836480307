import getRoute from './getRoute';
import { isLocal, isTesting } from './checkEnv';
import prodHelpCenterRouting from './prodHelpCenterRouting.json';
import testingHelpCenterRouting from './testingHelpCenterRouting.json';

export default function getHelpCenterRoute(deepLinkKey) {
  let idCollection;
  if (isTesting() || isLocal()) {
    idCollection = testingHelpCenterRouting;
  } else {
    idCollection = prodHelpCenterRouting;
  }

  let url;
  if (idCollection[deepLinkKey]) {
    const { articleId, sectionId, categoryId } = idCollection[deepLinkKey];
    if (categoryId) {
      url = `/help/category?id=${categoryId}`;
    } else {
      url = `/help/article?id=${articleId}&sectionId=${sectionId}`;
    }
  } else {
    url = '/help';
  }

  return getRoute(url);
}
