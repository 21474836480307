import app from 'app';
import NAVIGATION from 'constants/navigation';
import getRoute from 'helpers/getRoute';
import getHelpCenterRoute from 'helpers/helpCenterRoutes';
import ROUTES from 'routes.json';

app.service('NavLinks', ($state) => {
  const HOME_LINK = {
    title: NAVIGATION.HOME,
    getHref: () => getRoute(`/${ROUTES.landing.react}`),
    isActive: () => $state.includes('Landing'),
    dataHook: 'header-home-link',
  };

  const ACTIVITY_LINK = {
    title: NAVIGATION.ACTIVITY,
    getHref: () => getRoute(`/${ROUTES.activity}`),
    isActive: () => false, //migrated to React
    dataHook: 'header-activity-link',
  };

  const PLAN_LINK = {
    title: NAVIGATION.COVERAGE,
    getHref: () => getRoute(`/${ROUTES.plans.react}`),
    isActive: () => $state.includes('Plan'),
    dataHook: 'header-plans-link',
  };

  const CARE_LINK = {
    title: NAVIGATION.GET_CARE,
    getHref: () => getRoute('/get-care'),
    isActive: () => false, //migrated to React
    dataHook: 'header-care-link',
  };

  const EDU_LINK = {
    title: NAVIGATION.HELP,
    getHref: getHelpCenterRoute,
    isActive: () => false, //migrated to React
    dataHook: 'header-help-link',
  };

  function getNavLinks(planMemberships) {
    let navLinks = [];

    if (planMemberships.hasCHPlanMembership()) {
      navLinks = navLinks.concat(ACTIVITY_LINK);
    }

    if (planMemberships.hasCurrentOrFuturePlanMembership) {
      navLinks = navLinks.concat(PLAN_LINK);
    }

    if (planMemberships.hasCurrentOrFuturePlanMembership) {
      navLinks = navLinks.concat(CARE_LINK);
    }

    navLinks = navLinks.concat(EDU_LINK);

    navLinks = [HOME_LINK].concat(navLinks);

    return navLinks;
  }

  return {
    getNavLinks,
  };
});
