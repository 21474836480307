import app from 'app';
import templateUrl from './faqRecommendationSuggestion.html';
import './faqRecommendationSuggestion.scss';

function FaqRecommendationSuggestion() {}

app.component('faqRecommendationSuggestion', {
  bindings: {
    $close: '<',
  },
  controller: FaqRecommendationSuggestion,
  controllerAs: 'FaqRecommendationSuggestion',
  templateUrl,
});

export default 'faqRecommendationSuggestion';
