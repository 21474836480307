import app from 'app';
import templateUrl from './landingActivity.html';
import './landingActivity.scss';
import './landingClaim/landingClaim';

import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-arrow-right';
import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-documents';

function LandingActivity(Claims, $state, FeatureFlagService) {
  const LandingActivity = this;

  LandingActivity.$onInit = function () {
    Claims.getClaims(LandingActivity.currentUser.id, {
      sponsorId: LandingActivity.sponsorship.sponsor.id,
    })
      .then((resp) => {
        const { claims = [] } = resp;
        LandingActivity.claims = claims.slice(0, 3);
      })
      .catch(() => {
        LandingActivity.claims = [];
      });
  };

  LandingActivity.clickClaim = (claim) => {
    if (claim.claimType !== 'pharmacyClaim') {
      logEvent('claimsViewedHome', {
        category: eventCategories.HOME,
        planType: claim.planType,
      });

      window.location.href = `/activity/claim?claimId=${claim.id}`;
    }
  };

  LandingActivity.trackViewAll = function () {
    logEvent('viewAllActivityClicked', {
      category: eventCategories.HOME,
    });
  };
}

app.component('landingActivity', {
  bindings: {
    currentUser: '<',
    sponsorship: '<',
  },
  controller: LandingActivity,
  controllerAs: 'LandingActivity',
  templateUrl,
});
