import app from 'app';
import templateUrl from './accumulator.html';
import mxPopover from 'components/tooltips/mxPopover/mxPopover';
import './accumulator.scss';
import { LABELS } from 'constants/accumulator';

function Accumulator() {
  const Accumulator = this;

  Accumulator.$onInit = () => {
    Accumulator.data = Accumulator.accumulators[Accumulator.type];
    Accumulator.definition = Accumulator.definitions[Accumulator.type];

    Accumulator.popover = {
      component: mxPopover,
      locals: {
        content: Accumulator.definition.description,
      },
      attachment: 'top left',
      targetAttachment: 'bottom left',
      offset: '-4px 0',
    };
  };

  Accumulator.getNetworkLabel = function (networkType) {
    return LABELS[networkType];
  };

  Accumulator.getFamilyTierLabel = function (familyTier) {
    return familyTier === 'INDIVIDUAL' ? 'person' : 'family';
  };
}

app.component('accumulator', {
  bindings: {
    type: '@',
    accumulators: '<',
    definitions: '<',
    isFamilyLevel: '<',
  },
  controller: Accumulator,
  controllerAs: 'Accumulator',
  templateUrl,
});

export default 'accumulator';
