import app from 'app';
import templateUrl from './cards.html';
import './cards.scss';

// Assets
import appStoreUrl from './appstore-button.png';
import playStoreUrl from './playstore-button.png';

import { logEvent, eventCategories } from 'helpers/analytics';

// Sub-components.
import './flippableCard/flippableCard';
import './memberCard/memberCard';
import cardRequest from './cardRequest/cardRequest';
import CARDS_PERIOD from 'constants/cardsPeriod';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-close';

function CardsCtrl($timeout, chModal, Cards) {
  const CardsCtrl = this;
  CardsCtrl.currentOrFutureCards = CARDS_PERIOD.CURRENT;
  CardsCtrl.isLoading = true;
  // CH-61909 - Temporarily disable the PoCov panel
  CardsCtrl.enablePoCov = true;

  CardsCtrl.currentOrFutureOptions = [
    {
      label: 'Current',
      value: CARDS_PERIOD.CURRENT
    },
    {
      label: 'Future',
      value: CARDS_PERIOD.FUTURE
    }
  ];

  function getOption(member) {
    return {
      label: member.firstName,
      value: member.id
    };
  }

  function isMissingImages(cardsForPeriod) {
    const isMissingMedical =
      cardsForPeriod.hasMedical && !cardsForPeriod.medical.hasImgs;
    const isMissingDental =
      cardsForPeriod.hasDental && !cardsForPeriod.dental.hasImgs;
    const isMissingVision =
      cardsForPeriod.hasVision && !cardsForPeriod.vision.hasImgs;

    return isMissingMedical || isMissingDental || isMissingVision;
  }

  function getBannerMessage() {
    if (CardsCtrl.currentOrFutureCards === CARDS_PERIOD.FUTURE) {
      // Selected future and has current
      if (CardsCtrl.cards.hasCurrent) {
        return [
          'These are the details for your upcoming coverage.',
          'If you changed your coverage selection from last year,',
          "you'll be mailed physical cards before your new coverage starts."
        ].join(' ');
      }

      // selected future and doesn't have current
      return [
        'These are the details for your upcoming coverage.',
        "You'll receive physical cards in the mail before your coverage starts."
      ].join(' ');
    }

    // Selected current and also has future cards
    if (CardsCtrl.cards.hasFuture) {
      return [
        'These are the details for your current coverage.',
        'Update your "Plan Year" selection above to see the details for your upcoming coverage.'
      ].join(' ');
    }

    // Selected current, and is missing one or more images
    if (isMissingImages(CardsCtrl.cardsForPeriod)) {
      return [
        'Looks like your card images are not loading right now.',
        "Don't worry—we've shown the information you need below."
      ].join(' ');
    }

    return ''; // Selected current but doesn't have future cards
  }

  function updateView() {
    CardsCtrl.isLoading = false;
    if (!CardsCtrl.cards.hasCurrentAndFuture) {
      if (CardsCtrl.cards.hasFuture) {
        CardsCtrl.currentOrFutureCards = CARDS_PERIOD.FUTURE;
      } else {
        CardsCtrl.currentOrFutureCards = CARDS_PERIOD.CURRENT;
      }
    }

    CardsCtrl.cardsForPeriod = CardsCtrl.cards[CardsCtrl.currentOrFutureCards];
    CardsCtrl.bannerMessage = getBannerMessage();
    // Set/reset a card errors map to cache image source url errors of cards for a user for a period
    CardsCtrl.cardErrors = {};
  }

  CardsCtrl.$onInit = () => {
    CardsCtrl.appStoreUrl = appStoreUrl;
    CardsCtrl.playStoreUrl = playStoreUrl;

    const currentUserOption = getOption(CardsCtrl.currentUser);
    const dependents = Cards.getDependents(
      CardsCtrl.currentUser,
      CardsCtrl.currentSponsorship,
      CardsCtrl.planMemberships
    );

    CardsCtrl.family = [
      currentUserOption,
      ...dependents.map(member => getOption(member))
    ];
    CardsCtrl.selectUser(currentUserOption);
  };

  CardsCtrl.selectUser = selected => {
    logEvent('cardMemberFiltered', {
      category: eventCategories.CARDS
    });
    // TO FIX: Find selected member because native dropdown only returns id, not full object
    const user = CardsCtrl.family.find(
      member => member.value === selected.value
    );
    CardsCtrl.selectedUser = user;
    CardsCtrl.isLoading = true;
    Cards.getCardsByMember(CardsCtrl.currentSponsorship.id, selected.value)
      .then(cards => (CardsCtrl.cards = cards))
      .finally(() => updateView());
  };

  CardsCtrl.selectCurrentOrFutureCards = selected => {
    logEvent('cardYearFiltered', {
      category: eventCategories.CARDS,
      yearSelected: selected.label.toLowerCase()
    });
    CardsCtrl.currentOrFutureCards = selected.value;
    updateView();
  };

  CardsCtrl.requestNewCards = function() {
    logEvent('cardNewRequestStarted', {
      category: 'cards'
    });
    CardsCtrl.$close();
    chModal.open({
      component: cardRequest,
      locals: {
        currentUser: CardsCtrl.currentUser,
        currentSponsorship: CardsCtrl.currentSponsorship,
        planMemberships: CardsCtrl.planMemberships
      }
    });
  };

  CardsCtrl.openProofOfCoverage = function() {
    CardsCtrl.$close();
    Cards.openProofOfCoverage({
      currentUser: CardsCtrl.currentUser,
      currentSponsorship: CardsCtrl.currentSponsorship
    });
  };

  CardsCtrl.setCardError = function(cardType) {
    $timeout(() => {
      CardsCtrl.cardErrors[cardType] = true;
    });
  };

  CardsCtrl.printCardsClicked = () => {
    logEvent('cardPrinted', {
      category: eventCategories.CARDS,
      planType: Object.keys(CardsCtrl.cardsForPeriod.cardsByType)
    });
  };
}

app.component('cards', {
  bindings: {
    $close: '<',
    currentUser: '<',
    currentSponsorship: '<',
    planMemberships: '<'
  },
  controller: CardsCtrl,
  controllerAs: 'Cards',
  templateUrl
});

export default 'cards';
