import app from 'app';
import templateUrl from './landing.html';
import './landing.scss';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-cards-blank';

import { logEvent, eventCategories } from 'helpers/analytics';
import { TOAST_STATUS } from 'constants/toast';

import './landingActivity/landingActivity';
import './landingFunds/landingFunds';
import './landingCob/landingCob';
import './landingInviteDependents/landingInviteDependents';
import './landingPlans/landingPlans';
import './landingPartners/landingPartners';
import './landingRecommendations/landingRecommendations';
import './landingRecommendationSuggestions/landingRecommendationSuggestions';
import { COLLECTIVE_TITLE } from '../../collectiveTitle';
import getHelpCenterRoute from 'helpers/helpCenterRoutes';

function Landing(
  Cards,
  $element,
  SpendingAccountsService,
  Recommendations,
  Toaster,
) {
  const Landing = this;
  Landing.getHelpCenterRoute = getHelpCenterRoute;

  function getCoverageEndedMessage(pmEndDate) {
    if (pmEndDate) {
      return `Your coverage ended as of ${pmEndDate.format('MMMM Do, YYYY')}.`;
    }
  }

  function getFutureCoverageMessage(pmStartDate) {
    if (pmStartDate) {
      return `Hang tight—your coverage begins ${pmStartDate.format(
        'MMMM Do, YYYY',
      )}.`;
    }
  }

  function getBannerText() {
    if (Landing.planMemberships.hasCurrentCHPlanMembership()) {
      return;
    }
    if (Landing.planMemberships.hasFutureCHPlanMembership()) {
      return getFutureCoverageMessage(
        Landing.planMemberships.nextFutureMembershipStartDate(),
      );
    }

    if (
      Landing.planMemberships.hasPastCHPlanMembership() ||
      (Landing.memberFunds.hasFunds &&
        Landing.memberFunds.hasGroupFromYear(new Date().getYear())) ||
      !Landing.memberFunds.hasFunds
    ) {
      return getCoverageEndedMessage(
        Landing.planMemberships.latestPastMembershipEndDate(),
      );
    }
  }

  function getWelcomeText() {
    if (Landing.planMemberships.hasCurrentCHPlanMembership()) {
      return `We've got you covered.`;
    } else {
      return `Welcome to ${COLLECTIVE_TITLE}.`;
    }
  }

  Landing.viewCards = function () {
    logEvent('cardViewed', {
      planType: Landing.planTypes,
      category: eventCategories.CARDS,
      page: 'homepage',
    });

    Cards.openModal({
      currentUser: Landing.currentUser,
      currentSponsorship: Landing.currentSponsorship,
    });
  };

  Landing.cobNoStateTrigger = function () {
    Landing.cobNoState = true;
  };

  Landing.setMessages = function () {
    Landing.bannerMessage = getBannerText();
    Landing.welcomeMessage = getWelcomeText();
  };

  Landing.fetchRecommendationSuggestions = function () {
    Recommendations.fetchRecommendationSuggestions(Landing.currentUser.id).then(
      (recommendations) => {
        Landing.recommendationSuggestions = recommendations;
      },
    );
  };

  Landing.dismissRecommendation = function (recommendation) {
    Recommendations.dismissRecommendation(recommendation.channelIncludeId)
      .then(() => {
        Toaster.addToast({
          status: TOAST_STATUS.success,
          title: 'Successfully dismissed',
          description: `You won’t see a recommendation for ${recommendation.category.toUpperCase()} for 1 year.`,
        });
      })
      .catch(() => {
        Toaster.addToast({
          status: TOAST_STATUS.critical,
          title: 'Dismiss failed',
          description:
            'We’re sorry, but we’re having trouble connecting to our server. Refresh the page and try again.',
        });
      });
  };

  Landing.isRecommendationsEnabled = function isRecommendationsEnabled() {
    Recommendations.isRecommendationsEnabled().then((getCareEnabled) => {
      Landing.isRecommendationsEnabled = getCareEnabled;
    });
  };

  Landing.$onInit = () => {
    Landing.recommendations = [];
    Landing.recommendationSuggestions = [];

    $element.addClass('Landing');
    Landing.planTypes = Landing.currentSponsorship.activePlanTypes;
    Landing.fetchRecommendationSuggestions();
    Landing.isRecommendationsEnabled();

    //Landing.showCobQuestion = Landing.cob.showCobQuestion || Landing.cobNoState;

    const { sponsor, subscriber } = Landing.currentSponsorship;

    SpendingAccountsService.getMemberFunds(
      Landing.currentUser.id,
      sponsor.id,
      subscriber.id,
    ).then((funds) => {
      Landing.memberFunds = funds;
      Landing.setMessages();
    });
  };
}

app.component('landing', {
  bindings: {
    isSubscriber: '<',
    planMemberships: '<',
    allSponsorships: '<',
    sponsorshipId: '<',
    activeAlerts: '<',
    currentUser: '<',
    currentSponsorship: '<',
    navLinks: '<',
    // cob: '<'
  },
  controller: Landing,
  controllerAs: 'Landing',
  templateUrl,
});

export default 'landing';
