import app from 'app';
import templateUrl from './header.html';
import './header.scss';

// Sub-components.
import './accumulator/accumulator';
import './costAndCoverage/costAndCoverage';

function PlanDetailHeader(PlanDetails) {
  const Header = this;

  Header.$onInit = () => {
    Header.isDental = Header.plan.type === 'dental';
    Header.coverageOnly = Header.plan.type === 'vision';
    Header.showHealthcareBasicsDeeplink = Header.plan.type === 'medical';
    Header.isFamilyLevel = Header.plan.coveredMembers.length > 1;
    Header.accumulators = PlanDetails.groupAccumulators(
      Header.plan.accumulators
    );
  };
}

app.component('planDetailHeader', {
  bindings: {
    plan: '<',
    sponsorshipId: '<',
    viewCards: '&',
  },
  controller: PlanDetailHeader,
  controllerAs: 'Header',
  templateUrl,
});

export default 'planDetailHeader';
