const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const today = new Date();

export function getLocalTime(timeString) {
  const date = new Date(timeString);
  if (sameDay(today, date)) {
    return date.toLocaleTimeString('en-US', { timeStyle: 'short' });
  } else if (sameYear(today, date)) {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
  } else {
    return date.toLocaleDateString('en-US', { dateStyle: 'short' });
  }
}

function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function sameYear(d1, d2) {
  return d1.getFullYear() === d2.getFullYear();
}
